import { Component, OnInit } from '@angular/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { AppService } from '../app.service'
import { NewGiftcard } from '../data-model';
import * as $ from 'jquery';


export interface PeriodicElement {
  giftcard: string;
  position: number;
  action: number;
}


const ELEMENT_DATA: PeriodicElement[] = [
  {position: 1, giftcard: 'Akshaya Priority1', action: 1},
  {position: 2, giftcard: 'Akshaya Priority2', action: 2}
  
];

const options: NgbModalOptions = {
  backdropClass: '',
};



@Component({
  selector: 'app-giftcard',
  styleUrls: ['./giftcard.component.css'],
  templateUrl: './giftcard.component.html',
})
export class GiftcardComponent {
  newGiftcard: NewGiftcard = new NewGiftcard();
  opened = true;
  over = 'side';
  expandHeight = '42px';
  collapseHeight = '42px';
  displayMode = 'flat';
  ComNameStyle:object;
  FieldStyle:object;
  FieldPaymentStyle:object;
  TPaymentStyle:object;
  IPaymentStyle:object;
  BPaymentStyle:object;
  mobile_number: any;
  mnumber=localStorage.getItem('mobile_Number');
  cusname=localStorage.getItem('cusname');
  isGiftCardTc = false;
  tandcfoot = true;
  isSaveCurPermanent = false;
  registerWarningClass: boolean;
  registerWarningStyle: object;
  termandconClass: boolean;
  termandconStyle: object;
  msg: string = "";
  warning_content:any;
  emivalid:any;
  argsvals:any;
  mobilevalid:any;
  loginmob=localStorage.getItem('odoomobilenum');
  loginemailid=localStorage.getItem('odooemailid');
  isFirstnamecon = false;
  validem = false;
  validmob =false;
  totalamt=0;
  //onetimeregis=0;
  denomamt=0
  frontpage:File =null;
  backpage:File = null;
  pandrboolean =false;
  c_email = localStorage.getItem('odooemailid');
  c_mobile = localStorage.getItem('mobile');
  c_name=localStorage.getItem('country_name');
  countries:object;
  country_code:string;
  country_name:string;
  persfirstname:string = null; 
  perslastname:string = null;
  persdob:any; 
  persmobile:string = null;
  persemail:string = null;
  curstreet1:string = null;
  curstreet2:string = null;
  curcity:string = null;
  curstate:string = null;
  curpobox:string = null;
  curcountry:string = null;
  perstreet1:string = null; 
  perstreet2:string = null;
  percity: string = null;
  perpobox:string = null;
  perstate:string = null;
  percountry:string = null;
  nomstreet1:string = null;
  nomstreet2:string = null;
  nomcity:string = null;
  nomstate:string = null;
  nompobox:string = null;
  nomcountry:string = null;
  schemes:any;
  form_valid=true;
  tcheader:string[];
  tcgeneral:string[];
  tcbenefits:string[];
  min_amount=0;
  max_amount=0;
  multiples_of=0;


  tenureselection=[{ title: "6", value: 6 }, 
  { title: "9", value: 9 },{ title: "12", value: 12 }];
  relswithnomi=[{ title: "Father", value: "Father"}, 
  { title: "Mother", value:"Mother" },
  { title: "Spouse", value: "Spouse" }, 
  { title: "Son", value:"Son"  },
  { title: "Daughter", value: "Daughter" }, 
  { title: "Sibling", value:"Sibling"  }] ;
  tenureselected=0;
  relswithnomineeselected="";
  fileToUploadfront: any;
  fileToUploadback: any;
  fileName:any =null;
  fileData:any = null;
  idproofvalue:string = null;
  idproofid:string = null;
  idproofselection=[{ title: "Emirates", value: "Emirates" }, 
  { title: "Others", value: "Others" }];
  idproofuploadvalid:any;
  
   
        
  // overlap = false;

  watcher: Subscription;
  constructor(mediaObserver: MediaObserver,private router: Router,public spinner: NgxSpinnerService,public appService: AppService) {
    this.watcher = mediaObserver.media$.subscribe((change: MediaChange) => {
      if (change.mqAlias === 'sm' || change.mqAlias === 'xs') {
        this.opened = false;
        this.over = 'over';
        this.ComNameStyle ={'display':'block'};
        this.FieldStyle ={'width':'250px'};
        this.FieldPaymentStyle ={'width':'250px','margin-left':'6%'};
        this.TPaymentStyle ={'margin-left':'0%'};
        this.IPaymentStyle ={'margin-left':'1%'};
        this.BPaymentStyle ={'margin-left':'','margin-top':'15px'};
      } else {
        this.opened = true;
        this.over = 'side';
        this.ComNameStyle ={'display':'block'};
        this.FieldStyle ={'width':'39%'};
        this.FieldPaymentStyle ={'width':'39%','margin-left':'660px'};
        this.TPaymentStyle ={'margin-left':''};
        this.IPaymentStyle ={'margin-left':'50%'};
        this.BPaymentStyle ={'margin-left':''};
      }
    });
  }

setValue(e){
    if(e.checked){
        this.isGiftCardTc = true
   }else{
        this.isGiftCardTc = false
   }
}
setSaveCurPermanent(e){
var checked = $('#percheck').is(':checked')
// console.log(checked)
var self = this;
if(checked){
        self.perstreet1=self.curstreet1
  self.perstreet2=self.curstreet2
  self.percity=self.curcity
  self.perpobox=self.curpobox
  self.perstate=self.curstate
  self.percountry=self.curcountry
   }else{
        self.perstreet1=""
  self.perstreet2=""
  self.percity=""
  self.perpobox=""
  self.perstate=""
  self.percountry=""
   }
  
}
setSaveCurNominee(e){
var checked = $('#nomcheck').is(':checked')
  var self = this;
if(checked){
        self.nomstreet1=self.curstreet1
  self.nomstreet2=self.curstreet2
  self.nomcity=self.curcity
  self.nomstate=self.curstate
  self.nompobox=self.curpobox
  self.nomcountry=self.curcountry
   }else{
        self.nomstreet1=""
  self.nomstreet2=""
  self.nomcity=""
  self.nompobox=""
  self.nomstate=""
  self.nomcountry=""
   }

}

setcalctotamount(e){
var self = this;
// console.log(e.target.value)
  var r = e.target.value;
  self.totalamt=r *self.denomamt
  
}

setcountryselection(e){
  var self = this;
  // console.log(e.target.value)
  self.curcountry=e.target.value
  self.nomcountry=e.target.value
  self.percountry=e.target.value

}

setEmiAmount(e){
var self = this;
   self.emivalid=""
  var r= e.target.value
  // console.log(r,self.max_amount,typeof(self.max_amount))
  if((r % self.multiples_of) != 0 || r < self.min_amount || r > self.max_amount){

    if(r % (self.multiples_of) != 0)
    {
      self.emivalid=""
      $(".warningofemi").empty();
      self.emivalid="Reload Amount should be multiples of "+self.multiples_of
    
    }
    if( r < self.min_amount){
      self.emivalid=""
      $(".warningofemi").empty();
      self.emivalid="Reload Amount should not be lesser than "+self.min_amount
    
    }
    if( r > self.max_amount){
      self.emivalid=""
      $(".warningofemi").empty();
      self.emivalid="Reload Amount should not be greater than "+self.max_amount
      
    }
    $("<br/><span style='color:red'><t>"+self.emivalid+"</t></span>").appendTo(".warningofemi")
    $('#monthdenomamt').focus()
    return false;
  }
  else{

  self.emivalid=""
  self.denomamt=r
  self.totalamt=self.tenureselected*r
  $(".warningofemi").empty();
  
      return false;

  }


}
setMobileValid(e){
var self = this;
   self.mobilevalid="";
  var r = e.target.value;
  // console.log(r,"ldkfkdgjfjgfh")
  if (/^(?:\+971|00971|0)(?:2|3|4|6|7|9|50|51|52|55|56)[0-9]{7}$/.test(r) || r =="")
    {
      self.validmob = false;
      self.mobilevalid=""
      $(".warningofnominee").empty();
  
      return false;
    }
    else if(/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/.test(r) || r=="")
    {
    self.validmob = false;
      self.mobilevalid=""
      $(".warningofnominee").empty();
  
      return false;
    }
    else{
      self.validmob = true;
      self.mobilevalid=""
  $(".warningofnominee").empty();
  self.mobilevalid="Enter valid Mobile Number for the Nominee"
  $("<br/><span style='color:red'><t>"+self.mobilevalid+"</t></span>").appendTo(".warningofnominee")
      return false;
    }
}
setEmailValid(e){
var self = this;
   self.mobilevalid="";
  var r = e.target.value;
  // console.log(r,"ldkfkdgjfjgfh")
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(r) || r =="")
    {
      self.validem =false;
      self.mobilevalid=""
      $(".warningofnominee").empty();
  
      return false;
    }
    else{
      self.validem =true;
      self.mobilevalid=""
  $(".warningofnominee").empty();
  self.mobilevalid="Enter valid Email Id for the Nominee"
  $("<br/><span style='color:red'><t>"+self.mobilevalid+"</t></span>").appendTo(".warningofnominee")
      return false;
    }
}

  getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = $.trim(cookies[i]);
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
  }

handleFileInputfront(files){

   let proofFile = files.item(0);
  let reader = new FileReader();
   reader.readAsDataURL(proofFile);
    if(proofFile!=undefined){
                
        reader.onload = () => {
                this.fileToUploadfront = {
                    fileName: proofFile.name,
                    fileType: proofFile.type,
                    fileData: (<string>reader.result).split(',')[1]
                }
                }
                this.idproofuploadvalid=""
                $(".warningofidprfupload").empty();
                }
            
}
handleFileInputback(files){
  
  let proofFile = files.item(0);
  let reader = new FileReader();
   reader.readAsDataURL(proofFile);
   if(proofFile.files.length!=0){
        reader.onload = () => {
                 this.fileToUploadback= {
                    fileName: proofFile.name,
                    fileType: proofFile.type,
                    fileData: (<string>reader.result).split(',')[1]
                }
                }
                }
}




  ngOnInit() {
  let sessionkey=localStorage.getItem('session_valid_key')
  // console.log(sessionkey,"kdkdhdhdbccggd")
  if(sessionkey=="logout"){
  this.router.navigate(['/home'])
  }
  this.rendergiftcardregistration(localStorage.getItem('mobile'))
  // console.log(this.persfirstname,"please check this")
  
   
  }

  displayedColumns: string[] = ['position', 'giftcard', 'action'];
  dataSource = ELEMENT_DATA;

  moveToSelectedTab(tabName: string) {
  for (let i =0; i< document.querySelectorAll('.mat-tab-label-content').length; i++) {
      if ((<HTMLElement>document.querySelectorAll('.mat-tab-label-content')[i]).innerText == tabName) {
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[i]).click();
      }
    }
}


logout = function(){
   
  var self = this;
  self.spinner.show(); 
  self.router.navigate(['/home'])
  self.renderlogout(localStorage.getItem('odoomobilenum'))
  localStorage.setItem('session_valid_key', "logout");
  self.spinner.hide(); 
}
rglink = function(){
   
  var self = this;
  self.spinner.show(); 
  self.router.navigate(['/giftcardregistered'])
  self.spinner.hide(); 
}
termandconactive = function(){
  this.tandcfoot = true;
  this.termandconClass = true;
  this.termandconStyle = {'display': 'block'};
}
glistlink = function(){
   
  var self = this;
  self.spinner.show(); 
  self.router.navigate(['/giftcardlist'])
  self.spinner.hide(); 
}
hideWarning = function(){
    this.registerWarningClass = false;
    this.registerWarningStyle = {'display': 'none'};
  }
notermandconclick = function(){
	this.termandconClass = false;
    this.termandconStyle = {'display': 'none'};
}  
termandconclick = function(){
    var self=this;
  
  //let monthdenomamt = value.monthdenomamt
  //let tenure = self.tenureselected
  //let totamt = self.tenureselected*self.denomamt
  //let persfirstname = value.persfirstname
  //let perslastname = value.perslastname
  //let persdob = value.persdob
  //let persmobile = value.loginmob
  //let persemail = value.loginemailid
  //let persbeneficiary = value.persbeneficiary
  //let curstreet1 = value.curstreet1
  //let curstreet2 = value.curstreet2
  //let curcity = value.curcity
  //let curpobox = value.curpobox
  //let perstreet1 =self.perstreet1
  //let perstreet2 = self.perstreet2
  //let percity = self.percity
  //let perpobox = self.perpobox
  //let relswithnominee = self.relswithnomineeselected
  //let relsfirstname = value.relsfirstname
  //let relslastname = value.relslastname
  //let relsmobile = value.relsmobile
  //let relsemail = value.relsemail
  //let nomstreet1 = self.nomstreet1
  //let nomstreet2 = self.nomstreet2
  //let nomcity = self.nomcity
  //let nompobox = self.nompobox
  //let modeofpay = value.modeofpay
  //let paymentchannel = value.paymentchannel
  //let giftcardtype = value.giftcardtype
  //let idproofupload = self.idproofvalue
  //let idproofid = self.idproofid
  //let frontpage = self.fileToUploadfront
  //let backpage = self.fileToUploadback


    
  if (!self.form_valid)
  {
    return false;
  }
  this.termandconClass = false;
  this.termandconStyle = {'display': 'none'};
  self.spinner.show();
  let args=self.argsvals
  let ref=localStorage.getItem('refby');
  var csrftoken = self.getCookie('csrf_token');
  // console.log(self.form_valid)
  if(args['payment_channel'] == 'dohabank')
  {
      var session_id='';
        if(this.getCookie('kalyan_id')!=null){
          session_id=this.getCookie('kalyan_id');
        }  
       var csrftoken = this.getCookie('csrf_token');
        $.ajax({
            beforeSend: function(xhr, settings) {
              xhr.setRequestHeader("X-CSRFToken", csrftoken);
            },
            method: "POST",
            url: "/web/proceed_payment_doha_bank/",
            dataType: "json",
            data : {data:JSON.stringify(args),csrf_token: csrftoken},
            success: function(response) {
                if(response.error.status == 200)
                {
                  localStorage.setItem('checkout_session_id',response.data.session_id)
                  localStorage.setItem('order_id',response.data.order_id)
                  localStorage.setItem('emi_amount',response.data.emi_amount)
                  localStorage.setItem('log_id',response.data.log_id)
                  self.router.navigate(['/paymentdohabank'])
                }
                else
                {
                  alert(response.error.message)
                }
                self.spinner.hide();
            },
            error: function(err) {
            console.log('err')
             
            }
            });
  }

  else if(args['payment_channel'] == 'cybersource')
  {
      var session_id='';
      if(this.getCookie('kalyan_id')!=null){
        session_id=this.getCookie('kalyan_id');Date
      }
      //let args = {'session': session_id,'monthdenomamt':monthdenomamt,'tenure':tenure,'totamt':totamt,'persfirstname':persfirstname,'perslastname':perslastname,'persdob':persdob,'persmobile':persmobile,'persemail':persemail,'persbeneficiary':persbeneficiary,'curstreet1':curstreet1,'curstreet2':curstreet2,'curcity':curcity,'curpobox':curpobox,'perstreet1':perstreet1,'perstreet2':perstreet2,'percity':percity,'perpobox':perpobox,'relswithnominee':relswithnominee,'relsfirstname':relsfirstname,'relslastname':relslastname,'relsmobile':relsmobile,'relsemail':relsemail,'nomstreet1':nomstreet1,'nomstreet2':nomstreet2,'nomcity':nomcity,'nompobox':nompobox,'idproofupload':idproofupload,'frontpage':frontpage,'backpage':backpage};

      $.ajax({
            beforeSend: function(xhr, settings) {
              xhr.setRequestHeader("X-CSRFToken", csrftoken);
            },
            method: "POST",
            //url: "/web/payment_portal/newgiftcardregistration/",
            url: "/web/proceed_payment_cybersource/",
            dataType: "json",
            data : {data:JSON.stringify(args),csrf_token: csrftoken},
            success: function(response) {
            // console.log(response.error.status)
            if(response.error.status != "4002")
            {
            // console.log("cybersource response")
            // console.log(response.data)
            localStorage.setItem('cardnumber', response.data.EnrollmentId);
            localStorage.setItem('referencenumber', response.data.RecieptID);
            self.appService.setpaymentstatus("success");

            localStorage.setItem('customerName', response.data.customername);
            localStorage.setItem('payableAmount', response.data.amount);
            localStorage.setItem('encrpquery', response.data.encReq);
            localStorage.setItem('accessCode', response.data.access_code);

            localStorage.setItem('amount', response.data.amount);
            localStorage.setItem('transaction_type', response.data.transaction_type);
            localStorage.setItem('reference_number', response.data.reference_number);
            localStorage.setItem('signed_date_time', response.data.signed_date_time);
            localStorage.setItem('transaction_uuid', response.data.transaction_uuid);
            localStorage.setItem('signature', response.data.signature);
            localStorage.setItem('access_key', response.data.access_key);
            localStorage.setItem('profile_id', response.data.profile_id);
            localStorage.setItem('locale', response.data.locale);
            localStorage.setItem('customer_ip_address', response.data.customer_ip_address);
            localStorage.setItem('bill_to_forename', response.data.bill_to_forename);
            localStorage.setItem('bill_to_surname', response.data.bill_to_surname);
            localStorage.setItem('bill_to_email', response.data.bill_to_email);
            localStorage.setItem('bill_to_address_line1', response.data.bill_to_address_line1);
            localStorage.setItem('bill_to_phone', response.data.bill_to_phone);
            localStorage.setItem('bill_to_address_city', response.data.bill_to_address_city);
            localStorage.setItem('bill_to_address_postal_code', response.data.bill_to_address_postal_code);
            localStorage.setItem('bill_to_address_state', response.data.bill_to_address_state);
            localStorage.setItem('bill_to_address_country', response.data.bill_to_address_country);
            localStorage.setItem('unsigned_field_names', response.data.unsigned_field_names);
            localStorage.setItem('currency', response.data.currency);
            localStorage.setItem('signed_field_names', response.data.signed_field_names);
            localStorage.setItem('payurl', response.data.payurl);

            localStorage.setItem('merchant_defined_data1', response.data.merchant_defined_data1);
            localStorage.setItem('merchant_defined_data2', response.data.merchant_defined_data2);
            localStorage.setItem('merchant_defined_data3', response.data.merchant_defined_data3);
            localStorage.setItem('merchant_defined_data4', response.data.merchant_defined_data4);
            localStorage.setItem('merchant_defined_data5', response.data.merchant_defined_data5);
            localStorage.setItem('merchant_defined_data6', response.data.merchant_defined_data6);
            localStorage.setItem('merchant_defined_data7', response.data.merchant_defined_data7);
            localStorage.setItem('merchant_defined_data8', response.data.merchant_defined_data8);
            localStorage.setItem('merchant_defined_data20', response.data.merchant_defined_data20);

            // console.log(response.data.payurl)
            self.router.navigate(['/paymentcybersource'])

            }
            else
            {
            localStorage.setItem('cardnumber', "undefined");
            localStorage.setItem('referencenumber', "Not updated");
            self.appService.setpaymentstatus("failed");
            // console.log(self.appService.getpaymentstatus(),"failed")
            self.router.navigate(['/paymentpreview'])

            }
            self.pandrboolean=false;
            self.router.navigate(['/paymentcybersource'])
            },
            error: function(err) {
              console.log('err')

            }
            });
  }
  else if(args['payment_channel'] == 'sioncc'){
      var session_id='';
      if(this.getCookie('kalyan_id')!=null){
        session_id=this.getCookie('kalyan_id');Date
      }
      //let args = {'session': session_id,'monthdenomamt':monthdenomamt,'tenure':tenure,'totamt':totamt,'persfirstname':persfirstname,'perslastname':perslastname,'persdob':persdob,'persmobile':persmobile,'persemail':persemail,'persbeneficiary':persbeneficiary,'curstreet1':curstreet1,'curstreet2':curstreet2,'curcity':curcity,'curpobox':curpobox,'perstreet1':perstreet1,'perstreet2':perstreet2,'percity':percity,'perpobox':perpobox,'relswithnominee':relswithnominee,'relsfirstname':relsfirstname,'relslastname':relslastname,'relsmobile':relsmobile,'relsemail':relsemail,'nomstreet1':nomstreet1,'nomstreet2':nomstreet2,'nomcity':nomcity,'nompobox':nompobox,'idproofupload':idproofupload,'frontpage':frontpage,'backpage':backpage};

      $.ajax({
            beforeSend: function(xhr, settings) {
              xhr.setRequestHeader("X-CSRFToken", csrftoken);
            },
            method: "POST",
            url: "/web/si_creation_cc_avenue/",
            dataType: "json",
            data : {data:JSON.stringify(args),csrf_token: csrftoken},
            success: function(response) {
            // console.log(response.error.status)
            if(response.error.status != "4002")
            {
            // console.log("cybersource response")
            // console.log(response.data)
            localStorage.setItem('cardnumber', response.data.EnrollmentId);
            localStorage.setItem('referencenumber', response.data.RecieptID);
            self.appService.setpaymentstatus("success");

            localStorage.setItem('customerName', response.data.customername);
            localStorage.setItem('payableAmount', response.data.amount);
            localStorage.setItem('encrpquery', response.data.encReq);
            localStorage.setItem('accesscode', response.data.access_code);

            localStorage.setItem('amount', response.data.amount);
            localStorage.setItem('transaction_type', response.data.transaction_type);
            localStorage.setItem('reference_number', response.data.reference_number);
            localStorage.setItem('signed_date_time', response.data.signed_date_time);
            localStorage.setItem('transaction_uuid', response.data.transaction_uuid);
            localStorage.setItem('signature', response.data.signature);
            localStorage.setItem('access_key', response.data.access_key);
            localStorage.setItem('profile_id', response.data.profile_id);
            localStorage.setItem('locale', response.data.locale);
            localStorage.setItem('customer_ip_address', response.data.customer_ip_address);
            localStorage.setItem('bill_to_forename', response.data.bill_to_forename);
            localStorage.setItem('bill_to_surname', response.data.bill_to_surname);
            localStorage.setItem('bill_to_email', response.data.bill_to_email);
            localStorage.setItem('bill_to_address_line1', response.data.bill_to_address_line1);
            localStorage.setItem('bill_to_phone', response.data.bill_to_phone);
            localStorage.setItem('bill_to_address_city', response.data.bill_to_address_city);
            localStorage.setItem('bill_to_address_postal_code', response.data.bill_to_address_postal_code);
            localStorage.setItem('bill_to_address_state', response.data.bill_to_address_state);
            localStorage.setItem('bill_to_address_country', response.data.bill_to_address_country);
            localStorage.setItem('unsigned_field_names', response.data.unsigned_field_names);
            localStorage.setItem('currency', response.data.currency);
            localStorage.setItem('signed_field_names', response.data.signed_field_names);
            localStorage.setItem('payurl', response.data.payurl);

            localStorage.setItem('merchant_defined_data1', response.data.merchant_defined_data1);
            localStorage.setItem('merchant_defined_data2', response.data.merchant_defined_data2);
            localStorage.setItem('merchant_defined_data3', response.data.merchant_defined_data3);
            localStorage.setItem('merchant_defined_data4', response.data.merchant_defined_data4);
            localStorage.setItem('merchant_defined_data5', response.data.merchant_defined_data5);
            localStorage.setItem('merchant_defined_data6', response.data.merchant_defined_data6);
            localStorage.setItem('merchant_defined_data7', response.data.merchant_defined_data7);
            localStorage.setItem('merchant_defined_data8', response.data.merchant_defined_data8);
            localStorage.setItem('merchant_defined_data20', response.data.merchant_defined_data20);

            // console.log(response.data.payurl)

            }
            else
            {
              localStorage.setItem('cardnumber', "undefined");
              localStorage.setItem('referencenumber', "Not updated");
              self.appService.setpaymentstatus("failed");
              // console.log(self.appService.getpaymentstatus(),"failed")
              self.router.navigate(['/paymentpreview'])

            }
            self.pandrboolean=false;
            self.router.navigate(['/si_creation'])
            },
            error: function(err) {
              console.log('err')

            }
            });
  }
  else if(args['payment_channel']=="knet"){
    var session_id='';
  if(this.getCookie('kalyan_id')!=null){
    session_id=this.getCookie('kalyan_id');
  }  
  var csrftoken = this.getCookie('csrf_token');
  $.ajax({
          beforeSend: function(xhr, settings) {
            xhr.setRequestHeader("X-CSRFToken", csrftoken);
          },
          method: "POST",
          url: "/web/knet_proceed/",
          dataType: "json",
          data : {data:JSON.stringify(args),csrf_token: csrftoken},
          success: function(response) {
          // console.log(response.data.response.key)
          // console.log(response)
          
          // k=response.data.encReq
          
          // localStorage.setItem('key_param', response.key);
          window.location.href='https://kpay.com.kw/kpg/PaymentHTTP.htm?param=paymentInit&trandata='+response.data.response.key;
          // self.router.navigate(['/knet'])
          },
          error: function(err) {
            console.log('err')
           
          }
          });



    
  }

  else if(args['payment_channel'] == 'smart_pay'){
    var session_id='';
    if(this.getCookie('kalyan_id')!=null){
      session_id=this.getCookie('kalyan_id');Date
    }
    //let args = {'session': session_id,'monthdenomamt':monthdenomamt,'tenure':tenure,'totamt':totamt,'persfirstname':persfirstname,'perslastname':perslastname,'persdob':persdob,'persmobile':persmobile,'persemail':persemail,'persbeneficiary':persbeneficiary,'curstreet1':curstreet1,'curstreet2':curstreet2,'curcity':curcity,'curpobox':curpobox,'perstreet1':perstreet1,'perstreet2':perstreet2,'percity':percity,'perpobox':perpobox,'relswithnominee':relswithnominee,'relsfirstname':relsfirstname,'relslastname':relslastname,'relsmobile':relsmobile,'relsemail':relsemail,'nomstreet1':nomstreet1,'nomstreet2':nomstreet2,'nomcity':nomcity,'nompobox':nompobox,'idproofupload':idproofupload,'frontpage':frontpage,'backpage':backpage};

    $.ajax({
          beforeSend: function(xhr, settings) {
            xhr.setRequestHeader("X-CSRFToken", csrftoken);
          },
          method: "POST",
          url: "/web/proceed_payment_smart_pay/",
          dataType: "json",
          data : {data:JSON.stringify(args),csrf_token: csrftoken},
          success: function(response) {
          // console.log(response.error.status)
          if(response.error.status != "4002")
          {
          // console.log("cybersource response")
          // console.log(response.data)
          localStorage.setItem('cardnumber', response.data.EnrollmentId);
          localStorage.setItem('referencenumber', response.data.RecieptID);
          self.appService.setpaymentstatus("success");

          localStorage.setItem('customerName', response.data.customername);
          localStorage.setItem('payableAmount', response.data.amount);
          localStorage.setItem('encrpquery', response.data.encReq);
          localStorage.setItem('accesscode', response.data.access_code);

          localStorage.setItem('amount', response.data.amount);
          localStorage.setItem('transaction_type', response.data.transaction_type);
          localStorage.setItem('reference_number', response.data.reference_number);
          localStorage.setItem('signed_date_time', response.data.signed_date_time);
          localStorage.setItem('transaction_uuid', response.data.transaction_uuid);
          localStorage.setItem('signature', response.data.signature);
          localStorage.setItem('access_key', response.data.access_key);
          localStorage.setItem('profile_id', response.data.profile_id);
          localStorage.setItem('locale', response.data.locale);
          localStorage.setItem('customer_ip_address', response.data.customer_ip_address);
          localStorage.setItem('bill_to_forename', response.data.bill_to_forename);
          localStorage.setItem('bill_to_surname', response.data.bill_to_surname);
          localStorage.setItem('bill_to_email', response.data.bill_to_email);
          localStorage.setItem('bill_to_address_line1', response.data.bill_to_address_line1);
          localStorage.setItem('bill_to_phone', response.data.bill_to_phone);
          localStorage.setItem('bill_to_address_city', response.data.bill_to_address_city);
          localStorage.setItem('bill_to_address_postal_code', response.data.bill_to_address_postal_code);
          localStorage.setItem('bill_to_address_state', response.data.bill_to_address_state);
          localStorage.setItem('bill_to_address_country', response.data.bill_to_address_country);
          localStorage.setItem('unsigned_field_names', response.data.unsigned_field_names);
          localStorage.setItem('currency', response.data.currency);
          localStorage.setItem('signed_field_names', response.data.signed_field_names);
          localStorage.setItem('payurl', response.data.payurl);

          localStorage.setItem('merchant_defined_data1', response.data.merchant_defined_data1);
          localStorage.setItem('merchant_defined_data2', response.data.merchant_defined_data2);
          localStorage.setItem('merchant_defined_data3', response.data.merchant_defined_data3);
          localStorage.setItem('merchant_defined_data4', response.data.merchant_defined_data4);
          localStorage.setItem('merchant_defined_data5', response.data.merchant_defined_data5);
          localStorage.setItem('merchant_defined_data6', response.data.merchant_defined_data6);
          localStorage.setItem('merchant_defined_data7', response.data.merchant_defined_data7);
          localStorage.setItem('merchant_defined_data8', response.data.merchant_defined_data8);
          localStorage.setItem('merchant_defined_data20', response.data.merchant_defined_data20);

          // console.log(response.data.payurl)

          }
          else
          {
            localStorage.setItem('cardnumber', "undefined");
            localStorage.setItem('referencenumber', "Not updated");
            self.appService.setpaymentstatus("failed");
            // console.log(self.appService.getpaymentstatus(),"failed")
            self.router.navigate(['/paymentpreview'])

          }
          self.pandrboolean=false;
          self.router.navigate(['/smartpay'])
          },
          error: function(err) {
            console.log('err')

          }
          });
}
else if(args['payment_channel'] == 'qpay'){
  var session_id='';
  if(this.getCookie('kalyan_id')!=null){
    session_id=this.getCookie('kalyan_id');Date
  }
  //let args = {'session': session_id,'monthdenomamt':monthdenomamt,'tenure':tenure,'totamt':totamt,'persfirstname':persfirstname,'perslastname':perslastname,'persdob':persdob,'persmobile':persmobile,'persemail':persemail,'persbeneficiary':persbeneficiary,'curstreet1':curstreet1,'curstreet2':curstreet2,'curcity':curcity,'curpobox':curpobox,'perstreet1':perstreet1,'perstreet2':perstreet2,'percity':percity,'perpobox':perpobox,'relswithnominee':relswithnominee,'relsfirstname':relsfirstname,'relslastname':relslastname,'relsmobile':relsmobile,'relsemail':relsemail,'nomstreet1':nomstreet1,'nomstreet2':nomstreet2,'nomcity':nomcity,'nompobox':nompobox,'idproofupload':idproofupload,'frontpage':frontpage,'backpage':backpage};

  $.ajax({
        beforeSend: function(xhr, settings) {
          xhr.setRequestHeader("X-CSRFToken", csrftoken);
        },
        method: "POST",
        url: "/web/proceed_payment_quick_pay/",
        dataType: "json",
        data : {data:JSON.stringify(args),csrf_token: csrftoken},
        success: function(response) {
        if(response.error.status != "4002")
        {
        self.appService.setpaymentstatus("success");
        localStorage.setItem('amount', response.data.Amount);
        localStorage.setItem('currencyCode', response.data.CurrencyCode);
        localStorage.setItem('pun', response.data.PUN);
        localStorage.setItem('merchantModuleSessionID', response.data.MerchantModuleSessionID);
        localStorage.setItem('paymentDescription', response.data.PaymentDescription);
        localStorage.setItem('nationalID', response.data.NationalID);
        localStorage.setItem('merchantID', response.data.MerchantID);
        localStorage.setItem('bankID', response.data.BankID);
        localStorage.setItem('lang', response.data.Lang);
        localStorage.setItem('action', response.data.Action);
        localStorage.setItem('secureHash', response.data.SecureHash);
        localStorage.setItem('transactionRequestDate', response.data.TransactionRequestDate);
        localStorage.setItem('extraFields_f14', response.data.ExtraFields_f14);
        localStorage.setItem('quantity', response.data.Quantity);

        }
        else
        {
          // localStorage.setItem('cardnumber', "undefined");
          // localStorage.setItem('referencenumber', "Not updated");
          self.appService.setpaymentstatus("failed");
          self.router.navigate(['/paymentpreview'])

        }
        self.pandrboolean=false;
        self.router.navigate(['/qpay'])
        },
        error: function(err) {
          console.log('err')

        }
        });
}


  else
  {
      var session_id='';
      if(this.getCookie('kalyan_id')!=null){
        session_id=this.getCookie('kalyan_id');Date
      }  
      //let args = {'session': session_id,'monthdenomamt':monthdenomamt,'tenure':tenure,'totamt':totamt,'persfirstname':persfirstname,'perslastname':perslastname,'persdob':persdob,'persmobile':persmobile,'persemail':persemail,'persbeneficiary':persbeneficiary,'curstreet1':curstreet1,'curstreet2':curstreet2,'curcity':curcity,'curpobox':curpobox,'perstreet1':perstreet1,'perstreet2':perstreet2,'percity':percity,'perpobox':perpobox,'relswithnominee':relswithnominee,'relsfirstname':relsfirstname,'relslastname':relslastname,'relsmobile':relsmobile,'relsemail':relsemail,'nomstreet1':nomstreet1,'nomstreet2':nomstreet2,'nomcity':nomcity,'nompobox':nompobox,'idproofupload':idproofupload,'frontpage':frontpage,'backpage':backpage};
      
      $.ajax({
            beforeSend: function(xhr, settings) {
              xhr.setRequestHeader("X-CSRFToken", csrftoken);
            },
            method: "POST",
            //url: "/web/payment_portal/newgiftcardregistration/",
            url: "/web/proceed_payment/",
            dataType: "json",
            data : {data:JSON.stringify(args),csrf_token: csrftoken},
            success: function(response) {
            // console.log(response.error.status)
            if(response.error.status != "4002")
            {
            localStorage.setItem('cardnumber', response.data.EnrollmentId);
            localStorage.setItem('referencenumber', response.data.RecieptID);
            self.appService.setpaymentstatus("success");
            //console.log(self.appService.getpaymentstatus(),"successssssssssssssssssssssssssssss")
            localStorage.setItem('customerName', response.data.customername);
            localStorage.setItem('payableAmount', response.data.amount);
            localStorage.setItem('encrpquery', response.data.encReq);
            localStorage.setItem('accesscode', response.data.access_code);
            localStorage.setItem('payurl', response.data.payurl);
            // console.log(response.data.access_code)
            // console.log(response.data.encReq)
            // console.log(response.data.payurl)
            self.router.navigate(['/proceedpayment'])
            
            }
            else
            {
            localStorage.setItem('cardnumber', "undefined");
            localStorage.setItem('referencenumber', "Not updated");
            self.appService.setpaymentstatus("failed");
            // console.log(self.appService.getpaymentstatus(),"failed")
            self.router.navigate(['/paymentpreview'])

            }
            self.pandrboolean=false;
            self.router.navigate(['/proceedpayment'])
            self.spinner.hide();
            },
            error: function(err) {
              console.log('err')
              
            }
            });
  }
  self.spinner.hide();
  }

giftcardSubmit(value){
  // console.log($('#payment_channel'))
  var self=this;
  // console.log(value)
  let monthdenomamt = value.monthdenomamt
  let tenure = self.tenureselected
  let totamt = self.tenureselected*self.denomamt
  let persfirstname = value.persfirstname
  let perslastname = value.perslastname
  let persdob = value.persdob
  let persmobile = value.loginmob
  let persemail = value.loginemailid
  let persbeneficiary = value.persbeneficiary
  let curstreet1 = value.curstreet1
  let curstreet2 = value.curstreet2
  let curcity = value.curcity
  let curpobox = value.curpobox
  let perstreet1 =self.perstreet1
  let perstreet2 = self.perstreet2
  let percity = self.percity
  let perpobox = self.perpobox
  let relswithnominee = self.relswithnomineeselected
  let relsfirstname = value.relsfirstname
  let relslastname = value.relslastname
  let relsmobile = value.relsmobile
  let relsemail = value.relsemail
  let nomstreet1 = self.nomstreet1
  let nomstreet2 = self.nomstreet2
  let nomcity = self.nomcity
  let nompobox = self.nompobox
  let modeofpay = value.modeofpay
  let paymentchannel = value.paymentchannel
  let giftcardtype = value.giftcardtype
  let idproofupload = self.idproofvalue
  let idproofid = self.idproofid
  let frontpage = self.fileToUploadfront
  let backpage = self.fileToUploadback
  var fields = $(".fields-required")
  var missing_fields = "Please enter all mandatory fields..."
  // console.log(fields)
  $.each(fields, function(i, field) {
    var val =$(field).val()
    // console.log(val,"value")
    self.form_valid = true
    if(val=='' || val == '0' || val == null)
    {
        $(field).focus();
        self.form_valid = false
        return self.form_valid;
        //var name = $(field).attr('data-attr')
        //missing_fields += '\n\u2022 '+name+'.'
    }

   });
   //missing_fields+='</ul>'
   if(self.form_valid)
   {
      self.pandrboolean=true;
      this.tandcfoot = false;
      this.termandconClass = true;
      this.termandconStyle = {'display': 'block'};

      var ggg=self.spinner.show();
      var session_id='';
      if(this.getCookie('kalyan_id')!=null){
        session_id=this.getCookie('kalyan_id');Date
      }  
      let ref=localStorage.getItem('refby')
      self.argsvals = {'session': session_id,'scheme_id':giftcardtype,'monthdenomamt':monthdenomamt,'tenure':tenure,'totamt':totamt,'persfirstname':persfirstname,'perslastname':perslastname,'persdob':persdob,'persmobile':persmobile,'persemail':persemail,'persbeneficiary':persbeneficiary,'curstreet1':curstreet1,'curstreet2':curstreet2,'curcity':curcity,'curpobox':curpobox,'perstreet1':perstreet1,'perstreet2':perstreet2,'percity':percity,'perpobox':perpobox,'relswithnominee':relswithnominee,'relsfirstname':relsfirstname,'relslastname':relslastname,'relsmobile':relsmobile,'relsemail':relsemail,'nomstreet1':nomstreet1,'nomstreet2':nomstreet2,'nomcity':nomcity,'nompobox':nompobox,'idproofupload':idproofupload,'frontpage':frontpage,'backpage':backpage,'new_registration':true,'payment_mode':modeofpay,'payment_channel':paymentchannel,'ref':ref};
      var csrftoken = self.getCookie('csrf_token');
      self.spinner.hide();
      document.getElementById('tc_btn').click();
   }
   //else
   //{
      //this.registerWarningClass = true;
      //this.registerWarningStyle = {'display': 'flex'};
      //$('#err-msg').text(missing_fields)
   //}
  

}
rendergiftcardregistration(mobile_number){
var self= this;
self.spinner.show();
// console.log(mobile_number)
   var session_id='';
    if(this.getCookie('kalyan_id')!=null){
      session_id=this.getCookie('kalyan_id');Date
    }  
    let args = {'session': session_id,'mobile_number':mobile_number };
   var csrftoken = this.getCookie('csrf_token');
   $.ajax({
            beforeSend: function(xhr, settings) {
              xhr.setRequestHeader("X-CSRFToken", csrftoken);
            },
            method: "POST",
            url: "/web/payment_portal/giftcarddatapopulation/",
            dataType: "json",
            data : {data:JSON.stringify(args),csrf_token: csrftoken},
            success: function(response) {
            // console.log(response)
            // console.log(response.data[0].firstname,"kfkfkfkfkfkfkfkdfl")
            if (response.data[0].firstname != ""){
            self.isFirstnamecon = true
            }
            
            self.persfirstname=response.data[0].firstname, 
            self.perslastname=response.data[0].lastname,
            self.persdob=response.data[0].dob, 
            self.persmobile=response.data[0].mobilenumber , 
            self.persemail=response.data[0].email,
            self.curstreet1=response.data[0].curstreet1,
            self.curstreet2=response.data[0].curstreet2,
            self.curcity=response.data[0].curcity,
            self.curpobox=response.data[0].curpobox,
            self.curcountry=response.data[0].curcountry, 
            self.perstreet1=response.data[0].perstreet1, 
            self.perstreet2=response.data[0].perstreet2 ,
            self.percity= response.data[0].percity,
            self.perpobox=response.data[0].perpobox,
            self.percountry=response.data[0].percountry;
            self.country_code = response.data[0].country_code;
            self.country_name = response.data[0].country_name;
            self.countries = response.data[0].countries;
            self.schemes = response.data[0].schemes;
            self.tcheader = response.data[0].tc.heading;
            self.tcgeneral = response.data[0].tc.general;
            self.tcbenefits = response.data[0].tc.benefits;
            self.min_amount = response.data[0].min_amount;
            self.max_amount = response.data[0].max_amount;
            self.multiples_of = response.data[0].multiples;
            $('#min_amount').val(self.min_amount);
            $('#max_amount').val(self.max_amount);
            if (self.country_code != '784')
            {
              self.curcity = self.country_name
              self.nomcity = self.country_name
              self.percity = self.country_name
            }

            // console.log(self.schemes)
            // console.log(self.min_amount,self.max_amount,self.multiples_of,self.curcountry)


            //self.onetimeregis=0 
            self.newGiftcard ={
            monthdenomamt:null,
            tenure:null,
            persfirstname:self.persfirstname, 
            perslastname:self.perslastname,
            persdob:self.persdob, 
            persbeneficiary: "",
            relswithnominee: "",
            relsfirstname:"",
            relslastname: "",
            relsmobile: "",
            relsemail: "",
            modeofpay:"",
            paymentchannel:"",
            giftcardtype:"",
            
            };
            self.spinner.hide(); 
            },
            error: function(err) {
              console.log('err')
             
            }
            });
            }


renderlogout(mobile_number){
var self= this;
self.spinner.show();
// console.log(mobile_number,"registered giftcard")
   var session_id='';
    if(this.getCookie('kalyan_id')!=null){
      session_id=this.getCookie('kalyan_id');Date
    }  
    let args = {'session': session_id,'mobile_number':mobile_number };
   var csrftoken = this.getCookie('csrf_token');
  
   $.ajax({
            beforeSend: function(xhr, settings) {
              xhr.setRequestHeader("X-CSRFToken", csrftoken);
            },
            method: "POST",
            url: "/web/payment_portal/logout/",
            dataType: "json",
            data : {data:JSON.stringify(args),csrf_token: csrftoken},
            success: function(response) {
            self.spinner.hide();
            },
            error: function(err) {
              console.log('err')
             
            }
            });

   }            

}
