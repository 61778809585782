import { Component, OnInit } from '@angular/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { AppService } from '../app.service'
import * as $ from 'jquery';


@Component({
  selector: 'app-paymentpreview',
  templateUrl: './paymentpreview.component.html',
  styleUrls: ['./paymentpreview.component.css']
})
export class PaymentpreviewComponent implements OnInit {
  c_email = localStorage.getItem('odooemailid');
  c_mobile = localStorage.getItem('mobile');
  c_name=localStorage.getItem('country_name');
  opened = true;
  over = 'side';
  expandHeight = '42px';
  collapseHeight = '42px';
  displayMode = 'flat';
  ComNameStyle:object;
  SpanStyle:object;
  mnumber=localStorage.getItem('mobile_Number');
  cusname=localStorage.getItem('cusname');
  cardnumber=localStorage.getItem('cardnumber');
  //referencenumber=localStorage.getItem('referencenumber');
  paystats= this.appService.getpaymentstatus()
  pstats=localStorage.setItem('pstats', this.paystats);
  //paymentstatus=localStorage.getItem('pstats');
  referencenumber = ''
  paymentstatus = ''
  amount=''
  // gateway = ''
  si_status=''
  knet_transaction_id = ''
  knet_trackid = ''
  knet_Paymentid = ''        
  knet_ref_no = ''
  date = ''
  time = ''
  watcher: Subscription;
  constructor(mediaObserver: MediaObserver,private router: Router,public spinner: NgxSpinnerService,public appService: AppService) {
    this.watcher = mediaObserver.media$.subscribe((change: MediaChange) => {
      if (change.mqAlias === 'sm' || change.mqAlias === 'xs') {
        this.opened = false;
        this.over = 'over';
        this.ComNameStyle ={'display':'block'};
        this.SpanStyle ={'margin-left':'15%'};
        
      } else {
        this.opened = true;
        this.over = 'side';
        this.ComNameStyle ={'display':'block'};
        this.SpanStyle ={'margin-left':'15%'};
        
      }
    });
  }

  ngOnInit(): void {
      let sessionkey=localStorage.getItem('session_valid_key')
      // console.log(sessionkey,"kdkdhdhdbccggd")
      if(sessionkey=="logout"){
      this.router.navigate(['/home'])
      }
      var self = this;
      // console.log('sellllllllll',self)
      let params = this.router.routerState.snapshot.url;
      // console.log("Params = ", params);
      let params2 = params.split('?')
      // console.log('lllllllll',params2.length)
      let logId = params2[1].split('=')[1]
      // console.log(logId)
      if (logId){
      // console.log(logId)
      self.renderpaymentresponse(logId)
      
      }
      // let customerInfo = this.decrypt(localStorage.getItem('customerInfo'),KEY)
      let customerInfo='{}';
      //if(localStorage.getItem('customerInfo') == null)
      //{
         // customerInfo = this.decrypt(this.getCookie('customerInfo'),KEY)
      //}
      //else{
      //    customerInfo = this.decrypt(localStorage.getItem('customerInfo'),KEY)
      //}
      //var mobileNumber = JSON.parse( customerInfo ).mobileNumber;
      //let args = {txnid: params.txnid, mobileNumber: mobileNumber};

  }
   getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = $.trim(cookies[i]);
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
  }
  
  
renderpaymentresponse(recordId){
    var self= this;
    console.log('redenting',recordId)
    self.spinner.show();
    var session_id='';
    if(this.getCookie('kalyan_id')!=null){
      session_id=this.getCookie('kalyan_id');
    }  
    let args = {'session': session_id,'id':recordId};
    var csrftoken = this.getCookie('csrf_token');
    $.ajax({
            beforeSend: function(xhr, settings) {
              xhr.setRequestHeader("X-CSRFToken", csrftoken);
            },
            method: "POST",
            url: "/web/paymentlog/",
            dataType: "json",
            data : {data:JSON.stringify(args),csrf_token: csrftoken},
            success: function(response) {
            console.log(response.data)
            self.amount = response.data.amount;
            self.paymentstatus = response.data.status;
            self.referencenumber = response.data.TransactionId;
            self.cardnumber = response.data.cardnumber;
            self.si_status = response.data.si_status;
            self.knet_transaction_id = response.data.knet_transaction_id;
            self.knet_trackid = response.data.knet_trackid;
            self.knet_Paymentid = response.data.knet_Paymentid;        
            self.knet_ref_no = response.data.knet_ref_no;
            var now = new Date();
            var day = now.getDate();
            var month = now.getMonth() + 1;
            var year = now.getFullYear();
            var dateStr = month + '/' + day + '/' + year;
            var hours = now.getHours();
            var minutes = now.getMinutes();
            var seconds = now.getSeconds();
            var timeStr = hours + ':' + minutes + ':' + seconds;
            self.date = dateStr;        
            self.time = timeStr;
            self.amount=response.data.amount
            // self.gateway= response.data.gateway;
            self.router.navigate(['/paymentpreview'])
            self.spinner.hide();
            },
            error: function(err) {
              console.log(err)
             
            }
            });
}



  logout = function(){
   
  var self = this;
  self.spinner.show(); 
  self.router.navigate(['/home'])
  self.renderlogout(localStorage.getItem('odoomobilenum'))
  localStorage.setItem('session_valid_key', "logout");
  self.spinner.hide(); 
}
rglink = function(){
   
  var self = this;
  self.spinner.show(); 
  self.router.navigate(['/giftcardregistered'])
  self.spinner.hide(); 
}
gclink = function(){
   
  var self = this;
  self.spinner.show(); 
  self.router.navigate(['/giftcard'])
  self.spinner.hide(); 
}
glistlink = function(){
   
  var self = this;
  self.spinner.show(); 
  self.router.navigate(['/giftcardlist'])
  self.spinner.hide(); 
}
backaction = function(){
   
  var self = this;
  self.spinner.show(); 
  self.router.navigate(['/giftcardregistered'])
  self.spinner.hide(); 
}


renderlogout(mobile_number){
var self= this;
self.spinner.show();
console.log(mobile_number,"registered giftcard")
   var session_id='';
    if(this.getCookie('kalyan_id')!=null){
      session_id=this.getCookie('kalyan_id');Date
    }  
    let args = {'session': session_id,'mobile_number':mobile_number };
   var csrftoken = this.getCookie('csrf_token');
  
   $.ajax({
            beforeSend: function(xhr, settings) {
              xhr.setRequestHeader("X-CSRFToken", csrftoken);
            },
            method: "POST",
            url: "/web/payment_portal/logout/",
            dataType: "json",
            data : {data:JSON.stringify(args),csrf_token: csrftoken},
            success: function(response) {
            self.spinner.hide();
            },
            error: function(err) {
              console.log(err)
             
            }
            });

   }

}
