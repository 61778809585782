<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta http-equiv="X-UA-Compatible" content="ie=edge">
  <title>Kalayan Jewellers - Buy / Top-up Gift Cards</title>
  <link rel="shortcut icon" href="image/favicon.png" type="image/x-icon">
  <!-- Bootstrap , fonts & icons  -->
  <link rel="stylesheet" href="/assets/css/bootstrap.css">
  <link rel="stylesheet" href="/assets/fonts/icon-font/css/style.css">
  <link rel="stylesheet" href="/assets/fonts/typography-font/typo.css">
  <link rel="stylesheet" href="/assets/fonts/fontawesome-5/css/all.css">
  <!-- Plugin'stylesheets  -->
  <link rel="stylesheet" href="/assets/plugins/aos/aos.min.css">
  <link rel="stylesheet" href="/assets/plugins/fancybox/jquery.fancybox.min.css">
  <link rel="stylesheet" href="/assets/plugins/nice-select/nice-select.min.css">
  <link rel="stylesheet" href="/assets/plugins/slick/slick.min.css">
  <link rel="stylesheet" href="/assets/plugins/ui-range-slider/jquery-ui.css">
  <!-- Vendor stylesheets  -->
  <link rel="stylesheet" href="/assets/css/main.css">
  <!-- Custom stylesheet -->
</head>

<body>
  <ngx-spinner></ngx-spinner>
  <mat-sidenav-container class="site-wrapper overflow-hidden">




  <mat-sidenav-content>
    <mat-toolbar style="background-color: #e72126 !important;" class="header-box-shadow">
       <!--<button mat-icon-button (click)="sidenav.toggle()" style="outline: none;" fxShow.sm="true" fxShow.gt-sm="false">
        <mat-icon>menu</mat-icon>
      </button>-->
      <img style="float:left" src="/assets/image/favicon.ico"/>
      <!-- <span id="id_menu_heading_web" style="color: white"  fxFlex><a style="color:white;" routerLink='/home'>Kalyan Jewellers</a></span> -->
      <!-- <span style="color: white" [ngStyle]='ComNameStyle' fxFlex><a style="color:white;" routerLink='/home'>Kalyan Jewellers</a></span> -->
    <span class="example-spacer"></span>

     <!--<span>{{cusname | uppercase}}</span>-->
     <!-- <button mat-button style="background-color: inherit;color: white;font-size: 14px;font-weight: 500;"><a class="nav-link" style="color:white;font-size: 14px;font-weight: 500;" target="new" href="https://www.candere.com"
        aria-expanded="false"><span>Shop Online at Candere</span></a>
    </button>
     <button mat-button style="background-color: inherit;color: white;font-size: 14px;font-weight: 500;"><a class="nav-link" style="color:white;font-size: 14px;font-weight: 500;" target="new" href="https://kalyanjewellers.net/contact-us.php"
        aria-expanded="false"><span>Support</span></a></button>-->

<!--        <button mat-button style="background-color: inherit;color: white;font-size: 14px;font-weight: 500;" (click)="glistlink()">Giftcard List </button> -->
   <!--<button mat-button style="background-color: inherit;color: white;font-size: 14px;font-weight: 500; text-align:left"><a style="color:white;font-size: 14px;font-weight: 500;" target="new" href="https://www.candere.com"
        aria-expanded="false"><span>Shop Online at Candere</span></a>
    </button>-->



<div><span></span></div>
<mat-divider></mat-divider>
 <!-- <button mat-menu-item><a class="nav-link" style="color:black;font-size: 14px;font-weight: 500;" target="new" href="https://www.candere.com"
        aria-expanded="false"><span>Shop Online at Candere</span></a></button>
        <mat-divider></mat-divider>
  <button mat-menu-item><a class="nav-link" style="color:black;font-size: 14px;font-weight: 500;" target="new"  href="https://kalyanjewellers.net/contact-us.php"
        aria-expanded="false"><span>Support</span></a></button>
        <mat-divider></mat-divider>-->
   <!-- <button mat-button fxHide.xs style="background-color: inherit;color: white;font-size: 14px;font-weight: 500;" (click)="gclink()"><mat-label>New Giftcard</mat-label> -->
    <!-- </button> -->
      <button mat-button fxHide.xs style="background-color: inherit;color: white;font-size: 14px;font-weight: 500;" (click)="rglink()">
          Registered Giftcard
         </button>

    <button mat-button fxHide.xs style="background-color: inherit;color: white;font-size: 14px;font-weight: 500;"><a style="color:white;font-size: 14px;font-weight: 500;" target="new" href="https://kalyanjewellers.net/contact-us.php"
        aria-expanded="false"><span>Support</span></a></button>

    <button mat-button fxHide.xs [matMenuTriggerFor]="customMenu"> <a>
          <mat-icon style="color:white;border: none;outline:none;" class="v-align-middle">account_circle</mat-icon>

        </a></button>
    <mat-menu #customMenu="matMenu">
   <div mat-menu-item  fxHide.xs style="background-color: white;border: none;outline:none;" >
      <mat-label >Email   : {{c_email}}<br/></mat-label>
    </div>
    <div mat-menu-item  fxHide.xs style="background-color: white;border: none;outline:none;" >
      <mat-label >Mobile  : {{c_mobile}}</mat-label>
    </div>
    <div mat-menu-item  fxHide.xs style="background-color: white;border: none;outline:none;" >
      <mat-label >Country : {{c_name}}</mat-label>
    </div>
    <mat-divider></mat-divider>
    <button mat-menu-item  fxHide.xs  style="background-color: white;border: none;outline:none;" >
      <mat-label><button mat-raised-button color="warn" routerLink="/profile" style="border: none;outline:none;float:left!important">Profile</button><button mat-raised-button color="warn" style="border: none;outline:none;float:right" (click)="logout()">Logout</button></mat-label>
    </button>
    </mat-menu>
    <mat-divider></mat-divider>

    <button mat-icon-button [matMenuTriggerFor]="dropMenu" style="outline: none;color:white;" fxHide fxShow.xs>
        <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #dropMenu="matMenu">
        <ng-container >
          <!-- <button mat-button  style="background-color: inherit;color: grey;font-size: 14px;font-weight: 500;height:36px;" (click)="gclink()"><mat-label>New Giftcard</mat-label> -->
    <!-- </button> -->
    <br/>
      <button mat-button  style="background-color: inherit;color: grey;font-size: 14px;font-weight: 500;height:36px;" (click)="rglink()">
          Registered Giftcard
         </button>
         <br/>

    <button mat-button style="background-color: inherit;color: white;font-size: 14px;font-weight: 500;height:36px;"><a style="color:grey;font-size: 14px;font-weight: 500;height:36px;" target="new" href="https://kalyanjewellers.net/contact-us.php"
        aria-expanded="false"><span>Support</span></a></button>
        <br/>
   <button mat-menu-item routerLink="/profile" style="background-color: white;color:grey;border: none;outline:none;font-weight: 500;height:36px;" >

      <mat-label>Profile</mat-label>
    </button>


            <button mat-menu-item (click)="logout()" style="background-color: white;color:grey;font-weight: 500;height:36px;height:36px;" >
              <mat-label>Logout</mat-label>
                <mat-icon class="mr">logout</mat-icon>

            </button>
            <mat-divider></mat-divider>
        </ng-container>
    </mat-menu>





    </mat-toolbar>



<!-- ================================ -->

  <mat-card>
  <div class="bg-default-1 pt-5">

      <div class="container" style="margin-top: auto;margin-left:auto;background: white">


           <mat-card>
            <form ngNoForm #nonseamless id="nonseamless" name="nonseamless" method="post" name="redirect" action="">
                <div id="proceed_cybersource_payment_div">

                    <input type="hidden" id="amount" name="amount"/>
                    <input type="hidden" id="access_key" name="access_key" />
                    <input type="hidden" id="profile_id" name="profile_id" />
                    <input type="hidden" id="transaction_uuid" name="transaction_uuid" />
                    <input type="hidden" id="signed_field_names" name="signed_field_names" />
                    <input type="hidden" id="unsigned_field_names" name="unsigned_field_names" />
                    <input type="hidden" id="signed_date_time" name="signed_date_time" />
                    <input type="hidden" id="locale" name="locale" />
                    <input type="hidden" id="currency" name="currency" />
                    <input type="hidden" id="reference_number" name="reference_number" />
                    <input type="hidden" id="transaction_type" name="transaction_type" />
                    <br/>
                    <input type="hidden" id="bill_to_forename" name="bill_to_forename" />
                    <input type="hidden" id="bill_to_surname" name="bill_to_surname" />
                    <input type="hidden" id="bill_to_email" name="bill_to_email" />
                    <input type="hidden" id="bill_to_address_line1" name="bill_to_address_line1" />
                    <input type="hidden" id="bill_to_phone" name="bill_to_phone" />
                    <input type="hidden" id="bill_to_address_city" name="bill_to_address_city" />
                    <input type="hidden" id="bill_to_address_postal_code" name="bill_to_address_postal_code" />
                    <input type="hidden" id="customer_ip_address" name="customer_ip_address" />
                    <input type="hidden" id="bill_to_address_state" name="bill_to_address_state" />
                    <input type="hidden" id="bill_to_address_country" name="bill_to_address_country" />

                    <input type="hidden" id="merchant_defined_data1" name="merchant_defined_data1" />
                    <input type="hidden" id="merchant_defined_data2" name="merchant_defined_data2" />
                    <input type="hidden" id="merchant_defined_data3" name="merchant_defined_data3" />
                    <input type="hidden" id="merchant_defined_data4" name="merchant_defined_data4" />
                    <input type="hidden" id="merchant_defined_data5" name="merchant_defined_data5" />
                    <input type="hidden" id="merchant_defined_data6" name="merchant_defined_data6" />
                    <input type="hidden" id="merchant_defined_data7" name="merchant_defined_data7" />
                    <input type="hidden" id="merchant_defined_data8" name="merchant_defined_data8" />
                    <input type="hidden" id="merchant_defined_data20" name="merchant_defined_data20" />

                    <input type="hidden" id="signature" name="signature" />

                    <p>Please wait while your cybersource transaction is being processed. You will be automatically redirected to the confirmation page.Please do not hit "back", "forward" or "refresh" button.</p>
                    <div class="form-group text-center m-t-15">
                    <div class="col-xs-12">
                    <!-- <button type="submit"  class="btn btn-sm btn-primary" style="display: none" style="color:white;background-color: red;align:center;" id="confirm_payment" >Confirm</button> -->
                    </div>
                    </div>
                </div>
                <br/>
            </form>
           <script type="text/javascript">
             $('#nonseamless').submit()
           </script>
             <!-- <p class="font-weight-semibold text-center text-uppercase float-left">Payment information</p>
              <br/>
              <hr>
            	<br/>

              <table class="table table-sm table-bordered  w-50 text-center">
                <tr >
                  <td style="width: 40%" class="bg-gray text-white">Card Number</td>
                  <td>{{cardnumber}}</td>
                </tr>
                <tr>
                  <td style="width: 40%" class="bg-gray text-white pt-3">Payment Status</td>
                  <td><p *ngIf="paymentstatus=='success'">{{paymentstatus}}<i class="fa fa-check-circle ml-2 text-primary"></i></p>
                <p *ngIf="paymentstatus!='success'">{{paymentstatus}}<i class="fa fa-times-circle ml-2 text-danger"></i></p></td>
                </tr>
                <tr>
                  <td style="width: 40%" class="bg-gray text-white">Reference Number</td>
                  <td>{{referencenumber}}</td>
                </tr>
              </table>-->
<!--<hr>-->



</mat-card>



      </div>
    </div>
    </mat-card>

    <!-- Main Content end -->
    <!-- footer area function start -->
    <!-- cta section -->

     </mat-sidenav-content>


    <!-- footer area function end -->
  <!-- </div> -->
  </mat-sidenav-container>
  <!-- Vendor Scripts -->
  <script src="/assets/js/vendor.min.js"></script>
  <!-- Plugin's Scripts -->
  <script src="/assets/plugins/fancybox/jquery.fancybox.min.js"></script>
  <script src="/assets//assets/plugins/nice-select/jquery.nice-select.min.js"></script>
  <script src="/assets/plugins/aos/aos.min.js"></script>
  <script src="/assets/plugins/slick/slick.min.js"></script>
  <script src="/assets/plugins/counter-up/jquery.counterup.min.js"></script>
  <script src="/assets/plugins/counter-up/jquery.waypoints.min.js"></script>
  <script src="/assets/plugins/ui-range-slider/jquery-ui.js"></script>
  <!-- Activation Script -->
  <!-- <script src="js/drag-n-drop.js"></script> -->
  <script src="/assets/js/custom.js"></script>
</body>



</html>


