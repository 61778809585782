import { Component, OnInit } from '@angular/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { AppService } from '../app.service'
import * as $ from 'jquery';



@Component({
  selector: 'app-paymentresponse',
  templateUrl: './paymentresponse.component.html',
  styleUrls: ['./paymentresponse.component.css']
})
export class PaymentresponseComponent implements OnInit {

  opened = true;
  over = 'side';
  expandHeight = '42px';
  collapseHeight = '42px';
  displayMode = 'flat';
  ComNameStyle:object;
  SpanStyle:object;
  amount=localStorage.getItem('payableAmount');
  customerName=localStorage.getItem('customerName');
  paymentstatus:string;
  cardnumber:string;
  refnumber:string;
  c_email = localStorage.getItem('odooemailid');
  c_mobile = localStorage.getItem('mobile');
  c_name=localStorage.getItem('country_name');
  si_status=''
  
  referencenumber=localStorage.getItem('referencenumber');
  paymentURL = localStorage.getItem('payurl');
  accescode = localStorage.getItem('accesscode');
  encryptedString = localStorage.getItem('encrpquery');
  giftcardTopupId = localStorage.getItem('giftcardTopupId');
  resparam = localStorage
  

  watcher: Subscription;
  constructor(mediaObserver: MediaObserver,private router: Router,public spinner: NgxSpinnerService,public appService: AppService) {
    // console.log('locallllllllllll',localStorage)
    this.watcher = mediaObserver.media$.subscribe((change: MediaChange) => {
      if (change.mqAlias === 'sm' || change.mqAlias === 'xs') {
        this.opened = false;
        this.over = 'over';
        this.ComNameStyle ={'display':'block'};
        this.SpanStyle ={'margin-left':'15%'};
        
      } else {
        this.opened = true;
        this.over = 'side';
        this.ComNameStyle ={'display':'none'};
        this.SpanStyle ={'margin-left':'15%'};
        
      }
    });
  }

  ngOnInit(): void {
      var spin = this.spinner
      spin.show()
      var route = this.router
      var url_string = window.location.href
      var url = new URL(url_string);
      var r_indicator = url.searchParams.get('resultIndicator')
      // console.log(r_indicator)
      var log_id = localStorage.getItem('log_id')
      var mobile = localStorage.getItem('odoomobilenum')
      var request_data = localStorage.getItem('request_data')
      // console.log(mobile)
      var csrf_token = this.getCookie('csrftoken')
      var args = {'url':url_string,'log_id':log_id,'result_indicator':r_indicator,'mobile_no':mobile,'csrftoken':csrf_token,'request_data':request_data}
      $.ajax({
        url:'/web/payment_portal/paymentResponse/',
        method:'POST',
        data:args,
        dataType:'json',
        success:function(response){
            response['csrftoken'] = csrf_token
            $.ajax({
              url:'/web/paymentlog/',
              method:'POST',
              data:{'data':JSON.stringify(response)},
              dataType:'json',
              success:function(logresponse){
                  var pre_data = logresponse.data
                  this.paymentstatus = pre_data.status
                  this.si_status = response.si_status;
                  if(pre_data.status == 'success')
                  {
                      $('#success-mark').show();
                      $('#success-cnt').show();
                  }
                  else
                  {
                      $('#fail-cnt').show();
                      $('#fail-mark').show();
                  }
                  if (pre_data.cardnumber!=false)
                  {
                      this.cardnumber = pre_data.cardnumber
                  }
                  else
                  {
                      this.cardnumber = '--'
                  }
                  if (pre_data.TransactionId!=false)
                  {
                      this.refnumber = pre_data.TransactionId
                  }
                  else
                  {
                      this.refnumber = '--'
                  }

                  $('#cardnumber').text(this.cardnumber);
                  $('#referencenumber').text(this.refnumber);
                  spin.hide()
              }

            })
        }

      })

  }


  getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = $.trim(cookies[i]);
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
  }

 logout = function(){
   
  var self = this;
  self.spinner.show(); 
  self.router.navigate(['/home'])
  self.renderlogout(localStorage.getItem('odoomobilenum'))
  localStorage.setItem('session_valid_key', "logout");
  self.spinner.hide(); 
}
rglink = function(){
   
  var self = this;
  self.spinner.show(); 
  self.router.navigate(['/giftcardregistered'])
  self.spinner.hide(); 
}
gclink = function(){
   
  var self = this;
  self.spinner.show(); 
  self.router.navigate(['/giftcard'])
  self.spinner.hide(); 
}
glistlink = function(){
   
  var self = this;
  self.spinner.show(); 
  self.router.navigate(['/giftcardlist'])
  self.spinner.hide(); 
}
backaction = function(){
   
  var self = this;
  self.spinner.show(); 
  self.router.navigate(['/giftcardregistered'])
  self.spinner.hide(); 
}


renderlogout(mobile_number){
var self= this;
self.spinner.show();
// console.log(mobile_number,"registered giftcard")
   var session_id='';
    if(this.getCookie('kalyan_id')!=null){
      session_id=this.getCookie('kalyan_id');Date
    }  
    let args = {'session': session_id,'mobile_number':mobile_number };
   var csrftoken = this.getCookie('csrf_token');
  
   $.ajax({
            beforeSend: function(xhr, settings) {
              xhr.setRequestHeader("X-CSRFToken", csrftoken);
            },
            method: "POST",
            url: "/web/payment_portal/logout/",
            dataType: "json",
            data : {data:JSON.stringify(args),csrf_token: csrftoken},
            success: function(response) {
            self.spinner.hide();
            },
            error: function(err) {
              console.log('err')
             
            }
            });

   }

}
