import { Component, OnInit } from '@angular/core';

import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { AppService } from '../app.service'
import { GiftcardTopup } from '../data-model';
import * as $ from 'jquery';


@Component({
  selector: 'app-giftcardtopup',
  templateUrl: './giftcardtopup.component.html',
  styleUrls: ['./giftcardtopup.component.css']
})
export class GiftcardtopupComponent implements OnInit {

  opened = true;
  over = 'side';
  expandHeight = '42px';
  collapseHeight = '42px';
  displayMode = 'flat';
  ComNameStyle:object;
  SpanStyle:object;
  FieldStyle:object;
  couponStyle={'display':'none'};
  giftCardTopup: GiftcardTopup = new GiftcardTopup();
  mobile_number: any;
  mnumber=localStorage.getItem('mobile_Number');
  cusname=localStorage.getItem('cusname');
  regisdate:any;
  paidamount:any;
  pendingamount:any;
  totalamount:any;
  denomamount:any;
  nopaiddenom:any;
  cusid:any;
  mobile:any;
  cardid:any;
  topupmodeofpay="";
  topuppaymentchannel="";
  registerWarningClass: boolean;
  registerWarningStyle: object;
  warning_content:any;
  coupon_code=""
  isPayandredeem=false
  //isparedem="false"
  maturity_date:any;
  si_status="";
  accesscode=""
  encrpquery=""
  payurl = ""
  customerName = ""
  payableAmount = ""
  BrStyle:object;
  MrStyle:object;
  currency:string;
  country_code:string;
  c_email = localStorage.getItem('odooemailid');
  c_mobile = localStorage.getItem('mobile');
  c_name=localStorage.getItem('country_name');
  session_key = localStorage.getItem('session_valid_key');
  watcher: Subscription;
  constructor(mediaObserver: MediaObserver,private router: Router,public spinner: NgxSpinnerService,public appService: AppService) {
    this.watcher = mediaObserver.media$.subscribe((change: MediaChange) => {
      if (change.mqAlias === 'sm' || change.mqAlias === 'xs') {
        this.opened = false;
        this.over = 'over';
        this.ComNameStyle ={'display':'block'};
        this.SpanStyle ={'margin-left':'15%'};
        this.FieldStyle ={'width':'250px','margin-left':'15%'};
        this.BrStyle ={'display':'block'};
        this.MrStyle ={'margin-top':'15px'};
        
        
      } else {
        this.opened = true;
        this.over = 'side';
        this.ComNameStyle ={'display':'block'};
        this.SpanStyle ={'margin-left':'15%'};
        this.FieldStyle ={'width':'39%','margin-left':'15%'};
        this.BrStyle ={'display':'none'};
         this.MrStyle ={'margin-top':'0px'};

      }
    });
  }

  ngOnInit(): void {
  // console.log(localStorage.getItem('currency'))
  // console.log($('#modeofpay').val(),'ksdfhsgdfhj')
  let sessionkey=localStorage.getItem('session_valid_key')
  // console.log(sessionkey,"kdkdhdhdbccggd")
  if(sessionkey=="logout"){
  this.router.navigate(['/home'])
  }
  //this.isparedem=localStorage.getItem('isspayandredeem')
  this.rendergiftcardstopups(localStorage.getItem('giftcardtopupcardid'))
  this.currency = localStorage.getItem('currency')
  this.country_code = localStorage.getItem('country_code')
  }
  getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = $.trim(cookies[i]);
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
  }

setCouponApply(e){
  var self = this;
  if(e.checked){
  self.couponStyle={'display': 'block'};
  }
  else{
  self.couponStyle={'display': 'none'};
  self.coupon_code=""
  }
  
}



hideWarning = function(){
    this.registerWarningClass = false;
    this.registerWarningStyle = {'display': 'none'};
  }




  logout = function(){
   
  var self = this;
  self.spinner.show(); 
  self.router.navigate(['/home'])
  self.renderlogout(localStorage.getItem('odoomobilenum'))
  localStorage.setItem('session_valid_key', "logout");
  localStorage.clear();
  self.spinner.hide(); 
}
rglink = function(){
   
  var self = this;
  self.spinner.show(); 
  self.router.navigate(['/giftcardregistered'])
  self.spinner.hide(); 
}
gclink = function(){
   
  var self = this;
  self.spinner.show(); 
  self.router.navigate(['/giftcard'])
  self.spinner.hide(); 
}
glistlink = function(){
   
  var self = this;
  self.spinner.show(); 
  self.router.navigate(['/giftcardlist'])
  self.spinner.hide(); 
}

backaction = function(){
   
  var self = this;
  self.spinner.show(); 
  self.router.navigate(['/giftcardregistered'])
  self.spinner.hide(); 
}

giftcardpaymentpreview = function(data){
  var cardid = data['topup_id']
  var denomamount = data['denomination_amount']
  var payment_channel = data['payment_channel']
  var payment_mode = data['payment_mode']
  // console.log(denomamount,cardid,payment_channel,payment_mode,"mvmvnvnbfgfgeiuweoeo")
  var self = this;
if(payment_channel =="" || payment_mode == ""){
  self.registerWarningClass = true;
        self.registerWarningStyle = {'display': 'flex'};
        self.warning_content=[]
        self.warning_content.push('Please fill the Payment  Details')
        return false;
  }
  //localStorage.setItem('isspayandredeem',"true");
  //self.isparedem=localStorage.getItem('isspayandredeem')
  self.isPayandredeem =true
  self.spinner.show();
  let ref=localStorage.getItem('refby'); 
  let args = {'session': session_id,'giftcard_topup_id':cardid,'emi_amount': denomamount,'payment_channel':payment_channel,'payment_mode':payment_mode,'mobile_no':data['mobile_no'],'ref':ref,'payitmode':'Topup'};
  if(payment_channel=="dohabank"){
      var session_id='';
        if(this.getCookie('kalyan_id')!=null){
          session_id=this.getCookie('kalyan_id');
        }  
       var csrftoken = this.getCookie('csrf_token');
        $.ajax({
            beforeSend: function(xhr, settings) {
              xhr.setRequestHeader("X-CSRFToken", csrftoken);
            },
            method: "POST",
            url: "/web/proceed_payment_doha_bank/",
            dataType: "json",
            data : {data:JSON.stringify(args),csrf_token: csrftoken},
            success: function(response) {
              // console.log(response)
              if(response.error.status == 200)
              {
                localStorage.setItem('checkout_session_id',response.data.session_id)
                localStorage.setItem('order_id',response.data.order_id)
                localStorage.setItem('emi_amount',response.data.emi_amount)
                localStorage.setItem('log_id',response.data.log_id)
                self.router.navigate(['/paymentdohabank'])
              }
              else
              {
                alert(response.error.message)
              }
              self.spinner.hide();
            },
            error: function(err) {
            console.log('err')
             
            }
            });

  

  }

  else if(payment_channel=="smart_pay"){
      var session_id='';
    if(this.getCookie('kalyan_id')!=null){
      session_id=this.getCookie('kalyan_id');
    }  
   var csrftoken = this.getCookie('csrf_token');
   $.ajax({
            beforeSend: function(xhr, settings) {
              xhr.setRequestHeader("X-CSRFToken", csrftoken);
            },
            method: "POST",
            url: "/web/proceed_payment_smart_pay/",
            dataType: "json",
            data : {data:JSON.stringify(args),csrf_token: csrftoken},
            success: function(response) {
              if(response.error.status == "3001"){
          
                self.registerWarningClass = true;
                self.registerWarningStyle = {'display': 'flex'};
                self.warning_content=[]
                self.warning_content.push('Payment not allowed at this time. Please contact Kalyan support for more details')
                self.isPayandredeem =false
                self.spinner.hide();
                }
                else{
                if(response.error.status != "4002")
                {
                localStorage.setItem('customerName', response.data.customername);
                localStorage.setItem('payableAmount', response.data.amount);
                localStorage.setItem('encrpquery', response.data.encReq);
                localStorage.setItem('accessCode', response.data.access_code);
                localStorage.setItem('payurl', response.data.payurl);
                
                self.router.navigate(['/smartpay'])
                }
                else
                {
                localStorage.setItem('cardnumber', "undefined");
                localStorage.setItem('referencenumber', "Not updated");
                self.appService.setpaymentstatus("failed");
                // console.log(self.appService.getpaymentstatus(),"failed")
                self.router.navigate(['/paymentpreview'])
                }
                self.isPayandredeem =false
                //self.isparedem=localStorage.getItem('isspayandredeem')
                self.spinner.hide();
                }
            
            },
            error: function(err) {
              console.log('err')
             
            }
            });

  

      
  }
  else if(payment_channel=="qpay"){
    var session_id='';
  if(this.getCookie('kalyan_id')!=null){
    session_id=this.getCookie('kalyan_id');
  }  
 var csrftoken = this.getCookie('csrf_token');
 $.ajax({
          beforeSend: function(xhr, settings) {
            xhr.setRequestHeader("X-CSRFToken", csrftoken);
          },
          method: "POST",
          url: "/web/proceed_payment_quick_pay/",
          dataType: "json",
          data : {data:JSON.stringify(args),csrf_token: csrftoken},
          success: function(response) {
          // console.log(response.error.status)
          if(response.error.status == "3001"){
            
            self.registerWarningClass = true;
            self.registerWarningStyle = {'display': 'flex'};
            self.warning_content=[]
            self.warning_content.push('Payment not allowed at this time. Please contact Kalyan support for more details')
            self.isPayandredeem =false
            self.spinner.hide();
            }
            else{
            if(response.error.status != "4002")
            {
              localStorage.setItem('amount', response.data.Amount);
              localStorage.setItem('currencyCode', response.data.CurrencyCode);
              localStorage.setItem('pun', response.data.PUN);
              localStorage.setItem('merchantModuleSessionID', response.data.MerchantModuleSessionID);
              localStorage.setItem('paymentDescription', response.data.PaymentDescription);
              localStorage.setItem('nationalID', response.data.NationalID);
              localStorage.setItem('merchantID', response.data.MerchantID);
              localStorage.setItem('bankID', response.data.BankID);
              localStorage.setItem('lang', response.data.Lang);
              localStorage.setItem('action', response.data.Action);
              localStorage.setItem('secureHash', response.data.SecureHash);
              localStorage.setItem('transactionRequestDate', response.data.TransactionRequestDate);
              localStorage.setItem('extraFields_f14', response.data.ExtraFields_f14);
              localStorage.setItem('quantity', response.data.Quantity);
            
            self.router.navigate(['/qpay'])
            }
            else
            {
            localStorage.setItem('cardnumber', "undefined");
            localStorage.setItem('referencenumber', "Not updated");
            self.appService.setpaymentstatus("failed");
            // console.log(self.appService.getpaymentstatus(),"failed")
            self.router.navigate(['/paymentpreview'])
            }
            self.isPayandredeem =false
            //self.isparedem=localStorage.getItem('isspayandredeem')
            self.spinner.hide();
            }
          },
          error: function(err) {
            console.log('err')
           
          }
          });



    
}

  else if(payment_channel=="knet"){
    var session_id='';
  if(this.getCookie('kalyan_id')!=null){
    session_id=this.getCookie('kalyan_id');
  }  
  var csrftoken = this.getCookie('csrf_token');
  $.ajax({
          beforeSend: function(xhr, settings) {
            xhr.setRequestHeader("X-CSRFToken", csrftoken);
          },
          method: "POST",
          url: "/web/knet_proceed/",
          dataType: "json",
          data : {data:JSON.stringify(args),csrf_token: csrftoken},
          success: function(response) {
          // console.log(response.data.response.key)
          // console.log(response)
          
          // k=response.data.encReq
          
          // localStorage.setItem('key_param', response.key);
          window.location.href='https://kpay.com.kw/kpg/PaymentHTTP.htm?param=paymentInit&trandata='+response.data.response.key;
          // self.router.navigate(['/knet'])
          },
          error: function(err) {
            console.log(err)
           
          }
          });    
  }

  else if(payment_channel=="payit"){
    var session_id='';
    if(this.getCookie('kalyan_id')!=null){
      session_id=this.getCookie('kalyan_id');
    }  
    var csrftoken = this.getCookie('csrf_token');
    $.ajax({
      beforeSend: function(xhr, settings) {
        xhr.setRequestHeader("X-CSRFToken", csrftoken);
      },
      method: "POST",
      url: "/web/payit/",
      dataType: "json",
      data : {data:JSON.stringify(args),csrf_token: csrftoken},
      success: function(response) {
      // console.log(response.error.status)
      if(response.error.status == "3001"){
      
      self.registerWarningClass = true;
      self.registerWarningStyle = {'display': 'flex'};
      self.warning_content=[]
      self.warning_content.push('Payment not allowed at this time. Please contact Kalyan support for more details')
      self.isPayandredeem =false
      self.spinner.hide();
      }
      else{
      if(response.error.status != "4002")
      {
      self.router.navigate(['/payit'])
      }
      }
      },
      error: function(err) {
        alert('Payment not able to process Please contact adminstrator')
       
      }
      });  
  }


  else if(payment_channel=="sioncc"){
    var session_id='';
  if(this.getCookie('kalyan_id')!=null){
    session_id=this.getCookie('kalyan_id');
  }  
 var csrftoken = this.getCookie('csrf_token');
 $.ajax({
          beforeSend: function(xhr, settings) {
            xhr.setRequestHeader("X-CSRFToken", csrftoken);
          },
          method: "POST",
          url: "/web/si_creation_cc_avenue/",
          dataType: "json",
          data : {data:JSON.stringify(args),csrf_token: csrftoken},
          success: function(response) {
          // console.log(response.error.status)
          if(response.error.status == "3001"){
          
          self.registerWarningClass = true;
          self.registerWarningStyle = {'display': 'flex'};
          self.warning_content=[]
          self.warning_content.push('Payment not allowed at this time. Please contact Kalyan support for more details')
          self.isPayandredeem =false
          self.spinner.hide();
          }
          else{
          if(response.error.status != "4002")
          {
          localStorage.setItem('customerName', response.data.customername);
          localStorage.setItem('payableAmount', response.data.amount);
          localStorage.setItem('encrpquery', response.data.encReq);
          localStorage.setItem('accessCode', response.data.access_code);
          localStorage.setItem('payurl', response.data.payurl);
          
          self.router.navigate(['/si_creation'])
          }
          else
          {
          localStorage.setItem('cardnumber', "undefined");
          localStorage.setItem('referencenumber', "Not updated");
          self.appService.setpaymentstatus("failed");
          // console.log(self.appService.getpaymentstatus(),"failed")
          self.router.navigate(['/paymentpreview'])
          }
          self.isPayandredeem =false
          //self.isparedem=localStorage.getItem('isspayandredeem')
          self.spinner.hide();
          }
          },
          error: function(err) {
            console.log('err')
           
          }
          });



    
}

  else{
  var session_id='';
    if(this.getCookie('kalyan_id')!=null){
      session_id=this.getCookie('kalyan_id');
    }  
   var csrftoken = this.getCookie('csrf_token');
   $.ajax({
            beforeSend: function(xhr, settings) {
              xhr.setRequestHeader("X-CSRFToken", csrftoken);
            },
            method: "POST",
            url: "/web/proceed_payment/",
            dataType: "json",
            data : {data:JSON.stringify(args),csrf_token: csrftoken},
            success: function(response) {
            // console.log(response.error.status)
            if(response.error.status == "3001"){
            
            self.registerWarningClass = true;
            self.registerWarningStyle = {'display': 'flex'};
            self.warning_content=[]
            self.warning_content.push('Payment not allowed at this time. Please contact Kalyan support for more details')
            self.isPayandredeem =false
            self.spinner.hide();
            }
            else{
            if(response.error.status != "4002")
            {
            localStorage.setItem('customerName', response.data.customername);
            localStorage.setItem('payableAmount', response.data.amount);
            localStorage.setItem('encrpquery', response.data.encReq);
            localStorage.setItem('accessCode', response.data.access_code);
            localStorage.setItem('payurl', response.data.payurl);
            
            self.router.navigate(['/proceedpayment'])
            }
            else
            {
            localStorage.setItem('cardnumber', "undefined");
            localStorage.setItem('referencenumber', "Not updated");
            self.appService.setpaymentstatus("failed");
            // console.log(self.appService.getpaymentstatus(),"failed")
            self.router.navigate(['/paymentpreview'])
            }
            self.isPayandredeem =false
            //self.isparedem=localStorage.getItem('isspayandredeem')
            self.spinner.hide();
            }
            },
            error: function(err) {
              console.log('err')
             
            }
            });

  

  }
  
}

rendergiftcardstopups(cardid){
  var self= this;
  self.spinner.show();
   var session_id='';
    if(this.getCookie('kalyan_id')!=null){
      session_id=this.getCookie('kalyan_id');Date
    }  
    let args = {'session': self.session_key,'giftcard_topup_id':cardid };
   var csrftoken = this.getCookie('csrf_token');
   $.ajax({
            beforeSend: function(xhr, settings) {
              xhr.setRequestHeader("X-CSRFToken", csrftoken);
            },
            method: "POST",
            url: "/web/payment_portal/giftcardtopup/",
            dataType: "json",
            data : {data:JSON.stringify(args),csrf_token: csrftoken},
            success: function(response) {
            
            if (response.error.status=="4002"){
               
              self.logout()
            }
            else{
              self.cusname=response.data[0].FirstName;
              self.regisdate=response.data[0].JoinDate;
              self.paidamount=parseInt(response.data[0].AmountPaid);
              self.pendingamount=parseInt(response.data[0].RemainingAmount);
              self.totalamount=parseInt(response.data[0].TotalInstallmentAmount)
              self.denomamount=parseInt(response.data[0].EMIAmount);
              self.nopaiddenom=parseInt(response.data[0].NoOfPaidInstallment);
              self.cusid=response.data[0].CustomerID;
              self.mobile=response.data[0].MobileNumber;
              self.cardid=response.data[0].EnrollmentID;
              self.mnumber=response.data[0].MobileNumber;
              self.maturity_date=response.data[0].MaturityDate;
              self.si_status = response.data[0].si_status
              $('#modeofpay').val('')
              $('#paygateway').val('')
          
              self.spinner.hide();
            }
            },
            error: function(err) {
              console.log('err')
             
            }
            });
}


// payment_gateway(session_id){
//   var report_data=session_id
//   // console.log(report_data)
//   var body=``
//   var body=`
//   <html>
//     <head>
//         <script src="https://dohabank.gateway.mastercard.com/checkout/version/58/checkout.js"
//                 data-error="errorCallback"
//                 data-cancel="cancelCallback">
//         </script>

//         <script type="text/javascript">
//             function errorCallback(error) {
//                   console.log(JSON.stringify(error));
//             }
//             function cancelCallback() {
//                   console.log('Payment cancelled');
//             }

//             Checkout.configure({
//                 merchant: 'DB96321',
//                 order: {
//                     amount: function() {
//                         //Dynamic calculation of amount
//                         return 80 + 20;
//                     },
//                     currency: 'USD',
//                     description: 'Ordered goods',
//                     id: '78767868668'
//                 },
//                 interaction: {
//                     operation: 'PURCHASE', 
//                     merchant: {
//                         name: 'KALYAN JEWELLERS INDIA PRIVATE LTD..'
//                     }
//                 },
//                 session: {
//                     id: '`+session_id+`'
//                 }
//             });
//         </script>
//     </head>
//     <body>
        
//         <input type="button" value="Pay with Lightbox" onclick="Checkout.showLightbox();" />
//         <input type="button" value="Pay with Payment Page" onclick="Checkout.showPaymentPage();" />
        
//     </body>
// </html>
//     `
// // console.log(body)
// return body;
// }

renderlogout(mobile_number){
var self= this;
self.spinner.show();
// console.log(mobile_number,"registered giftcard")
   var session_id='';
    if(this.getCookie('kalyan_id')!=null){
      session_id=this.getCookie('kalyan_id');Date
    }  
    let args = {'session': session_id,'mobile_number':mobile_number };
   var csrftoken = this.getCookie('csrf_token');
  
   $.ajax({
            beforeSend: function(xhr, settings) {
              xhr.setRequestHeader("X-CSRFToken", csrftoken);
            },
            method: "POST",
            url: "/web/payment_portal/logout/",
            dataType: "json",
            data : {data:JSON.stringify(args),csrf_token: csrftoken},
            success: function(response) {
            self.spinner.hide();
            },
            error: function(err) {
              console.log('err')
             
            }
            });

   }

}
