import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-data-screen',
  templateUrl: './no-data-screen.component.html',
  styleUrls: ['./no-data-screen.component.css']
})
export class NoDataScreenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
