import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';



@Component({
  selector: 'app-kalyan-navbar',
  templateUrl: './kalyan-navbar.component.html',
  styleUrls: ['./kalyan-navbar.component.css']
})
export class KalyanNavbarComponent implements OnInit {
  loginDisplay: boolean;
  c_email = localStorage.getItem('odooemailid');
  c_mobile = localStorage.getItem('mobile');
  c_name=localStorage.getItem('country_name');
  
  constructor(private spinner: NgxSpinnerService,private router: Router) { }

  ngOnInit(): void {

  	this.loginDisplay = false;
  }



  dismissToggler(){
    $('#sidebar').removeClass('active');
    $('.overlay').removeClass('active');
  };
  toggleMenubar(){
    $('#sidebar').addClass('active');
    $('.overlay').addClass('active');
    $('.collapse.in').toggleClass('in');
    $('a[aria-expanded=true]').attr('aria-expanded', 'false');
  };

  rglink = function(){
   
  var self = this;
  self.spinner.show(); 
  self.router.navigate(['/giftcardregistered'])
  self.spinner.hide(); 
};
gclink = function(){
   
  var self = this;
  self.spinner.show(); 
  self.router.navigate(['/giftcard'])
  self.spinner.hide(); 
};
logout = function(){
   
  if(confirm("Are you sure you want to logout ?")) {
  	var self = this;
  	self.spinner.show(); 
  	self.renderlogout(localStorage.getItem('odoomobilenum'))
  	localStorage.setItem('session_valid_key', "logout");
  	self.router.navigate(['/home'])
  	self.spinner.hide(); 
    localStorage.clear();
  }
  
};

renderlogout(mobile_number){
var self= this;
self.spinner.show();
console.log(mobile_number,"registered giftcard")
   var session_id='';
    if(this.getCookie('kalyan_id')!=null){
      session_id=this.getCookie('kalyan_id');
    }  
    let args = {'session': session_id,'mobile_number':mobile_number };
   var csrftoken = this.getCookie('csrf_token');
  
   $.ajax({
            beforeSend: function(xhr, settings) {
              xhr.setRequestHeader("X-CSRFToken", csrftoken);
            },
            method: "POST",
            url: "/web/payment_portal/logout/",
            dataType: "json",
            data : {data:JSON.stringify(args),csrf_token: csrftoken},
            success: function(response) {
            self.spinner.hide();
            },
            error: function(err) {
              console.log(err)
             
            }
            });

   };

getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = $.trim(cookies[i]);
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
  };
}
