<app-kalyan-navbar></app-kalyan-navbar>
<div class="container mt-25">
  <div class="row">
    <div class="col-md-6 mx-auto mt-5">
      <div class="payment">
        <div class="payment_header">
          <div class="check" id="success-mark"><i class="fa fa-check text-success" aria-hidden="true"></i></div>
          <div class="check" id="fail-mark"><i class="fa fa-times text-danger" aria-hidden="true"></i></div>
        </div>
        <div class="content">
          <h1 id="success-cnt">Payment Success !</h1>
          <h1 id="fail-cnt">Payment Failed !</h1>
          <p>Please find below your gift card number and payment reference number. </p>
          <p><b>Giftcard No. :</b><span id="cardnumber"></span></p>
          <p><b>Reference Number :</b><span id="referencenumber"></span></p>
          <p *ngIf="si_status=='yes' && paymentstatus=='success'"><b>Successfully Registered for Monthly Auto debit from
              credit card</b></p>
          <a href="/giftcardregistered">Go to Home</a>
        </div>

      </div>
    </div>
  </div>
</div>