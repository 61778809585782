import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { AppService } from '../app.service'
import * as $ from 'jquery';

@Component({
  selector: 'app-knet',
  templateUrl: './knet.component.html',
  styleUrls: ['./knet.component.css']
})
export class KnetComponent implements OnInit {
  encryptedString = localStorage.getItem('key_param');
  constructor(private router: Router,public spinner: NgxSpinnerService) { 
    
  }

  ngOnInit(): void {
  	let sessionkey=localStorage.getItem('session_valid_key')
    console.log(sessionkey,"kdkdhdhdbccggd")
    if(sessionkey=="logout"){
    this.router.navigate(['/home'])
    
    }
    $('#encRequest').val(localStorage.getItem('key_param'));
    // $('#access_code').val(ac);
    $('#nonseamless').submit();
  }

}
