<app-kalyan-navbar></app-kalyan-navbar>
<mat-card>
  <div class="bg-default-1 pt-5">
    <br/><br/>
    <div class="container" style="margin-left:auto;">
      <mat-card>
        <p class="font-weight-semibold text-uppercase">Customer Information</p>
        <hr>
        <div class="row mt-2">
          <div class="col-md-2 font-weight-semibold">First Name</div>
          <div class="col-md-4 text-primary">{{persfirstname}}</div>
          <div class="col-md-3 font-weight-bold">Last Name</div>
          <div class="col-md-3 text-primary"><b>{{perslastname}}</b></div>
        </div>
        <div class="row mt-2">
          <div class="col-md-2 font-weight-bold">Mobile Number</div>
          <div class="col-md-4 text-primary">{{persmobile}}</div>
          <div class="col-md-3 font-weight-bold">Email</div>
          <div class="col-md-3 text-primary"><b>{{persemail}}</b></div>
        </div>
        <div class="row mt-2">
          <div class="col-md-2 font-weight-bold">DOB</div>
          <div class="col-md-4 text-primary">{{persdob}}</div>
          <div class="col-md-3"></div>
          <div class="col-md-3 text-primary"></div>
        </div>

        <br />
        <p class="font-weight-semibold text-uppercase">Current Address</p>
        <hr>
        <div class="row mt-2">
          <div class="col-md-2 font-weight-bold">Street1</div>
          <div class="col-md-4 text-primary">{{curstreet1}}</div>
          <div class="col-md-3 font-weight-bold">Street2</div>
          <div class="col-md-3 text-primary">{{curstreet2}}</div>
        </div>
        <div class="row mt-2">
          <div class="col-md-2 font-weight-bold">City</div>
          <div class="col-md-4 text-primary">{{curcity}}</div>
          <div class="col-md-3 font-weight-bold">State</div>
          <div class="col-md-3 text-primary">{{curstate}}</div>
        </div>
        <div class="row mt-2">
          <div class="col-md-2 font-weight-bold">PO Box</div>
          <div class="col-md-4 text-primary">{{curpobox}}</div>
          <div class="col-md-3 font-weight-bold">Country</div>
          <div class="col-md-3 text-primary">{{curcountry}}</div>
        </div>


        <br />
        <p class="font-weight-semibold text-uppercase">Permanent Address</p>
        <hr>
        <div class="row mt-2">
          <div class="col-md-2 font-weight-bold">Street1</div>
          <div class="col-md-4 text-primary">{{perstreet1}}</div>
          <div class="col-md-3 font-weight-bold">Street2</div>
          <div class="col-md-3 text-primary">{{perstreet2}}</div>
        </div>
        <div class="row mt-2">
          <div class="col-md-2 font-weight-bold">City</div>
          <div class="col-md-4 text-primary">{{percity}}</div>
          <div class="col-md-3 font-weight-bold">PO Box</div>
          <div class="col-md-3 text-primary">{{perpobox}}</div>
        </div>
        <div class="row mt-2">
          <div class="col-md-2 font-weight-bold">State</div>
          <div class="col-md-4 text-primary">{{perstate}}</div>
          <div class="col-md-3 font-weight-bold">Country</div>
          <div class="col-md-3 text-primary">{{percountry}}</div>
        </div>

        <br />
        <button class="btn btn-sm btn-danger" (click)="rglink()"><i class="fa fa-backward mr-2"></i> Back</button>
        <br />
        <br />
        <br />
        
        <form #formC="ngForm" (ngSubmit)="confirmPasswordSubmit(formC.value)" class="modal modal-lg fade" role="dialog"
          [ngClass]="{
                        'show': ConfirmPasswordClass == true,
                        '': ConfirmPasswordClass == false
                        }" [ngStyle]='confirmPasswordStyle' id="forgot-password-popup" data-backdrop="static">
          <div class="modal-body text-center">

            <button (click)="onNoconfirmpasswordclick()" [ngStyle]='LoginXotpStyle' class="btn btn-danger"
              id="forgot-password-close"><i class="fa fa-close"></i></button>
            <img src="/assets/images/logo.png">

            <br />
            <div style="color:red;font-size:18px;" class="warningconfirmpassword text-center"></div>
            <!-- style="margin-top: 150px" -->
            <p class="text-center mt-5">Change Password</p>
            <div class="modal-dialog" role="document">
              <div class="form-group m-t-20">
                <div class="form-group m-t-20">
                  <div class="col-xs-12">
                    <div style="font-size:10px;display:none;color:red;" id="id_warn_message">Password should be Minimum
                      8 character</div>
                    <input id="id_password" class="form-control val" type="password" name="password"
                      [(ngModel)]="confirmpasswordForm.password" placeholder="Password"
                      (keyup)="passwordValidation($event)" ng-pattern-restrict="^[a-zA-Z0-9]+">
                  </div>
                </div>
                <div class="form-group m-t-30">
                  <div class="col-xs-12">
                    <div style="font-size:10px;display:none;color:red;" id="id_confirm_warn_message">Password should be
                      Minimum 8 character</div>
                    <input id="id_confirm_password" class="form-control val" type="password" name="confirm_password"
                      [(ngModel)]="confirmpasswordForm.confirmPassword" placeholder="Confirm Password"
                      (keyup)="passwordValidation($event)">
                  </div>
                </div>

                <div class="form-group text-center m-t-20" style="margin-top: 20px;">
                  <div class="col-xs-12">
                    <button class="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light"
                      style="background-color:#1E90FF;" type="submit">Submit</button>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </form>
        <div class="modal modal-dialog-centered fade" tabindex="-1" role="dialog" aria-labelledby="modelTitle"
          aria-hidden="true" [ngClass]="{
                'show': registerWarningClass == true,
                '': registerWarningClass == false
                }" [ngStyle]='registerWarningStyle' style="color:gray;opacity:0.98;display:none;margin:auto;"
          id="modal-register-warning">
          <!-- style="margin-top: 150px" -->
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title" id="modelTitle">Message</h4>
                <button type="button" (click)="hideWarning()" class="close" data-dismiss="modal"
                  aria-hidden="true">×</button>
              </div>
              <div class="modal-body">
                <div *ngFor="let wc of warning_content"><span>{{wc}}</span><br /></div>
              </div>
              <div class="modal-footer">
                <button type="button" (click)="hideWarning()" class="btn btn-danger">Close</button>
              </div>
            </div>
          </div>
        </div>

      </mat-card>




    </div>
  </div>
</mat-card>