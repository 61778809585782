import { Component, OnInit } from '@angular/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { AppService } from '../app.service';
import * as $ from 'jquery';
import { SchemeList } from '../data-model';


export interface PeriodicElement {
  giftcardid: string;
  registereddate: string;
  totalamount: number;
  denomamount:number;
  nopaiddenom:number;
  paidamount:number;
  balanceamount:number;
  giftcardtopup:number;
  paymenthistory:number;
  

}










@Component({
  selector: 'app-registredgiftcard',
  templateUrl: './registredgiftcard.component.html',
  styleUrls: ['./registredgiftcard.component.css']
})
export class RegistredgiftcardComponent implements OnInit{
  opened = true;
  over = 'side';
  expandHeight = '42px';
  collapseHeight = '42px';
  displayMode = 'flat';
  schemeList:any;
  schemes:any;
  currency:string;
  ComNameStyle:object;
  buttoncomstyle:object;
  buttonpayonlinestyle:object;
  c_email = localStorage.getItem('odooemailid');
  c_mobile = localStorage.getItem('mobile');
  c_name=localStorage.getItem('country_name');
  mnumber=localStorage.getItem('mobile_Number');
  session_key = localStorage.getItem('session_valid_key');
  dtOptions: any = {};

  watcher: Subscription;
  constructor(mediaObserver: MediaObserver,private router: Router,public spinner: NgxSpinnerService,public appService: AppService) {
    this.watcher = mediaObserver.media$.subscribe((change: MediaChange) => {
      if (change.mqAlias === 'sm' || change.mqAlias === 'xs') {
        this.opened = false;
        this.over = 'over';
        this.ComNameStyle ={'display':'block'};
      } else {
        this.opened = true;
        this.over = 'side';
        this.ComNameStyle ={'display':'block'};
      }
    });
  }

  getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = $.trim(cookies[i]);
            // console.log((name + '='))
            if (cookie.substring(0, name.length + 1) ==(name + '=')) {
                // console.log(decodeURIComponent(cookie.substring(name.length + 1)))
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    // console.log(cookieValue)
    return cookieValue;
  }

  ngOnInit() {
    setTimeout(()=>{  
      this.dtOptions = {
          pagingType: 'full_numbers',
          pageLength: 5,
        lengthMenu : [5, 10, 25],
          processing: true
        };
    }, 100);
    let sessionkey=localStorage.getItem('session_valid_key');
    this.currency=localStorage.getItem('currency');
    // console.log(sessionkey,"kdkdhdhdbccggd")
    if(sessionkey=="logout"){
    this.router.navigate(['/home'])
    }
  // console.log("iam calling")
   let mobile_number= this.appService.getMobileNumber();
  //  console.log(localStorage.getItem('mobile'))
   if (mobile_number != undefined){
   localStorage.setItem('mobile_Number', mobile_number);
   }
   this.renderregisteredcards(localStorage.getItem('mobile'))
   

  }

  
logout = function(){
   
  var self = this;
  self.spinner.show(); 
  self.router.navigate(['/home'])
  self.renderlogout(localStorage.getItem('odoomobilenum'))
  localStorage.setItem('session_valid_key', "logout");
  
  localStorage.clear();
  self.spinner.hide(); 
}
gclink = function(){
   
  var self = this;
  self.spinner.show(); 
  self.router.navigate(['/giftcard'])
  self.spinner.hide(); 
}
glistlink = function(){
   
  var self = this;
  self.spinner.show(); 
  self.router.navigate(['/giftcardlist'])
  self.spinner.hide(); 
}
gifttopup = function(item){
   
  var self = this;
  localStorage.setItem('giftcardtopupcardid',item );
  self.spinner.show(); 
  self.router.navigate(['/giftcardtopup'])
  self.spinner.hide(); 
}
paymenthistory = function(item){
   
  var self = this;
  localStorage.setItem('paymenthistoryid',item );
  self.spinner.show(); 
  self.router.navigate(['/paymenthistory'])
  self.spinner.hide(); 
}

renderregisteredcards(mobile_number){
var self= this;
self.spinner.show();
// console.log(mobile_number)
   var session_id='';
    if(this.getCookie('kalyan_id')!=null){
      session_id=this.getCookie('kalyan_id');Date
    }  
   let args = {'session': self.session_key,'mobile_number':mobile_number };
   var csrftoken = this.getCookie('csrf_token');
   $.ajax({
            beforeSend: function(xhr, settings) {
              xhr.setRequestHeader("X-CSRFToken", csrftoken);
            },
            method: "POST",
            url: "/web/payment_portal/registeredgiftcard/",
            dataType: "json",
            data : {data:JSON.stringify(args),csrf_token: csrftoken},
            success: function(response) {
            // console.log(response)
            if (response.error.status=="4002")
            {   
                if(response.error.description == "Session not valid"){
                    
                  self.logout()
                }
                else{
                
                localStorage.setItem('cusname',"");
                
                  self.buttoncomstyle={'display':'none'}
                  self.buttonpayonlinestyle=={'display':'none'}
                 self.spinner.hide();
                 alert('There is no registered cards. Please make new registration card in the showrooms')
                }
                //  self.router.navigate(['/giftcard']);
            }
            else
            {
                // console.log(response.data[0].RemainingAmount,"llll")
                localStorage.setItem('cusname', response.data[0].FirstName);
                
                self.schemeList=response.data;
                self.spinner.hide();
            }
            // console.log(self.schemeList)
            },
            error: function(err) {
              console.log('err')
             
            }
            });
}

renderlogout(mobile_number){
var self= this;
self.spinner.show();
console.log(mobile_number,"registered giftcard")
   var session_id='';
    if(this.getCookie('kalyan_id')!=null){
      session_id=this.getCookie('kalyan_id');Date
    }  
    let args = {'session': session_id,'mobile_number':mobile_number };
   var csrftoken = this.getCookie('csrf_token');
  
   $.ajax({
            beforeSend: function(xhr, settings) {
              xhr.setRequestHeader("X-CSRFToken", csrftoken);
            },
            method: "POST",
            url: "/web/payment_portal/logout/",
            dataType: "json",
            data : {data:JSON.stringify(args),csrf_token: csrftoken},
            success: function(response) {
            self.spinner.hide();
            },
            error: function(err) {
              console.log(err)
             
            }
            });

   }
  

}
