import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Router } from '@angular/router';
@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.css']
})
export class TermsAndConditionsComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  country_change = function(){
  	// console.log("shdfhjsdf")
  	$('.terms_and_conditions').hide()
  	var val = $('#country_code').val()
  	$('#c_'+val).show()
  	// console.log(val)
  }

  home = function(){
  	this.router.navigate(['/'])
  }
}
