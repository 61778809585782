import { Component, OnInit } from '@angular/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { AppService } from '../app.service'
import * as $ from 'jquery';

@Component({
  selector: 'app-payment-cyber-source-component',
  templateUrl: './payment-cyber-source-component.component.html',
  styleUrls: ['./payment-cyber-source-component.component.css']
})
export class PaymentCyberSourceComponentComponent implements OnInit {

  c_email = localStorage.getItem('odooemailid');
  c_mobile = localStorage.getItem('mobile');
  c_name = localStorage.getItem('country_name');
  c_payurl = localStorage.getItem('payurl');


  constructor(private router: Router) {

   }

  ngOnInit(): void {
    console.log("cybersource ts file calling")
    let sessionkey=localStorage.getItem('session_valid_key')
    console.log(sessionkey,"kdkdhdhdbccggd")
    if(sessionkey=="logout"){
    this.router.navigate(['/home'])

    }
    console.log(localStorage.getItem('payurl'), "c_payurl")
    console.log(localStorage.getItem('amount'), "amounttttttttt")
    $('#nonseamless').attr('action',localStorage.getItem('payurl'))
    $('#amount').val(localStorage.getItem('amount'))
    $('#access_key').val(localStorage.getItem('access_key'))
    $('#profile_id').val(localStorage.getItem('profile_id'))
    $('#transaction_uuid').val(localStorage.getItem('transaction_uuid'))
    $('#signed_field_names').val(localStorage.getItem('signed_field_names'))
    $('#unsigned_field_names').val(localStorage.getItem('unsigned_field_names'))
    $('#signed_date_time').val(localStorage.getItem('signed_date_time'))
    $('#locale').val(localStorage.getItem('locale'))
    $('#currency').val(localStorage.getItem('currency'))
    $('#reference_number').val(localStorage.getItem('reference_number'))
    $('#transaction_type').val(localStorage.getItem('transaction_type'))

    $('#bill_to_forename').val(localStorage.getItem('bill_to_forename'))
    $('#bill_to_surname').val(localStorage.getItem('bill_to_surname'))
    $('#bill_to_email').val(localStorage.getItem('bill_to_email'))
    $('#bill_to_address_line1').val(localStorage.getItem('bill_to_address_line1'))
    $('#bill_to_phone').val(localStorage.getItem('bill_to_phone'))
    $('#bill_to_address_city').val(localStorage.getItem('bill_to_address_city'))
    $('#bill_to_address_postal_code').val(localStorage.getItem('bill_to_address_postal_code'))
    $('#customer_ip_address').val(localStorage.getItem('customer_ip_address'))
    $('#bill_to_address_state').val(localStorage.getItem('bill_to_address_state'))
    $('#bill_to_address_country').val(localStorage.getItem('bill_to_address_country'))

    $('#merchant_defined_data1').val(localStorage.getItem('merchant_defined_data1'))
    $('#merchant_defined_data2').val(localStorage.getItem('merchant_defined_data2'))
    $('#merchant_defined_data3').val(localStorage.getItem('merchant_defined_data3'))
    $('#merchant_defined_data4').val(localStorage.getItem('merchant_defined_data4'))
    $('#merchant_defined_data5').val(localStorage.getItem('merchant_defined_data5'))
    $('#merchant_defined_data6').val(localStorage.getItem('merchant_defined_data6'))
    $('#merchant_defined_data7').val(localStorage.getItem('merchant_defined_data7'))
    $('#merchant_defined_data8').val(localStorage.getItem('merchant_defined_data8'))
    $('#merchant_defined_data20').val(localStorage.getItem('merchant_defined_data20'))

    $('#signature').val(localStorage.getItem('signature'))
    $('#nonseamless').submit()
  }

  logout = function(){

    var self = this;
    self.spinner.show();
    self.router.navigate(['/home'])
    self.renderlogout(localStorage.getItem('odoomobilenum'))
    localStorage.setItem('session_valid_key', "logout");
    self.spinner.hide();
  }
  rglink = function(){

    var self = this;
    self.spinner.show();
    self.router.navigate(['/giftcardregistered'])
    self.spinner.hide();
  }
  termandconactive = function(){
    this.tandcfoot = true;
    this.termandconClass = true;
    this.termandconStyle = {'display': 'block'};
  }
  glistlink = function(){

    var self = this;
    self.spinner.show();
    self.router.navigate(['/giftcardlist'])
    self.spinner.hide();
  }
}

