<app-kalyan-navbar></app-kalyan-navbar>
<br/>
<br/>
<br/>
<br/>
<br/>
<!-- <mat-card>
  <div class="col-md-6">
    <label class="control-label">Enter Mobile Number Registered with PayIT</label><span class="text-danger ml-2"> *</span>
    <input name="mobile" id="mobile" type="number" (blur)="mobcheck($event)" required="true" class="form-control fields-required" data-attr="mobile" >
    <br>
  </div>
  <div class="col-md-6" id="emi">
    <label class="control-label">EMI Amount</label><span class="text-danger ml-2"> *</span>
    <input name="emi_amt" id="emi_amt" value="{{emi_amount}}" type="number" required="true" readonly class="form-control fields-required" data-attr="mobile" >
    <br>
  </div>
  <div class="col-md-6" id="otp1">
    <label class="control-label">Enter the OTP Sent To Your Mobile No</label>
    <input name="otp" id="otp"  type="number" required="true" class="form-control fields-required" data-attr="otp">
    <button class="btn btn-sm btn-danger float-left" type="button" (click)="payitproceed()">Pay</button>
  </div>

</mat-card> -->
<div class="card">
  <div class="card-body">

    <div class="row">
      <div class="col">
        <input type="text" class="form-control" placeholder="Enter Mobile Number Registered with PayIT" id="mobile"  name="mobile" type="number" (blur)="mobcheck()" aria-label="mobileno">
      </div>
      <div class="col">
        <input type="text" class="form-control" placeholder="EMI Amount" aria-label="EMI Amount" name="emi_amt" id="emi_amt" value="{{emi_amount}}" type="number" required="true" readonly>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button class="btn btn-sm btn-danger float-left" type="button" (click)="mobcheck()">Continue</button>
      </div>
    </div>    
  </div>    
</div>


