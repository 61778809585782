<app-kalyan-navbar></app-kalyan-navbar>
  <mat-card>
  <div class="bg-default-1 pt-5">
    	
      <div class="container" style="margin-top: auto;margin-left:auto;background: white">
        
          
           <mat-card>
            <form ngNoForm #nonseamless id="nonseamless" name="nonseamless" method="post" name="redirect" action="https://secure.ccavenue.ae/transaction/transaction.do?command=initiateTransaction"> 
                <div id="proceed_payment_div">
                    <input type="hidden" id="encRequest" name="encRequest" value="{{encryptedString}}"/>
                    <input type="hidden" name="access_code" id="access_code" value="{{accessCode}}"/>
                    
                    <button style="visibility:hidden">Submit</button>
                    <p>Please wait while your transaction is being processed. You will be automatically redirected to the confirmation page.Please do not hit "back", "forward" or "refresh" button.</p>
                    <div class="form-group text-center m-t-15">
                    <div class="col-xs-12">
                      <button type="submit"  class="btn btn-sm btn-primary" style="display: none" style="color:white;background-color: red;align:center;visibility:hidden" id="confirm_payment" >Confirm</button>
                    </div>
                    </div>
                </div>
                <br/>
            </form>
           <script type="text/javascript">
             $('#nonseamless').submit()
           </script>
             <!-- <p class="font-weight-semibold text-center text-uppercase float-left">Payment information</p>
              <br/>
              <hr>
            	<br/>
              
              <table class="table table-sm table-bordered  w-50 text-center">
                <tr >
                  <td style="width: 40%" class="bg-gray text-white">Card Number</td>
                  <td>{{cardnumber}}</td>
                </tr>
                <tr>
                  <td style="width: 40%" class="bg-gray text-white pt-3">Payment Status</td>
                  <td><p *ngIf="paymentstatus=='success'">{{paymentstatus}}<i class="fa fa-check-circle ml-2 text-primary"></i></p>
                <p *ngIf="paymentstatus!='success'">{{paymentstatus}}<i class="fa fa-times-circle ml-2 text-danger"></i></p></td>
                </tr>
                <tr>
                  <td style="width: 40%" class="bg-gray text-white">Reference Number</td>
                  <td>{{referencenumber}}</td>
                </tr>
              </table>-->
<!--<hr>-->
              
            	
           
</mat-card>
          
       
        
      </div>
    </div>
    </mat-card>
   


