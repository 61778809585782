
import { MatDialog, MatDialogRef } from  '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export  class  LoginComponent{

    public  email:  string  =  "";
    public  password:  string  =  "";
    	constructor(private  dialog:  MatDialog,public dialogRef: MatDialogRef<LoginComponent>){}

        

    onNoClick(): void {
    this.dialogRef.close();
  }

}

