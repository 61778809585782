<app-kalyan-navbar></app-kalyan-navbar>
<mat-toolbar style="background-color: #e72126 !important;margin-top: 12px;" class="header-box-shadow">
     <!-- <button mat-icon-button (click)="sidenav.toggle()" style="outline: none;" fxShow.sm="true" fxShow.gt-sm="false">
        <mat-icon>menu</mat-icon>
      </button>-->
      <img style="float:left" src="/assets/image/favicon.ico"/>
      <span id="id_menu_heading_web" style="color: white"  fxFlex><a style="color:white;" routerLink='/home'>Kalyan Jewellers</a></span>
      <span style="color: white" [ngStyle]='ComNameStyle' fxFlex><a style="color:white;" routerLink='/home'>Kalyan Jewellers</a></span>
      <span class="example-spacer"></span>
      <select style="border: none;outline:none;">
          <!-- <mat-label>Select Country</mat-label> -->
          <option></option>
          <option selected>United Arab Emirates  | AED</option>
          <!-- <option>Chocklate</option> -->
          <!-- <option>Pancakes</option> -->
      </select>
      <button mat-button style="background-color: inherit;color: white;font-size: 14px;font-weight: 500;border: none;outline:none;" (click)="gclink()"><mat-label>New Giftcard</mat-label>
    </button>
      <button mat-button style="background-color: inherit;color: white;font-size: 14px;font-weight: 500;border: none;outline:none;" (click)="rglink()">
          Registered Giftcards 
        </button>
<!--        <button mat-button style="background-color: inherit;color: white;font-size: 14px;font-weight: 500;">Giftcard List </button> -->
<!-- <button mat-button style="background-color: inherit;color: white;font-size: 14px;font-weight: 500; text-align:left"><a style="color:white;font-size: 14px;font-weight: 500;" target="new" href="https://www.candere.com" 
        aria-expanded="false"><span>Shop Online at Candere</span></a>
    </button> -->
 <button mat-button style="background-color: inherit;color: white;font-size: 14px;font-weight: 500;"><a style="color:white;font-size: 14px;font-weight: 500;" target="new" href="https://kalyanjewellers.net/contact-us.php" 
        aria-expanded="false"><span>Support</span></a></button>
   
       
        <button mat-button [matMenuTriggerFor]="customMenu" style="border: none;outline:none;"> <a>
          <mat-icon style="color:white;"class="v-align-middle">account_circle</mat-icon>
          
        </a></button>
    <mat-menu #customMenu="matMenu">
    <div><span></span></div>
    <mat-divider></mat-divider>
      <button mat-menu-item><a class="nav-link" style="color:black;font-size: 14px;font-weight: 500;" target="new" href="https://www.candere.com" 
            aria-expanded="false"><span>Shop Online at Candere</span></a></button>
            <mat-divider></mat-divider>
      <button mat-menu-item><a class="nav-link" style="color:black;font-size: 14px;font-weight: 500;" target="new"  href="https://kalyanjewellers.net/contact-us.php" 
            aria-expanded="false"><span>Support</span></a></button>
            <mat-divider></mat-divider>
      <button mat-menu-item  fxHide.xs routerLink="/profile" style="background-color: white;border: none;outline:none;" >
          <mat-label>Profile</mat-label>
        </button>     
      <button mat-menu-item  fxHide.xs (click)="logout()" style="background-color: white;border: none;outline:none;" >
          <mat-label>Logout</mat-label>
            
            
        </button>
        <mat-divider></mat-divider>
    </mat-menu>
        <button mat-icon-button [matMenuTriggerFor]="dropMenu" style="outline: none;" fxHide fxShow.xs>
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #dropMenu="matMenu">
            <ng-container >
                
                <button mat-menu-item (click)="logout()" style="background-color: white" >
                  <mat-label>Logout</mat-label>
                    <mat-icon class="mr">logout</mat-icon>
                    
                </button>
                <mat-divider></mat-divider>
            </ng-container>
        </mat-menu>




      <!-- 
      <button mat-button>
        <mat-icon class="mr-1">home</mat-icon>
        Upcoming
      </button>
      <span fxShow.lt-md="false" fxShow.gt-sm="true">
        <button mat-button>
          <mat-icon class="mr-1">link</mat-icon>
          Link</button>
        <button mat-button>
          <mat-icon class="mr-1">link</mat-icon>
          Link</button>
        <button mat-button [matMenuTriggerFor]="dropDown">
          DropDown
          <mat-icon class="ml-1">arrow_drop_down</mat-icon>
        </button>
        <mat-menu #dropDown="matMenu" overlapTrigger="false">
          <button mat-menu-item>
            <mat-icon>dialpad</mat-icon>
            <span>Dropdown Link 1</span>
          </button>
          <button mat-menu-item disabled>
            <mat-icon>voicemail</mat-icon>
            <span>Dropdown Link 2</span>
          </button>
        </mat-menu>
      </span>
      <span class="spacer"></span>
      <button mat-button [matMenuTriggerFor]="menu" fxShow.lt-md="false" fxShow.gt-sm="true">
        <mat-icon>account_circle</mat-icon>
        <span class="ml-2">Account</span>
      </button>
      <button mat-icon-button [matMenuTriggerFor]="menu" fxShow.lt-md="true" fxShow.gt-sm="false">
        <mat-icon>account_circle</mat-icon>
      </button>
      <mat-menu #menu="matMenu" overlapTrigger="false">
        <button mat-menu-item>
          <mat-icon>account_circle</mat-icon>
          <span>My Account</span>
        </button>
        <button mat-menu-item>
          <mat-icon>settings</mat-icon>
          <span>Settings</span>
        </button>
        <button mat-menu-item>
          <mat-icon>money</mat-icon>
          <span>Billing</span>
        </button>
      </mat-menu> -->
      <!-- <button mat-button   (click)="logout()" style="background-color: white;float:right;" >
        <mat-label>Logout</mat-label>
      <mat-icon class="ml-3" title="Logout">exit_to_app</mat-icon>
        
    </button> -->
      <!-- <mat-icon class="ml-3" title="Logout">exit_to_app</mat-icon> -->
    </mat-toolbar>
 


<!-- ================================ -->
    
    <mat-card>
    <div class="bg-default-1">
    	
      <div class="container">
        
          
          	<!-- <mat-button-toggle-group style="border-color:  coral ;" name="fontStyle" aria-label="Font Style">
				  <mat-button-toggle style="background-color: #C0C0C0;border-color:  coral ; " value="home"><a style="color:red;" routerLink="/home">Home</a></mat-button-toggle>
				  <mat-button-toggle style="background-color: #F0F0F0;border-color:  coral ;" value="new_giftcard"><a>New Giftcard</a></mat-button-toggle>
				  <mat-button-toggle style="background-color: #C0C0C0 ;border-color:  coral ;" value="registered_giftcard"><a style="color:red;" routerLink="/giftcardregistered">Registerd Giftcard</a></mat-button-toggle>
				  <mat-button-toggle style="background-color: #C0C0C0 ;border-color:  coral ;" value="payment_history"><a style="color:red;" href="#">Payment History</a></mat-button-toggle>
				  <mat-button-toggle style="background-color: #C0C0C0 ;border-color:  coral ;" value="profile"><a style="color:red;" href="#">Profile</a></mat-button-toggle>
				  <mat-button-toggle style="background-color: #C0C0C0 ;border-color:  coral ;" value="documents"><a style="color:red;" href="#">Documents</a></mat-button-toggle>
            </mat-button-toggle-group> -->
            <mat-card>
            	
                    <p class="float-left font-weight-semibold mt-2 text-uppercase">GiftCard List</p>
                        <!-- <mat-form-field>
                            <mat-label>Search</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. abc" #input>
                        </mat-form-field> -->
				  		<table mat-table [dataSource]="dataSource" class="table table-striped table-responsive-md btn-table">
				  			<!-- S.no -->
				  			<ng-container matColumnDef="position">
							    <th style="font-size: 15px;" mat-header-cell *matHeaderCellDef><b>S.No</b> </th>
							    <td mat-cell *matCellDef="let element"> {{element.position}} </td>
							</ng-container>
							<!-- Giftcard -->
							<ng-container matColumnDef="giftcard">
							    <th  style="font-size: 15px;" mat-header-cell *matHeaderCellDef><b>Giftcard</b></th>
							    <td style="margin-left: 100px;" mat-cell *matCellDef="let element"> {{element.giftcard}} </td>
							</ng-container>
							<!-- action -->
							<ng-container matColumnDef="action">
							    <th style="font-size: 15px;" mat-header-cell *matHeaderCellDef><b>Action</b></th>
							    <td style="margin-left: 100px;" mat-cell *matCellDef="let element"> <button mat-raised-button color="warn" (click)="gclink()">Purchase Card</button></td>
							</ng-container>
							<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  							<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

				  		</table>
                       <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>-->

				 
            </mat-card>

          
       
        
      </div>
    </div>
    </mat-card>



