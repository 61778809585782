<app-kalyan-navbar></app-kalyan-navbar>    
   <mat-card>
    <div class="bg-default-1 pt-5">
    	
      <div class="container" style="margin-left:auto;">
        
          

            <mat-card>
              <p class="font-weight-semibold text-uppercase text-center">Payment Information</p>
              <p class="font-weight-semibold text-uppercase">Gift card information</p>
              <hr>
              <div  class="row mt-2">
                <div class="col-md-3">Card Number</div>
                <div class="col-md-3 text-primary">{{infoList.EnrollmentNo}}</div>
                <div [ngStyle]="MrStyle" class="col-md-2">Customer Name</div>
                <div class="col-md-4 text-primary">{{infoList.FirstName}}</div>
              </div>
              <br [ngStyle]="BrStyle"/>
              <div  class="row mt-2">
                <div class="col-md-3">Tenure</div>
                <div class="col-md-3 text-primary">{{infoList.NoOfInstallments}}</div>
                <div [ngStyle]="MrStyle" class="col-md-2">Gift Card Type</div>
                <div class="col-md-4 text-primary">{{infoList.SchemeType}}</div>
              </div>
              <br [ngStyle]="BrStyle"/>
              <div  class="row mt-2">
                <div class="col-md-3">Join Date</div>
                <div class="col-md-3 text-primary">{{infoList.JoinDate | date: 'dd-MM-yyyy'}}</div>
                <div [ngStyle]="MrStyle" class="col-md-2">Maturity Date</div>
                <div class="col-md-4 text-primary">{{infoList.MaturityDate | date: 'dd-MM-yyy'}}</div>
              </div>
              <br [ngStyle]="BrStyle"/>
              <div  class="row mt-2">
                <div class="col-md-3">Monthly Reload Amount</div>
                <div class="col-md-3 text-primary">{{currency}} {{infoList.EMI}}</div>
                <div [ngStyle]="MrStyle" class="col-md-2">Total Amount</div>
                <div class="col-md-4 text-primary">{{currency}} {{infoList.TotalAmount}}</div>
              </div>
              <br [ngStyle]="BrStyle"/>
              <div  class="row mt-2">
                <div class="col-md-3">Status</div>
                <div class="col-md-3 text-primary">{{infoList.SchemeStatus}}</div>
                <div [ngStyle]="MrStyle" class="col-md-2">Fee Amount</div>
                <div class="col-md-4 text-primary">{{currency}} {{infoList.FeeAmount}}</div>
              </div>

              <br/>
              <p class="font-weight-semibold text-uppercase text-center">Topup Details</p>
              <hr>
              <div class="table-responsive">
               <table  class="table table-bordered text-center">
                <thead class="thead-light">
                    <tr>
  
                      <th>Payment Date </th>
                      <th>Reference Number</th>
                      <th>Payment Status </th>
                      <th>Receipt ({{currency}})</th>
                      
                    </tr>
                </thead>
                  

                <!-- Giftcard Id -->
                <tr *ngFor="let item of paymentList">
                  <td > {{item.Date | date: 'dd/MM/yyyy'}} </td>
                  <td > {{item.ReferenceNo}} </td>
                  <td> {{item.PaymentStatus | titlecase}} </td>
                  <td> {{item.Amount}} </td>
                </tr>
              
              

              </table>
 
              </div>
               


              <button  mat-raised-button (click)="backaction()"  style="background-color: red;color: white;float:left;border: none;outline:none;"><i class="fa fa-backward mr-2"></i>Back</button>
            	<br/>
              <br/>

              <br/><br/>
              <br/>
            
            </mat-card>

          
       
        
      </div>
    </div>
    </mat-card>


