import { Component, OnInit } from '@angular/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import * as $ from 'jquery';
import { AppService } from '../app.service';
// import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-pay-it',
  templateUrl: './pay-it.component.html',
  styleUrls: ['./pay-it.component.css']
})


export class PayItComponent{
  // private toastr: ToastrService
  opened = true;
  over = 'side';
  expandHeight = '42px';
  collapseHeight = '42px';
  displayMode = 'flat';
  otp = '';
  inputValue: string;
  mobileNo: string;
  ComNameStyle:object;
  SpanStyle:object;
  watcher: Subscription;
  giftcardTopupId = localStorage.getItem('giftcardTopupId');
  c_mobile = localStorage.getItem('mobile');
  emi_amount = localStorage.getItem('payableAmount')
  constructor(mediaObserver: MediaObserver,private router: Router,public spinner: NgxSpinnerService,public appService: AppService) {
    this.watcher = mediaObserver.media$.subscribe((change: MediaChange) => {
      if (change.mqAlias === 'sm' || change.mqAlias === 'xs') {
        this.opened = false;
        this.over = 'over';
        this.ComNameStyle ={'display':'block'};
        this.SpanStyle ={'margin-left':'15%'};
        
      } else {
        this.opened = true;
        this.over = 'side';
        this.ComNameStyle ={'display':'block'};
        this.SpanStyle ={'margin-left':'15%'};
        
      }
    });
  }  
  ngOnInit(): void {
    let sessionkey=localStorage.getItem('session_valid_key')
  }
  getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = $.trim(cookies[i]);
            // console.log((name + '='))
            if (cookie.substring(0, name.length + 1) ==(name + '=')) {
                // console.log(decodeURIComponent(cookie.substring(name.length + 1)))
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    // console.log(cookieValue)
    return cookieValue;
  }
  logout = function(){
   
    var self = this;
    self.spinner.show(); 
    self.router.navigate(['/home'])
    self.renderlogout(localStorage.getItem('odoomobilenum'))
    localStorage.setItem('session_valid_key', "logout");
    self.spinner.hide(); 
  }
  rglink = function(){
     
    var self = this;
    self.spinner.show(); 
    self.router.navigate(['/giftcardregistered'])
    self.spinner.hide(); 
  }
  gclink = function(){
     
    var self = this;
    self.spinner.show(); 
    self.router.navigate(['/giftcard'])
    self.spinner.hide(); 
  }
  glistlink = function(){
     
    var self = this;
    self.spinner.show(); 
    self.router.navigate(['/giftcardlist'])
    self.spinner.hide(); 
  }
  backaction = function(){
     
    var self = this;
    self.spinner.show(); 
    self.router.navigate(['/giftcardregistered'])
    self.spinner.hide(); 
  }
  payitproceed() {
    var self = this
    var payit_mobile = $('#mobile').val()
    var csrftoken = this.getCookie('csrf_token');
    var otp = $('#otp').val()
    var otp1 = otp.toString();
    // var otp2 = otp1.length;

    var session_id='';
    if(this.getCookie('kalyan_id')!=null){
      session_id=this.getCookie('kalyan_id');
    }
    // console.log(otp1.length,'otp1.length')
    if(otp1.length != 6){
      alert('Please enter valid 6 digit OTP number')
      return
    }
    // if(otp){
    let args = {'session': session_id,'payit_mobile_number':payit_mobile,'otp':otp};
    $.ajax({
      beforeSend: function(xhr, settings) {
      xhr.setRequestHeader("X-CSRFToken", csrftoken);
    },
    method: "POST",
    url: "/web/payit/proceedpay/",
    dataType: "json",
    data : {data:JSON.stringify(args),csrf_token: csrftoken},
    success: function(response) {
      self.router.navigate(['/paymentview'],{ queryParams: { id: response.id}, queryParamsHandling: 'merge' });
     
    },
    error: function(err) {console.log('err')}
    });     
    // }
    // else{
    //   alert('Please Enter OTP')  
    // }
  }  
  
  mobcheck() {
   var payit_mobile = $('#mobile').val()
   var mobile = localStorage.getItem('mobile') 
   var csrftoken = this.getCookie('csrf_token');
   var session_id='';
   if(this.getCookie('kalyan_id')!=null){
     session_id=this.getCookie('kalyan_id');
   }
   let args = {'session': session_id,'payit_mobile_number':payit_mobile,'mobile':mobile};
   $.ajax({
      beforeSend: function(xhr, settings) {
      xhr.setRequestHeader("X-CSRFToken", csrftoken);
    },
    method: "POST",
    url: "/web/payit/mobile_no/check/",
    dataType: "json",
    data : {data:JSON.stringify(args),csrf_token: csrftoken},
    success: function(response) {
      // console.log(response,'response') 
      // console.log(response.amt,'response')
      var amt = response.amt
      if(response.status == 'success'){
        $('#otp1').show()
        $('#emi').show()
        $('#emi_amt').val(amt)
      }
      
    },
    error: function(err) {console.log('err')}
   });     
  }
  
  renderlogout(mobile_number){
  var self= this;
  self.spinner.show();
  console.log(mobile_number,"registered giftcard")
     var session_id='';
      if(this.getCookie('kalyan_id')!=null){
        session_id=this.getCookie('kalyan_id');Date
      }  
      let args = {'session': session_id,'mobile_number':mobile_number };
     var csrftoken = this.getCookie('csrf_token');
    
     $.ajax({
              beforeSend: function(xhr, settings) {
                xhr.setRequestHeader("X-CSRFToken", csrftoken);
              },
              method: "POST",
              url: "/web/payment_portal/logout/",
              dataType: "json",
              data : {data:JSON.stringify(args),csrf_token: csrftoken},
              success: function(response) {
              self.spinner.hide();
              },
              error: function(err) {
                console.log('err')
               
              }
              });
  
     }
}
