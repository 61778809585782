<header class="site-header site-header--menu-right bg-default py-7 py-lg-0 site-header--absolute site-header--sticky">
      <div class="container">
        <nav class="navbar site-navbar offcanvas-active navbar-expand-lg  px-0 py-0">
          <!-- Brand Logo-->
          <div class="brand-logo">
            <a href="./index.html">
              <!-- light version logo (logo must be black)-->
              <!-- <img src="assets/images/kalyanlogo.jpg" alt="" class="light-version-logo default-logo"> -->
              <!-- Dark version logo (logo must be White)-->
              <img src="assets/images/favicon.ico" title="Kalyan Jewellers" alt="Kalyan" class="do-with-text"> <span class="company-name">Kalyan Jewellers</span>
            </a>
          </div>
          <div class="collapse navbar-collapse" id="mobile-menu">
            <div class="navbar-nav-wrapper">
              <ul class="navbar-nav main-menu">
                <!-- <li class="nav-item dropdown active">
                  <a class="nav-link dropdown-toggle gr-toggle-arrow" id="navbarDropdown" href="#features" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Home <i class="icon icon-small-down"></i></a>
                  <ul class="gr-menu-dropdown dropdown-menu" aria-labelledby="navbarDropdown">
                    <li class="drop-menu-item">
                      <a href="home-1.html">
                        Homepage 01
                      </a>
                    </li>
                    <li class="drop-menu-item">
                      <a href="home-2.html">
                        Homepage 02
                      </a>
                    </li>
                    <li class="drop-menu-item">
                      <a href="home-3.html">
                        Homepage 03
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle gr-toggle-arrow" id="navbarDropdown2" href="#features" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Pages <i class="icon icon-small-down"></i></a>
                  <ul class="gr-menu-dropdown dropdown-menu" aria-labelledby="navbarDropdown2">
                    <li class="drop-menu-item dropdown">
                      <a class="dropdown-toggle gr-toggle-arrow" id="navbarDropdown242" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Job Pages
                        <i class="icon icon-small-down"></i>
                      </a>
                      <ul class="gr-menu-dropdown dropdown-menu dropdown-left" aria-labelledby="navbarDropdown242">
                        <li class="drop-menu-item">
                          <a href="./search-grid.html">
                            Job Grid
                          </a>
                        </li>
                        <li class="drop-menu-item">
                          <a href="./search-list-1.html">
                            Job List
                          </a>
                        </li>
                        <li class="drop-menu-item">
                          <a href="./jobdetails.html">
                            Job Details
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li class="drop-menu-item dropdown">
                      <a class="dropdown-toggle gr-toggle-arrow" id="navbarDropdown21" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Dashboard
                        <i class="icon icon-small-down"></i>
                      </a>
                      <ul class="gr-menu-dropdown dropdown-menu dropdown-left" aria-labelledby="navbarDropdown21">
                        <li class="drop-menu-item">
                          <a href="./dashboard-main.html">
                            Dashboard Main
                          </a>
                        </li>
                        <li class="drop-menu-item">
                          <a href="./dashboard-settings.html">
                            Settings
                          </a>
                        </li>
                        <li class="drop-menu-item">
                          <a href="./dashboard-posted-applicants.html">
                            Applicants
                          </a>
                        </li>
                        <li class="drop-menu-item">
                          <a href="./dashboard-posted-applicants.html">
                            Posted Jobs
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li class="drop-menu-item dropdown">
                      <a class="dropdown-toggle gr-toggle-arrow" id="navbarDropdown25g" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Candidate Pages
                        <i class="icon icon-small-down"></i>
                      </a>
                      <ul class="gr-menu-dropdown dropdown-menu dropdown-left" aria-labelledby="navbarDropdown25g">
                        <li class="drop-menu-item">
                          <a href="./candidate-profile-main.html">
                            Candidate Profile
                          </a>
                        </li>
                        <li class="drop-menu-item">
                          <a href="./candidate-profile.html">
                            Candidate Profile 02
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li class="drop-menu-item dropdown">
                      <a class="dropdown-toggle gr-toggle-arrow" id="navbarDropdown242" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Search Pages
                        <i class="icon icon-small-down"></i>
                      </a>
                      <ul class="gr-menu-dropdown dropdown-menu dropdown-left" aria-labelledby="navbarDropdown242">
                        <li class="drop-menu-item">
                          <a href="./search-grid.html">
                            Search Grid
                          </a>
                        </li>
                        <li class="drop-menu-item">
                          <a href="./search-list-1.html">
                            Search List 01
                          </a>
                        </li>
                        <li class="drop-menu-item">
                          <a href="./search-list-2.html">
                            Search List 02
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li class="drop-menu-item">
                      <a href="./company-profile.html">
                        Company Profile
                      </a>
                    </li>
                    <li class="drop-menu-item dropdown">
                      <a class="dropdown-toggle gr-toggle-arrow" id="navbarDropdown242" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Account
                        <i class="icon icon-small-down"></i>
                      </a>
                      <ul class="gr-menu-dropdown dropdown-menu dropdown-left" aria-labelledby="navbarDropdown242">
                        <li class="drop-menu-item">
                          <a href="javacript:" data-toggle="modal" data-target="#login">
                            Sign In
                          </a>
                        </li>
                        <li class="drop-menu-item">
                          <a href="javacript:" data-toggle="modal" data-target="#signup">
                            Sign Up
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li class="drop-menu-item dropdown">
                      <a class="dropdown-toggle gr-toggle-arrow" id="navbarDropdown26" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Essential
                        <i class="icon icon-small-down"></i>
                      </a>
                      <ul class="gr-menu-dropdown dropdown-menu dropdown-left" aria-labelledby="navbarDropdown26">
                        <li class="drop-menu-item">
                          <a href="faq.html">
                            Faq
                          </a>
                        </li>
                        <li class="drop-menu-item">
                          <a href="./error-404.html">
                            404
                          </a>
                        </li>
                        <li class="drop-menu-item">
                          <a href="./pricing-page.html">
                            Pricing page
                          </a>
                        </li>
                        <li class="drop-menu-item">
                          <a href="./contact.html">Contact</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li> -->
                <li class="nav-item text-white mobile-logo text-center pl-20">
                      <img src="/assets/images/kalyanlogo.jpg" width="100" height="100">
                </li>
                <!-- <li class="nav-item text-white">
                  <a class="nav-link text-white" href="/giftcard/">Giftcard Registration</a>
                </li> -->
                <li class="nav-item text-white">
                  <a class="nav-link text-white" href="/giftcardregistered/">Registered Cards</a>
                </li>
                <li class="nav-item text-white">
                  <a class="nav-link text-white" href="https://kalyanjewellers.net/contact-us.php">Support</a>
                </li>
                <li class="nav-item text-white mobile-user">
                  <div class="dropdown show-gr-dropdown py-5">
                <a class="proile media ml-7 flex-y-center" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  
                  <i class="fas fa-user-circle heading-default-color ml-6 text-white"></i>
                </a>
                <div class="dropdown-menu gr-menu-dropdown dropdown-right border-0 border-width-2 py-2 w-auto bg-default" aria-labelledby="dropdownMenuLink">
                  <a class="dropdown-item py-2 font-size-3 font-weight-semibold line-height-1p2 text-uppercase" href="candidate-profile-main.html"> Profile</a>
                  <a class="dropdown-item py-2 text-red font-size-3 font-weight-semibold line-height-1p2 text-uppercase" href="#">Log Out</a>
                </div>
              </div>
                </li>
              </ul>
            </div>
            <button class="d-block d-lg-none offcanvas-btn-close focus-reset" type="button" data-toggle="collapse" data-target="#mobile-menu" aria-controls="mobile-menu" aria-expanded="true" aria-label="Toggle navigation">
              <i class="gr-cross-icon"></i>
            </button>
          </div>
          <div class="header-btns header-btn-devider ml-auto pr-5 ml-lg-6 d-none d-xs-flex signup-btn">
            <!-- <a class="btn btn-transparent text-white text-uppercase font-size-3 heading-default-color focus-reset" href="javacript:" data-target="#login-email">
              Log in
            </a> -->
            <div>
              <div class="dropdown show-gr-dropdown py-5">
                <a class="proile media ml-7 flex-y-center" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  
                  <i class="fas fa-user-circle heading-default-color ml-6 text-white"></i>
                </a>
                <div class="dropdown-menu gr-menu-dropdown dropdown-right border-0 border-width-2 py-2 w-auto bg-default" aria-labelledby="dropdownMenuLink">
                  <a class="dropdown-item py-2 font-size-3 font-weight-semibold line-height-1p2 text-uppercase" href="/profile">Edit Profile</a>
                  <a class="dropdown-item py-2 text-red font-size-3 font-weight-semibold line-height-1p2 text-uppercase" href="#" (click)='logout()'>Log Out</a>
                </div>
              </div>
            </div>
          </div>
          <!-- Mobile Menu Hamburger-->
          <button class="navbar-toggler btn-close-off-canvas  hamburger-icon border-0" type="button" data-toggle="collapse" data-target="#mobile-menu" aria-controls="mobile-menu" aria-expanded="false" aria-label="Toggle navigation">
            <!-- <i class="icon icon-simple-remove icon-close"></i> -->
            <span class="hamburger hamburger--squeeze js-hamburger">
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
            </span>
            </span>
          </button>
          <!--/.Mobile Menu Hamburger Ends-->
        </nav>
      </div>
    </header>