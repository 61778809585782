<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta http-equiv="X-UA-Compatible" content="ie=edge">
  <title>Landing Page Template</title>
  <link rel="shortcut icon" href="/assets/image/favicon.png" type="image/x-icon">
  <!-- Bootstrap , fonts & icons  -->
  <link rel="stylesheet" href="/assets/css/bootstrap.css">
  <link rel="stylesheet" href="/assets/fonts/icon-font/css/style.css">
  <link rel="stylesheet" href="/assets/fonts/typography-font/typo.css">
  <link rel="stylesheet" href="/assets/fonts/fontawesome-5/css/all.css">
  <!-- Plugin'stylesheets  -->
  <link rel="stylesheet" href="/assets/plugins/aos/aos.min.css">
  <link rel="stylesheet" href="/assets/plugins/fancybox/jquery.fancybox.min.css">
  <link rel="stylesheet" href="/assets/plugins/nice-select/nice-select.min.css">
  <link rel="stylesheet" href="/assets/plugins/slick/slick.min.css">
  <link rel="stylesheet" href="/assets/plugins/ui-range-slider/jquery-ui.css">
  <!-- Vendor stylesheets  -->
  <link rel="stylesheet" href="/assets/css/main.css">
  <!-- Custom stylesheet -->
  <style type="text/css">
    
#signup.form-control
{
    color: black!important;
}
  </style>
</head>

<body>
  <div class="site-wrapper overflow-hidden ">
    <!-- Header start  -->
    <!-- Header Area -->
    <app-kalyan-navbar></app-kalyan-navbar>
    <div class="container pt-12 pt-lg-24 pb-13 pb-lg-25">
          <div class="row justify-content-center">
            <div class="col-xxl-6 col-lg-7 col-md-9" >
              <!-- section-title start -->
              <div class="section-title text-center mb-12 mb-lg-18 mb-lg-15 pb-lg-15 pb-0">
                <h2 class="mb-9">Check our amazing gift cards, choose the best one for you.</h2>
                <p class="text-default-color font-size-4 px-5 px-md-10 px-lg-15 px-xl-24 px-xxl-22">Click here to register new gift card.</p>
              </div>
              <!-- section-title end -->
            </div>
          </div>
      </div>

</div></body></html>