import { Component, OnInit } from '@angular/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { AppService } from '../app.service'
import * as $ from 'jquery';

@Component({
  selector: 'app-smart-pay-gateway',
  templateUrl: './smart-pay-gateway.component.html',
  styleUrls: ['./smart-pay-gateway.component.css']
})
export class SmartPayGatewayComponent implements OnInit {
	opened = true;
  over = 'side';
  expandHeight = '42px';
  collapseHeight = '42px';
  displayMode = 'flat';
  ComNameStyle:object;
  SpanStyle:object;
  amount=localStorage.getItem('payableAmount');
  customerName=localStorage.getItem('customerName');
  
  referencenumber=localStorage.getItem('referencenumber');
  paymentURL = localStorage.getItem('payurl');
  accessCode = localStorage.getItem('accessCode');
  encryptedString = localStorage.getItem('encrpquery');
  giftcardTopupId = localStorage.getItem('giftcardTopupId');
  c_email = localStorage.getItem('odooemailid');
  c_mobile = localStorage.getItem('mobile');
  c_name=localStorage.getItem('country_name');
  constructor(private router: Router,public spinner: NgxSpinnerService) { }

  ngOnInit(): void {
  	let sessionkey=localStorage.getItem('session_valid_key')
    // console.log(sessionkey,"kdkdhdhdbccggd")
    if(sessionkey=="logout"){
    this.router.navigate(['/home'])
    
    }
    let ac='AVZR00JC11BC26RZCB'
    $('#encRequest').val(localStorage.getItem('encrpquery'));
    $('#access_code').val(ac);
    $('#nonseamless').submit();
  }

}
