<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta http-equiv="X-UA-Compatible" content="ie=edge">
  <title>Kalayan Jewellers - Buy / Top-up Gift Cards</title>
  <link rel="shortcut icon" href="image/favicon.png" type="image/x-icon">
  <!-- Bootstrap , fonts & icons  -->
  <link rel="stylesheet" href="/assets/css/bootstrap.css">
  <link rel="stylesheet" href="/assets/fonts/icon-font/css/style.css">
  <link rel="stylesheet" href="/assets/fonts/typography-font/typo.css">
  <link rel="stylesheet" href="/assets/fonts/fontawesome-5/css/all.css">
  <!-- Plugin'stylesheets  -->
  <link rel="stylesheet" href="/assets/plugins/aos/aos.min.css">
  <link rel="stylesheet" href="/assets/plugins/fancybox/jquery.fancybox.min.css">
  <link rel="stylesheet" href="/assets/plugins/nice-select/nice-select.min.css">
  <link rel="stylesheet" href="/assets/plugins/slick/slick.min.css">
  <link rel="stylesheet" href="/assets/plugins/ui-range-slider/jquery-ui.css">
  <!-- Vendor stylesheets  -->
  <link rel="stylesheet" href="/assets/css/main.css">
  <!-- Custom stylesheet -->
  <style type="text/css">
  	#c_634,#c_512,#c_414
	{
		display: none;
	}
	.signup-btn
	{
		display: none!important;
	}
  </style>
</head>

<body>
	<app-home-navbar></app-home-navbar>
	<br/>
	<br/>
	<br/>
	<br/>
	<div class=" pt-5 pl-5 -r-5">
		<!-- <div class="card-header">
			<h4>Terms and Conditions</h4>
		</div> -->
		<div class="">
			<div class="row text-center">
				<div class="col-md-12">
					<img src="/assets/images/logo.png">
				</div>
			</div>
			<div class="row">
				<div class="col-md-8"></div>
				<div class="col-md-4" >
					<select class="form-control float-right" id="country_code" (change)="country_change()">
						<option value="784">United Arab emirates</option>
						<option value="634">Qatar</option>
						<option value="414">Kuwait</option>
						<option value="512">Oman</option>
					</select>
				</div>

			</div>
			
			<br/>
			<div id="c_784" style="padding:5px 20px!important" class="terms_and_conditions">
				<h6 class='text-uppercase font-weight-semibold' style="font-weight:bold">Terms & conditions</h6>
		          <ul>
		            <li> All customers / members are advised to read and comply with the detailed terms & conditions mentioned in the catalogue, and if required, also to clarify with the respective outlet at the time of enrolling for the program.</li>
		            <li>Payment of monthly Reload amount can be made at any of Kalyan Jewellers’ outlets in UAE/Online. Monthly payments should reach before the 15th of every month. Payment reaching after this date will be treated as default / not paid for that particular month and final offer benefits will be calculated on that basis, apart from postponing the date of the jewellery purchase.</li>
		            <li>On maturity of the program, member is eligible to purchase ornaments in gold, diamonds, pearl or precious stones at the existing market terms and conditions as applicable on that date. Gold Rate protection & Partial redemption of Load/Reload amount is not applicable under this program. No cash will be awarded / refunded. For non MRP gold ornaments, the gold price prevailing on the date of purchase by the member shall be applicable.</li>
		            <li>Purchase of 22 carat/24 carat Gold coins(or) Bars (or) Biscuits and 24 Carat jewellery are not allowed under this program</li>
		            <li>All entries made or not made can be deemed approved if not contested in writing by the card holders within 15 days of due date or the date of entry.</li>
		            <li>Members should ensure that monthly card reloads made by them are correctly entered upon the payment. In case of any discrepancy, it should be brought to the notice of the respective outlet manager for necessary correction.</li>
		            <li>Kalyan Jewellers has the right but not the obligation to further verify the identity of a member’s ID or stop any transaction on a card if it deems an identity not to be properly established.</li>
		            <li>Pre-payment of monthly card reload at one time and redemption (with benefits) on or before the indicated day of maturity (based on tenure selected by the customer at the time of joining the program) will not be allowed. If the member wishes to pay all the monthly card reloads, he / she can do so. However, they will be eligible for benefits only on maturity of the program and not at any time prior to the period.</li>
		            <li>Purchase with maturity benefit can be availed only after payment of the last Reload Amount, according to the maturity of the G.C.R.P. Reload Amount cannot be earlier than 15 days before the actual maturity date.</li>
		            <li>Kalyan Jewellers UAE reserves the right to alter, amend, add or delete part or whole of the benefits of the program without prior notice. This program is sponsored by Kalyan Jewellers, hence reserves the right to suspend it at any time. In such an event, he/she may purchase the item at the Kalyan Jewellers outlet where they are a member of the program, equal to the value of the money accumulated in that Gift card as on that date.</li>
		            <li>By entering the program, you accept these terms & conditions and hereby agree to fully abide by the same.</li>
		            <li>Kalyan Jewellers reserves the right to change the general terms and conditions at any time. We may provide notification of such changes on our website www.kalyanjewellers.net or through our outlets in UAE.</li>
		          </ul>
		          <h4 class="text-center" style="margin-top: 20px;font-weight:bold">GIFT CARD RELOAD PROGRAM</h4>
		          <p class="font-weight-semibold text-uppercase text-center" style="font-weight:bold">DETAILS OF THE GIFT CARD RELOAD PROGRAM ARE AS FOLLOWS (G.C.R.P)</p>
		          <table  class="table table-bordered text-center">
		            <thead class="thead-light">
		                <tr style="font-weight:bold text-uppercase">

		                  <th>MONTHS</th>
		                  <th>NUMBER OF CARD RELOADS</th>
		                  <th>GOLD </th>
		                  <th>PRECIOUS/ UNCUT/POLKI</th>
		                  <th>diamond</th>
		                </tr>
		            </thead>
		            <tr>
		              <td >6 Months  </td>
		              <td >6 Times</td>
		              <td> 40% </td>
		              <td> 60% </td>
		              <td> 80% </td>
		            </tr>
		            <tr>
		              <td >9 Months  </td>
		              <td >9 Times </td>
		              <td> 60% </td>
		              <td> 90% </td>
		              <td> 120% </td>
		            </tr>
		            <tr>
		              <td > 12 Months </td>
		              <td > 12 Times</td>
		              <td> 100% </td>
		              <td> 120% </td>
		              <td> 160% </td>
		            </tr>
		          
		                                

		        </table>
		        <h6 class='text-uppercase' style="font-weight:bold">Customer Benefits</h6>
		        <ul>
		            <li>Minimum load/reload amount is AED 200. It can be above this amount but in multiples of 100</li>
		            <li>Gift card can be reloaded with the same amount only once a month</li>
		            <li>Customer can enjoy additional benefits on their Load/Reload Amount </li>
		            <li>Additional benefit would vary based on the duration and product category</li>

		            <li>The above chart indicates the benefits on each product category and period</li>

		            <li>Benefits is the percentage of your monthly Reload amount </li>
		            <li>The Gift card is valid for a period of 12 months from the date of activation</li>
		        </ul>
			</div>
			<div id="c_634" style="padding:5px 20px!important" class="terms_and_conditions">
					<h6 class="text-uppercase" style="font-weight:bold">Terms & conditions:</h6>
		          <ul>
		            <li>All customers / members are advised to read and comply with the detailed terms & conditions mentioned in the catalogue, and if required, also to clarify with the respective outlet at the time of enrolling for the program.</li>
		            <li>Payment of monthly Reload amount can be made at any of Kalyan Jewellers’ outlets in QATAR/Online. Monthly payments should reach before the 15th of every month. Payment reaching after this date will be treated as default / not paid for that particular month and final offer benefits will be calculated on that basis, apart from postponing the date of the jewellery purchase.</li>
		            <li>On maturity of the program, member is eligible to purchase ornaments in gold, diamonds, pearl or precious stones at the existing market terms and conditions as applicable on that date. Gold Rate protection & Partial redemption of Load/Reload amount is not applicable under this program. No cash will be awarded / refunded. For non MRP gold ornaments, the gold price prevailing on the date of purchase by the member shall be applicable.</li>
		            <li>Purchase of 22 carat/24 carat Gold coins(or) Bars (or) Biscuits and 24 Carat jewellery are not allowed under this program</li>
		            <li>All entries made or not made can be deemed approved if not contested in writing by the card holders within 15 days of due date or the date of entry.</li>
		            <li>Members should ensure that monthly card reloads made by them are correctly entered upon the payment. In caseof any discrepancy, it should be brought to the notice of the respective outlet manager for necessary correction.</li>
		            <li>Kalyan Jewellers has the right but not the obligation to further verify the identity of a member’s ID or stop any transaction on a card if it deems an identity not to be properly established.</li>
		            <li>Pre-payment of monthly card reload at one time and redemption (with benefits) on or before the indicated day of maturity (based on tenure selected by the customer at the time of joining the program) will not be allowed. If the member wishes to pay all the monthly card reloads, he / she can do so. However, they will be eligible for benefits only on maturity of the program and not at any time prior to the period.</li>
		            <li>Purchase with maturity benefit can be availed only after payment of the last Reload Amount, according to the maturity of the G.C.R.P. Reload Amount cannot be earlier than 15 days before the actual maturity date.</li>
		            <li>Kalyan Jewellers QATAR reserves the right to alter, amend, add or delete part or whole of the benefits of the program without prior notice. This program is sponsored by Kalyan Jewellers, hence reserves the right to suspend it at any time. In such an event, he/she may purchase the item at the Kalyan Jewellers outlet where they are a member of the program, equal to the value of the money accumulated in that Gift card as on that date.</li>
		            <li>By entering the program, you accept these terms and conditions & hereby agree to fully abide by the same.</li>
		            <li>Kalyan Jewellers reserves the right to change the general terms and conditions at any time. We may provide notification of such changes on our website www.kalyanjewellers.net or through our outlets in QATAR.</li>

		          </ul>
		          <h4 class="text-center" style="margin-top: 20px;">GIFT CARD RELOAD PROGRAM</h4>
		          <p class="font-weight-semibold text-uppercase text-center">DETAILS OF THE GIFT CARD RELOAD PROGRAM ARE AS FOLLOWS (G.C.R.P)</p>
		          <table  class="table table-bordered text-center">
		            <thead class="thead-light">
		                <tr style="font-weight:bold text-uppercase">

		                  <th>MONTHS</th>
		                  <th>NUMBER OF CARD RELOADS</th>
		                  <th>GOLD </th>
		                  <th>PRECIOUS/ UNCUT/POLKI</th>
		                  <th>diamond</th>
		                </tr>
		            </thead>
		            <tr>
		              <td >6 Months  </td>
		              <td >6 Times</td>
		              <td> 40% </td>
		              <td> 60% </td>
		              <td> 80% </td>
		            </tr>
		            <tr>
		              <td >9 Months  </td>
		              <td >9 Times </td>
		              <td> 60% </td>
		              <td> 90% </td>
		              <td> 120% </td>
		            </tr>
		            <tr>
		              <td > 12 Months </td>
		              <td > 12 Times</td>
		              <td> 80% </td>
		              <td> 120% </td>
		              <td> 160% </td>
		            </tr>
		          
		                                

		        </table>
		        <h6 class='text-uppercase' style="font-weight:bold">Customer Benefits</h6>
		        <ul>
		            <li>Minimum load/reload amount is QAR 200. It can be above this amount but in multiples of 100</li>
		            <li>Gift card can be reloaded with the same amount only once a month</li>
		            <li>Customer can enjoy additional benefits on their Load/Reload Amount </li>
		            <li>Additional benefit would vary based on the duration and product category</li>

		            <li>The above chart indicates the benefits on each product category and period</li>

		            <li>Benefits is the percentage of your monthly Reload amount </li>
		            <li>The Gift card is valid for a period of 12 months from the date of activation</li>
		        </ul>
			</div>
			<div id="c_414" style="padding:5px 20px!important" class="terms_and_conditions">
				<h6 class="text-uppercase" style="font-weight:bold">Terms & Conditions:</h6>
		          <ul>
		            <li>All customers / members are advised to read and comply with the detailed terms & conditions mentioned in the catalogue, and if required, also to clarify with the respective outlet at the time of enrolling for the program.</li>
		            <li>Payment of monthly Reload amount can be made at any of Kalyan Jewellers’ outlets in KUWAIT/Online. Monthly payments should reach before the 15th of every month. Payment reaching after this date will be treated as default / not paid for that particular month and final offer benefits will be calculated on that basis, apart from postponing the date of the jewellery purchase.</li>
		            <li>On maturity of the program, member is eligible to purchase ornaments in gold, diamonds, pearl or precious stones at the existing market terms and conditions as applicable on that date. Gold Rate protection & Partial redemption of Load/Reload amount is not applicable under this program. No cash will be awarded / refunded. For non MRP gold ornaments, the gold price prevailing on the date of purchase by the member shall be applicable.</li>
		            <li>Purchase of 22 carat/24 carat Gold coins(or) Bars (or) Biscuits and 24 Carat jewellery are not allowed under this program</li>
		            <li>All entries made or not made can be deemed approved if not contested in writing by the card holders within 15 days of due date or the date of entry.</li>
		            <li>Members should ensure that monthly card reloads made by them are correctly entered upon the payment. In caseof any discrepancy, it should be brought to the notice of the respective outlet manager for necessary correction.</li>
		            <li>Kalyan Jewellers has the right but not the obligation to further verify the identity of a member’s ID or stop any transaction on a card if it deems an identity not to be properly established.</li>
		            <li>Pre-payment of monthly card reload at one time and redemption (with benefits) on or before the indicated day of maturity (based on tenure selected by the customer at the time of joining the program) will not be allowed. If the member wishes to pay all the monthly card reloads, he / she can do so. However, they will be eligible for benefits only on maturity of the program and not at any time prior to the period.</li>
		            <li>Purchase with maturity benefit can be availed only after payment of the last Reload Amount, according to the maturity of the G.C.R.P. Reload Amount cannot be earlier than 15 days before the actual maturity date.</li>
		            <li>Kalyan Jewellers KUWAIT reserves the right to alter, amend, add or delete part or whole of the benefits of the program without prior notice. This program is sponsored by Kalyan Jewellers, hence reserves the right to suspend it at any time. In such an event, he/she may purchase the item at the Kalyan Jewellers outlet where they are a member of the program, equal to the value of the money accumulated in that Gift card as on that date.</li>
		            <li>By entering the program, you accept these terms and conditions & hereby agree to fully abide by the same.</li>
		            <li>Kalyan Jewellers reserves the right to change the general terms and conditions at any time. We may provide notification of such changes on our website www.kalyanjewellers.net or through our outlets in KUWAIT.</li>

		          </ul>
		          <h4 class="text-center" style="margin-top: 20px;">GIFT CARD RELOAD PROGRAM</h4>
		          <p class="font-weight-semibold text-uppercase text-center">DETAILS OF THE GIFT CARD RELOAD PROGRAM ARE AS FOLLOWS (G.C.R.P)</p>
		          <table  class="table table-bordered text-center">
		            <thead class="thead-light">
		                <tr style="font-weight:bold text-uppercase">

		                  <th>MONTHS</th>
		                  <th>NUMBER OF CARD RELOADS</th>
		                  <th>GOLD </th>
		                  <th>PRECIOUS/ UNCUT/POLKI</th>
		                  <th>diamond</th>
		                </tr>
		            </thead>
		            <tr>
		              <td >6 Months  </td>
		              <td >6 Times</td>
		              <td> 40% </td>
		              <td> 60% </td>
		              <td> 80% </td>
		            </tr>
		            <tr>
		              <td >9 Months  </td>
		              <td >9 Times </td>
		              <td> 60% </td>
		              <td> 90% </td>
		              <td> 120% </td>
		            </tr>
		            <tr>
		              <td > 12 Months </td>
		              <td > 12 Times</td>
		              <td> 100% </td>
		              <td> 120% </td>
		              <td> 160% </td>
		            </tr>



		        </table>
		        <h6 class='text-uppercase' style="font-weight:bold">Customer Benefits</h6>
		        <ul>
		            <li>Minimum load/reload amount is KWD 20. It can be above this amount but in multiples of 10</li>
		            <li>Gift card can be reloaded with the same amount only once a month</li>
		            <li>Customer can enjoy additional benefits on their Load/Reload Amount </li>
		            <li>Additional benefit would vary based on the duration and product category</li>

		            <li>The above chart indicates the benefits on each product category and period</li>

		            <li>Benefits is the percentage of your monthly Reload amount </li>
		            <li>The Gift card is valid for a period of 12 months from the date of activation</li>
		        </ul>
			</div>
			<div id="c_512" style="padding:5px 20px!important" class="terms_and_conditions">
				<h6 class='text-uppercase font-weight-semibold' style="font-weight:bold">General conditions</h6>
		          <ul>
		            <li> All customers / members are advised to read and comply with the detailed general conditions mentioned in the catalogue, and if required, also to clarify with the respective outlet at the time of enrolling for the program.</li>
		            <li>Payment of monthly Reload amount can be made at any of Kalyan Jewellers’ outlets in OMAN/Online. Monthly payments should reach before the 15th of every month. Payment reaching after this date will be treated as default / not paid for that particular month and final offer benefits will be calculated on that basis, apart from postponing the date of the jewellery purchase</li>
		            <li>On maturity of the program, member is eligible to purchase ornaments in gold, diamonds, pearl or precious stones at the existing market conditions as applicable on that date. Gold Rate protection & Partial redemption of Load/Reload amount is not applicable under this program. No cash will be awarded / refunded. For non MRP gold ornaments, the gold price prevailing on the date of purchase by the member shall be applicable.</li>
		            <li>Purchase of 22 carat Gold coins is also allowed. 24 carat Gold coins(or) Bars (or) Biscuits and 24 Carat jewellery are not allowed under this program</li>
		            <li>All entries made or not made can be deemed approved if not contested in writing by the card holders within 15 days of due date or the date of entry.</li>
		            <li>Members should ensure that monthly card reloads made by them are correctly entered upon the payment. In case of any discrepancy, it should be brought to the notice of the respective outlet manager for necessary correction.</li>
		            <li>Kalyan Jewellers has the right but not the obligation to further verify the identity of a member’s ID or stop any transaction on a card if it deems an identity not to be properly established.</li>
		            <li>Pre-payment of monthly card reload at one time and redemption (with benefits) on or before the indicated day of maturity (based on tenure selected by the customer at the time of joining the program) will not be allowed. If the member wishes to pay all the monthly card reloads, he / she can do so. However, they will be eligible for benefits only on maturity of the program and not at any time prior to the period.</li>
		            <li>Purchase with maturity benefit can be availed only after payment of the last Reload Amount, according to the maturity of the G.C.R.P. Reload Amount cannot be earlier than 15 days before the actual maturity date.</li>
		            <li>Kalyan Jewellers OMAN reserves the right to alter, amend, add or delete part or whole of the benefits of the program without prior notice. This program is sponsored by Kalyan Jewellers, hence reserves the right to suspend it at any time. In such an event, he/she may purchase the item at the Kalyan Jewellers outlet where they are a member of the program, equal to the value of the money accumulated in that Gift card as on that date.</li>
		            <li>By entering the program, you accept these conditions & hereby agree to fully abide by the same.</li>
		            <li>Kalyan Jewellers reserves the right to change the general conditions at any time. We may provide notification of such changes on our website www.kalyanjewellers.net or through our outlets in OMAN.</li>
		          </ul>
		          <h4 class="text-center" style="margin-top: 20px;font-weight:bold">GIFT CARD RELOAD PROGRAM</h4>
		          <p class="font-weight-semibold text-uppercase text-center" style="font-weight:bold">DETAILS OF THE GIFT CARD RELOAD PROGRAM ARE AS FOLLOWS (G.C.R.P)</p>
		          <table  class="table table-bordered text-center">
		            <thead class="thead-light">
		                <tr style="font-weight:bold text-uppercase">

		                  <th>MONTHS</th>
		                  <th>NUMBER OF CARD RELOADS</th>
		                  <th>GOLD </th>
		                  <th>PRECIOUS/ UNCUT/POLKI</th>
		                  <th>diamond</th>
		                </tr>
		            </thead>
		            <tr>
		              <td >6 Months  </td>
		              <td >6 Times</td>
		              <td> 40% </td>
		              <td> 60% </td>
		              <td> 100% </td>
		            </tr>
		            <tr>
		              <td >9 Months  </td>
		              <td >9 Times </td>
		              <td> 60% </td>
		              <td> 90% </td>
		              <td> 120% </td>
		            </tr>
		            <tr>
		              <td > 12 Months </td>
		              <td > 12 Times</td>
		              <td> 100% </td>
		              <td> 120% </td>
		              <td> 160% </td>
		            </tr>



		        </table>
		        <h6 class='text-uppercase' style="font-weight:bold">Customer Benefits</h6>
		        <ul>
		            <li>Minimum load/reload amount is OMR 20. It can be above this amount but in multiples of 10</li>
		            <li>Gift card can be reloaded with the same amount only once a month</li>
		            <li>Customer can enjoy additional benefits on their Load/Reload Amount</li>
		            <li>Additional benefit would vary based on the duration and product category</li>

		            <li>The above chart indicates the benefits on each product category and period</li>

		            <li>Benefits is the percentage of your monthly Reload amount</li>
		            <li>Gift card is valid for a period of 12 months from the date of activation</li>
		        </ul>
			</div>
		</div>
		<button class="btn btn-danger float-right" (click)='home()'><i class="fa fa-backward text-white"></i> Back to Home</button>
	</div>

 <script src="/assets/js/vendor.min.js"></script>
  <!-- Plugin's Scripts -->
  <script src="/assets/plugins/fancybox/jquery.fancybox.min.js"></script>
  <script src="/assets//assets/plugins/nice-select/jquery.nice-select.min.js"></script>
  <script src="/assets/plugins/aos/aos.min.js"></script>
  <script src="/assets/plugins/slick/slick.min.js"></script>
  <script src="/assets/plugins/counter-up/jquery.counterup.min.js"></script>
  <script src="/assets/plugins/counter-up/jquery.waypoints.min.js"></script>
  <script src="/assets/plugins/ui-range-slider/jquery-ui.js"></script>
  <!-- Activation Script -->
  <!-- <script src="js/drag-n-drop.js"></script> -->
  <script src="/assets/js/custom.js"></script>

</body>

</html>
