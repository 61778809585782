import { Component, OnInit } from '@angular/core';

import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { ConfirmPasswordForm} from '../data-model';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { AppService } from '../app.service'
import * as $ from 'jquery';



@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
confirmpasswordForm: ConfirmPasswordForm = new ConfirmPasswordForm();
opened = true;
  over = 'side';
  expandHeight = '42px';
  collapseHeight = '42px';
  displayMode = 'flat';
  ComNameStyle:object;
  LoginXotpStyle:object;
  ConfirmPasswordClass: boolean;
  confirmPasswordStyle: object;
  validate_message: string = "";
  registerWarningClass: boolean;
  registerWarningStyle: object;
  msg: string = "";
  warning_content:any;
  c_email = localStorage.getItem('odooemailid');
  c_mobile = localStorage.getItem('mobile');
  c_name=localStorage.getItem('country_name');
  session_key = localStorage.getItem('session_valid_key');
  persfirstname:any; 
  perslastname:any;
  persdob:any; 
  persmobile:any;
  persemail:any;
  curstreet1:string = null;
  curstreet2:string = null;
  curcity:string = null;
  curstate:string = null;
  curpobox:string = null;
  curcountry:string = null;
  perstreet1:string = null; 
  perstreet2:string = null;
  percity: string = null;
  perpobox:string = null;
  perstate:string = null;
  percountry:string = null;

  watcher: Subscription;
  constructor(mediaObserver: MediaObserver,private router: Router,public spinner: NgxSpinnerService,public appService: AppService) {
    this.watcher = mediaObserver.media$.subscribe((change: MediaChange) => {
      if (change.mqAlias === 'sm' || change.mqAlias === 'xs') {
        this.opened = false;
        this.over = 'over';
        this.ComNameStyle ={'display':'block'};
        this.LoginXotpStyle = {'margin-right':'6%'};
        
        
        
      } else {
        this.opened = true;
        this.over = 'side';
        this.ComNameStyle ={'display':'block'};
        this.LoginXotpStyle={'margin-right':'0%'};
        
      }
    });
  }


  getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = $.trim(cookies[i]);
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
  }

  ngOnInit(): void {
  let sessionkey=localStorage.getItem('session_valid_key')
  // console.log(sessionkey,"kdkdhdhdbccggd")
  if(sessionkey=="logout"){
  this.router.navigate(['/home'])
  }
  this.renderprofileview(localStorage.getItem('odoomobilenum'))
  }

  hideWarning = function(){
    this.registerWarningClass = false;
    this.registerWarningStyle = {'display': 'none'};
  }

change_password = function(){
	var self =this;
	self.confirmpasswordForm={
	password:"",
	confirmPassword:""
	}
	self.ConfirmPasswordClass = true;
	self.confirmPasswordStyle = {'display': 'block'};
	return false
}

onNoconfirmpasswordclick = function(){
    var self=this;
    self.confirmpasswordForm={
    password:"",
    confirmPassword:""
    };
    $(".warningconfirmpassword").html("")
    this.ConfirmPasswordClass = false;
    this.confirmPasswordStyle = {'display': 'none'};
    return false;

  
}

  logout = function(){
   
  var self = this;
  self.spinner.show(); 
  self.renderlogout(localStorage.getItem('odoomobilenum'))
  localStorage.setItem('session_valid_key', "logout");
  self.router.navigate(['/home'])
  self.spinner.hide();
  localStorage.clear() 
}
rglink = function(){
   
  var self = this;
  self.spinner.show(); 
  self.router.navigate(['/giftcardregistered'])
  self.spinner.hide(); 
}
gclink = function(){
   
  var self = this;
  self.spinner.show(); 
  self.router.navigate(['/giftcard'])
  self.spinner.hide(); 
}
glistlink = function(){
   
  var self = this;
  self.spinner.show(); 
  self.router.navigate(['/giftcardlist'])
  self.spinner.hide(); 
}

passwordValidation(x){
// console.log('conign inside password validation',x)
let passwd = x.target.value
if (passwd.length < 8 && passwd.length != 0){
var self = this;
if (x.target.id == 'id_password'){
$('#id_warn_message').show();
$('#id_confirm_warn_message').hide();
}
else
{
$('#id_confirm_warn_message').show();
$('#id_warn_message').hide();

}
}
else{
if (x.target.id == 'id_password'){
$('#id_warn_message').hide();
}
else
{
$('#id_confirm_warn_message').hide();
}
}
}

renderprofileview(mobilenumber){
	var self= this;
  self.spinner.show();
	var session_id='';
    if(this.getCookie('kalyan_id')!=null){
      session_id=this.getCookie('kalyan_id');Date
    }  
    let args = {'session': self.session_key,'mobile_number':mobilenumber };
    var csrftoken = this.getCookie('csrf_token');
    $.ajax({
            beforeSend: function(xhr, settings) {
              xhr.setRequestHeader("X-CSRFToken", csrftoken);
            },
            method: "POST",
            url: "/web/payment_portal/giftcarddatapopulation/",
            dataType: "json",
            data : {data:JSON.stringify(args),csrf_token: csrftoken},
            success: function(response) {
            if (response.error.status=="4002")
            {   
              if(response.error.description == "Session not valid"){
                console.log('inside function')  
                self.logout()
              }
              
            }
            else{
              self.persfirstname=response.data[0].firstname, 
              self.perslastname=response.data[0].lastname,
              self.persdob=response.data[0].dob, 
              self.persmobile=response.data[0].mobilenumber , 
              self.persemail=response.data[0].email,
              self.curstreet1=response.data[0].curstreet1,
              self.curstreet2=response.data[0].curstreet2,
              self.curcity=response.data[0].curcity,
              self.curpobox=response.data[0].curpobox,
              self.curcountry=response.data[0].curcountry, 
              self.perstreet1=response.data[0].perstreet1, 
              self.perstreet2=response.data[0].perstreet2 ,
              self.percity= response.data[0].percity,
              self.perpobox=response.data[0].perpobox,
              self.percountry=response.data[0].percountry
              self.spinner.hide();
            }
            },
            error: function(err) {
              console.log('err')
             
            }
            });

}
validatePassword(password){
var pattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!#$%&?@])([a-zA-Z0-9!#$%&?@]+)$/
// console.log('tttttttttttttttttt',pattern)
if (pattern.test(password)){
    return true;
}
else{
    return false;
}
}

renderlogout(mobile_number){
var self= this;
self.spinner.show();
// console.log(mobile_number,"registered giftcard")
   var session_id='';
    if(this.getCookie('kalyan_id')!=null){
      session_id=this.getCookie('kalyan_id');Date
    }  
    let args = {'session': session_id,'mobile_number':mobile_number };
   var csrftoken = this.getCookie('csrf_token');
  
   $.ajax({
            beforeSend: function(xhr, settings) {
              xhr.setRequestHeader("X-CSRFToken", csrftoken);
            },
            method: "POST",
            url: "/web/payment_portal/logout/",
            dataType: "json",
            data : {data:JSON.stringify(args),csrf_token: csrftoken},
            success: function(response) {
            self.spinner.hide();
            },
            error: function(err) {
              console.log('err')
             
            }
            });

   }

confirmPasswordSubmit(value){
var self=this;
// console.log(value.password,value.confirm_password)
  let password=value.password
  let confirm_password=value.confirm_password
  // console.log(password,confirm_password)
  if(!password || !confirm_password){
  self.validate_message =""
  self.validate_message = "Please Enter the Correct Password";

    $(".warningconfirmpassword").html("");

    $("<br/><span style='color:red'><t>"+self.validate_message+"</t></span>").appendTo(".warningconfirmpassword")
    return false;
  }
  if(password!=confirm_password){
  self.validate_message =""
  self.validate_message = "Please Enter Correct Password";

    $(".warningconfirmpassword").html("");

    $("<br/><span style='color:red'><t>"+self.validate_message+"</t></span>").appendTo(".warningconfirmpassword")
    return false;
  }
  if (password.length < 8){
    
    this.validate_message = "Password must be minimum 8 character";
    $('#id_warn_message').hide();
    $(".warning").html("");

    $("<br/><span><t>"+this.validate_message+"</t></span>").appendTo(".warning")
    return false;
    
    }
    else{
    let validPassword = self.validatePassword(password)
    // console.log('password validatedddddddddddddd',validPassword)
    if (validPassword == false){
        this.validate_message = "Password should contain Upper Case Letter, a Number and one special character from this list !#$%&?@";
        $('#id_warn_message').hide();
        $(".warningconfirmpassword").html("");

        $("<br/><span><t>"+this.validate_message+"</t></span>").appendTo(".warningconfirmpassword")
        return false;
    }
    
    }

  if(password && confirm_password){
  
  var session_id='';
    if(this.getCookie('kalyan_id')!=null){
      session_id=this.getCookie('kalyan_id');Date
    }
    let args = {'session': session_id,'email':localStorage.getItem('odooemailid'),'password':password,'confirm_password':confirm_password };
    var csrftoken = self.getCookie('csrf_token');
    self.spinner.show();  
    $.ajax({
            beforeSend: function(xhr, settings) {
              xhr.setRequestHeader("X-CSRFToken", csrftoken);
            },
            method: "POST",
            url: "/web/payment_portal/forgotpassword/",
            dataType: "json",
            data : {data:JSON.stringify(args),csrf_token: csrftoken},
            success: function(response) {
            self.confirmpasswordForm={
            password:"",
            confirmPassword:""
            };
            if(response.error.status=="4002"){
            $(".warningconfirmpassword").html("");
            var info="Forgot Password option Failed"
            $("<br/><span style='color:red;'><t>"+info+"</t></span>").appendTo(".warningconfirmpassword")
            self.spinner.hide();
            }
            else{
            self.confirmpasswordForm={
            password:"",
            confirmPassword:""
            };
            $(".warningconfirmpassword").html("")
            self.ConfirmPasswordClass = false;
            self.confirmPasswordStyle = {'display': 'none'};
            
            self.registerWarningClass = true;
            self.registerWarningStyle = {'display': 'flex'};
            self.warning_content=[]
            self.warning_content.push('Password changed successfully')
            
            }





            self.spinner.hide();
            },
            error: function(err) {
              console.log('err')
              self.validate_message = err['message'];
              
              self.spinner.hide();
            }
            });
  }
  
}


}
