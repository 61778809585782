<app-kalyan-navbar></app-kalyan-navbar>
<div class="container" style="margin-left: auto;">

    <div class="card container-card" *ngIf="schemeList != undefined">
        <div class="card-body">
            <h5 class="float-left mr-2">List of registered cards</h5> |
            <!-- <span class="top-cnt">Click here to register new gift card <a href="/giftcard" class="text-primary"><u><i
                            class="fa fa-plus-circle mr-2"></i>Register gift card</u></a></span> -->
            <br />
            <hr />
            <div class="row">
                <div class="col-md-4"><b>Customer Name : </b>{{schemeList[0].FirstName | uppercase}}
                    {{schemeList[0].LastName | uppercase}}</div>
                <div class="col-md-4"><b>Mobile : </b>{{schemeList[0].MobileNumber}}</div>
                <div class="col-md-4"><b>Customer ID :</b>{{schemeList[0].CustomerID}}</div>
            </div>
            <hr />
            <div class="row">
                <div class="col-md-12">
                    <div class="card mt-48902s43 0" *ngFor="let scheme of schemeList" id="mobile_scheme_list">
                        <div class="card-body" (click)="getSchemeInfo(scheme.EcryptedEnrollmentID)">
                            <table id="table-scheme-list">
                                <tr>
                                    <th>Giftcard ID</th>
                                    <td class="text-right">{{scheme.EnrollmentID}}</td>
                                </tr>
                                <tr>
                                    <th>Join Date</th>
                                    <td class="text-right">{{scheme.JoinDate}}</td>
                                </tr>
                                <tr>
                                    <th>Total Amount</th>
                                    <td class="text-right">{{scheme.TotalInstallmentAmount}}</td>
                                </tr>
                                <tr>
                                    <th>Monthly Denomination Amount</th>
                                    <td class="text-right">{{scheme.EMIAmount}}</td>
                                </tr>
                                <tr>
                                    <th>No. of Paid Denomination</th>
                                    <td class="text-right">{{scheme.NoOfPaidInstallment}}</td>
                                </tr>
                                <tr>
                                    <th>Paid Amount ({{currency}})</th>
                                    <td class="text-right">{{scheme.AmountPaid}}</td>
                                </tr>
                                <tr>
                                    <th>Balance Amount ({{currency}})</th>
                                    <td class="text-right">{{scheme.RemainingAmount}}</td>
                                </tr>
                            </table>

                        </div>
                        <div class="card-footer">
                            <table>
                                <tr>
                                    <td class="text-center">
                                        <button mat-raised-button color="warn" [ngStyle]='buttonpayonlinestyle'
                                            [hidden]="scheme?.RemainingAmount == 0 || scheme?.RemainingAmount == '1'"
                                            (click)="gifttopup(scheme.EnrollmentID)"
                                            style="border: none;outline:none;">Topup</button>
                                    </td>
                                    <td class="text-center">
                                        <button mat-raised-button color="warn" [ngStyle]='buttoncomstyle'
                                            (click)="paymenthistory(scheme.EnrollmentID)"
                                            [hidden]="scheme?.RemainingAmount == 0 || scheme?.RemainingAmount == '1'"
                                            style="border: none;outline:none;">View</button>
                                    </td>

                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="table-responsive" id="id_menu_heading_web">
                        <table class="table table-striped  table-sm row-border hover" id="cards-table" datatable
                            [dtOptions]="dtOptions">

                            <thead style="background-color:#7e8989;color:#ffffff;">

                                <th style="width:200px;">Giftcard ID </th>
                                <th style="width:200px;">Registered Date </th>
                                <th style="margin-left: 40px;">Total Amount ({{currency}}) </th>
                                <th style="">Monthly Denomination Amount <br />({{currency}})</th>
                                <th style="">No. of Paid Denomination</th>
                                <th style="">Paid Amount ({{currency}})</th>
                                <th style="">Balance Amount <br />({{currency}})</th>
                                <th style="width:130px;">Giftcard Topup </th>
                                <th style="width:130px;">Payment History</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of schemeList">
                                    <td [hidden]="item?.RemainingAmount == 0"> {{item.EnrollmentID}} </td>
                                    <td [hidden]="item?.RemainingAmount == 0"> {{item.JoinDate | date: 'dd-MM-yyyy'}}
                                    </td>
                                    <td [hidden]="item?.RemainingAmount == 0"> {{item.TotalInstallmentAmount}} </td>
                                    <td [hidden]="item?.RemainingAmount == 0"> {{item.EMIAmount}} </td>
                                    <td [hidden]="item?.RemainingAmount == 0"> {{item.NoOfPaidInstallment}} </td>
                                    <td [hidden]="item?.RemainingAmount == 0"> {{item.AmountPaid}} </td>
                                    <td [hidden]="item?.RemainingAmount == 0 || item?.RemainingAmount == '1'">
                                        {{item.RemainingAmount}} </td>

                                    <td style="width: 130px;">
                                        <t *ngIf="item.EnrollmentID"><button mat-raised-button color="warn"
                                                [ngStyle]='buttonpayonlinestyle'
                                                [hidden]="item?.RemainingAmount == 0 || item?.RemainingAmount == '1'"
                                                (click)="gifttopup(item.EnrollmentID)"
                                                style="border: none;outline:none;">Topup</button></t>
                                    </td>


                                    <td style="width: 130px;"> <button mat-raised-button color="warn"
                                            [ngStyle]='buttoncomstyle' (click)="paymenthistory(item.EnrollmentID)"
                                            [hidden]="item?.RemainingAmount == 0 || item?.RemainingAmount == '1'"
                                            style="border: none;outline:none;">View</button></td>
                                </tr>

                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>