<app-kalyan-navbar></app-kalyan-navbar>
<div class="container mt-25">
  <div class="row">
    <div class="col-md-6 mx-auto mt-5">
      <div class="payment">
        <div class="payment_header">
          <div class="check" *ngIf="paymentstatus=='success'"><i class="fa fa-check text-success"
              aria-hidden="true"></i>
          </div>
          <div class="check" *ngIf="paymentstatus!='success'">
            <i class="fa fa-times text-danger" aria-hidden="true"></i>
          </div>
        </div>
        <div class="content">
          <h1 *ngIf="paymentstatus=='success'">Payment Success !</h1>
          <h1 *ngIf="paymentstatus!='success'">Payment Failed !</h1>
          <p>Please find below your gift card number and payment reference number. </p><br>
          <p><b>Giftcard No. :</b>{{cardnumber}}</p>
          <p><b>Reference Number :</b>{{referencenumber}}</p>
          <p><b>Amount :</b>{{amount}}</p>
          <!-- <p *ngIf="knet_transaction_id != ''">
            <b>Knet Transaction Id</b> :{{knet_transaction_id}}
          </p>
          <p *ngIf="knet_trackid != ''"><b>Knet Trackid:</b>{{knet_trackid}}</p>
          <p *ngIf="knet_transaction_id != ''"><b>Date:</b>{{date}}:{{time}}</p> -->
          <p *ngIf="si_status=='yes' && paymentstatus=='success'"><b>Successfully Registered Auto debit Standing
              Instruction Please Maintain Sufficient balance on everymonth</b></p>
          <a href="/giftcardregistered">Go to Home</a>
        </div>

      </div>
    </div>
  </div>
</div>