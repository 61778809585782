<app-kalyan-navbar></app-kalyan-navbar>
<mat-toolbar style="background-color: #e72126 !important;" class="header-box-shadow">
  <!--<button mat-icon-button (click)="sidenav.toggle()" style="outline: none;" fxShow.sm="true" fxShow.gt-sm="false">
        <mat-icon>menu</mat-icon>
      </button>-->
  <img style="float:left" src="/assets/image/favicon.ico" />
  <!-- <span id="id_menu_heading_web" style="color: white"  fxFlex><a style="color:white;" routerLink='/home'>Kalyan Jewellers</a></span> -->
  <span style="color: white" [ngStyle]='ComNameStyle' fxFlex><a style="color:white;" routerLink='/home'>Kalyan
      Jewellers</a></span>
  <span class="example-spacer"></span>

  <!--<span>{{cusname | uppercase}}</span>-->
  <!-- <button mat-button style="background-color: inherit;color: white;font-size: 14px;font-weight: 500;"><a class="nav-link" style="color:white;font-size: 14px;font-weight: 500;" target="new" href="https://www.candere.com" 
        aria-expanded="false"><span>Shop Online at Candere</span></a>
    </button>
     <button mat-button style="background-color: inherit;color: white;font-size: 14px;font-weight: 500;"><a class="nav-link" style="color:white;font-size: 14px;font-weight: 500;" target="new" href="https://kalyanjewellers.net/contact-us.php" 
        aria-expanded="false"><span>Support</span></a></button>-->

  <!--        <button mat-button style="background-color: inherit;color: white;font-size: 14px;font-weight: 500;" (click)="glistlink()">Giftcard List </button> -->
  <!--<button mat-button style="background-color: inherit;color: white;font-size: 14px;font-weight: 500; text-align:left"><a style="color:white;font-size: 14px;font-weight: 500;" target="new" href="https://www.candere.com" 
        aria-expanded="false"><span>Shop Online at Candere</span></a>
    </button>-->



  <div><span></span></div>
  <mat-divider></mat-divider>
  <!-- <button mat-menu-item><a class="nav-link" style="color:black;font-size: 14px;font-weight: 500;" target="new" href="https://www.candere.com" 
        aria-expanded="false"><span>Shop Online at Candere</span></a></button>
        <mat-divider></mat-divider>
  <button mat-menu-item><a class="nav-link" style="color:black;font-size: 14px;font-weight: 500;" target="new"  href="https://kalyanjewellers.net/contact-us.php" 
        aria-expanded="false"><span>Support</span></a></button>
        <mat-divider></mat-divider>-->
  <button mat-button fxHide.xs style="background-color: inherit;color: white;font-size: 14px;font-weight: 500;"
    (click)="gclink()"><mat-label>New Giftcard</mat-label>
  </button>
  <button mat-button fxHide.xs style="background-color: inherit;color: white;font-size: 14px;font-weight: 500;"
    (click)="rglink()">
    Registered Giftcard
  </button>

  <button mat-button fxHide.xs style="background-color: inherit;color: white;font-size: 14px;font-weight: 500;"><a
      style="color:white;font-size: 14px;font-weight: 500;" target="new"
      href="https://kalyanjewellers.net/contact-us.php" aria-expanded="false"><span>Support</span></a></button>

  <button mat-button fxHide.xs [matMenuTriggerFor]="customMenu"> <a>
      <mat-icon style="color:white;border: none;outline:none;" class="v-align-middle">account_circle</mat-icon>

    </a></button>
  <mat-menu #customMenu="matMenu">
    <div mat-menu-item fxHide.xs style="background-color: white;border: none;outline:none;">
      <mat-label>Email : {{c_email}}<br /></mat-label>
    </div>
    <div mat-menu-item fxHide.xs style="background-color: white;border: none;outline:none;">
      <mat-label>Mobile : {{c_mobile}}</mat-label>
    </div>
    <div mat-menu-item fxHide.xs style="background-color: white;border: none;outline:none;">
      <mat-label>Country : {{c_name}}</mat-label>
    </div>
    <mat-divider></mat-divider>
    <button mat-menu-item fxHide.xs style="background-color: white;border: none;outline:none;">
      <mat-label><button mat-raised-button color="warn" routerLink="/profile"
          style="border: none;outline:none;float:left!important">Profile</button><button mat-raised-button color="warn"
          style="border: none;outline:none;float:right" (click)="logout()">Logout</button></mat-label>
    </button>
  </mat-menu>
  <mat-divider></mat-divider>

  <button mat-icon-button [matMenuTriggerFor]="dropMenu" style="outline: none;color:white;" fxHide fxShow.xs>
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #dropMenu="matMenu">
    <ng-container>
      <button mat-button style="background-color: inherit;color: grey;font-size: 14px;font-weight: 500;height:36px;"
        (click)="gclink()"><mat-label>New Giftcard</mat-label>
      </button>
      <br />
      <button mat-button style="background-color: inherit;color: grey;font-size: 14px;font-weight: 500;height:36px;"
        (click)="rglink()">
        Registered Giftcard
      </button>
      <br />

      <button mat-button style="background-color: inherit;color: white;font-size: 14px;font-weight: 500;height:36px;"><a
          style="color:grey;font-size: 14px;font-weight: 500;height:36px;" target="new"
          href="https://kalyanjewellers.net/contact-us.php" aria-expanded="false"><span>Support</span></a></button>
      <br />
      <button mat-menu-item routerLink="/profile"
        style="background-color: white;color:grey;border: none;outline:none;font-weight: 500;height:36px;">

        <mat-label>Profile</mat-label>
      </button>


      <button mat-menu-item (click)="logout()"
        style="background-color: white;color:grey;font-weight: 500;height:36px;height:36px;">
        <mat-label>Logout</mat-label>
        <mat-icon class="mr">logout</mat-icon>

      </button>
      <mat-divider></mat-divider>
    </ng-container>
  </mat-menu>





</mat-toolbar>



<!-- ================================ -->

<mat-card>
  <div class="bg-default-1 pt-5">

    <div class="container" style="margin-top: auto;margin-left:auto;background: white">


      <mat-card>
        <!-- <form ngNoForm #nonseamless id="nonseamless" name="nonseamless" method="post" name="redirect">  -->
        <form ngNoForm #nonseamless id="nonseamless" name="nonseamless" method="post" name="redirect"
          action="https://secure.ccavenue.ae/transaction/transaction.do?command=initiateTransaction">
          <div id="proceed_payment_div">

            <!-- <table class="table table-sm table-bordered  w-50 text-center">
                        <tr>
                            <td style="width: 40%" class="bg-gray text-white">Customer Name</td>
                            <td>{{customerName}}</td>
                        </tr>
                        <tr>
                            <td style="width: 40%" class="bg-gray text-white pt-3">Reference Number</td>
                            <td>{{referencenumber}}</td>
                        </tr>
                       
                        <tr>
                            <td style="width: 40%" class="bg-gray text-white">Amount</td>
                            <td>{{amount}}</td>
                        </tr>
                        <tr>
                            <td>-->
            <!--<input type="hidden" id="encRequest" name="encRequest" value="{{encryptedString}}"/>
                                <input type="hidden" name="access_code" id="access_code" value="{{accescode}}"/>
                                <button type="submit"  id="confirm_payment">Confirm</button>-->
            <!-- </td>-->
            <!-- <td>-->
            <!-- <button  class="btn btn-danger btn-sm" (click)="backaction()" style="background: white;color:red;border: none"><i class="fa fa-backward mr-2"></i> Back</button>-->
            <!--</td>-->
            <!--</tr>
                    </table>-->
            <input type="hidden" id="encRequest" name="encRequest" value="{{encryptedString}}" />
            <input type="hidden" name="access_code" id="access_code" value="{{accescode}}" />
            <p>Please wait while your transaction is being processed. You will be automatically redirected to the
              confirmation page.Please do not hit "back", "forward" or "refresh" button.</p>
            <div class="form-group text-center m-t-15">
              <div class="col-xs-12">
                <!-- <button type="submit"  class="btn btn-sm btn-primary" style="display: none" style="color:white;background-color: red;align:center;" id="confirm_payment" >Confirm</button> -->
              </div>
            </div>
          </div>
          <br />
        </form>
        <script type="text/javascript">
          $('#nonseamless').submit()
        </script>

      </mat-card>



    </div>
  </div>
</mat-card>