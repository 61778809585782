<app-kalyan-navbar></app-kalyan-navbar>
<br/><br/>
<br/><br/>
<br/><br/>  
<div class="card">
    <div class="card-body mt-10">
        <p align="center" class="alert-danger">Please wait while your transaction is being processed. You will be automatically redirected to the payment page.</p> 
    </div>
</div>

