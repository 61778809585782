import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor() { }

  mobileNumber: any;
  paymentstatus: any;

  setMobileNumber(mobileNumber: any) {
  	this.mobileNumber = mobileNumber
  	return false;
  }

  getMobileNumber() {
  	return this.mobileNumber
  }

  setpaymentstatus(paymentstatus: any) {
    this.paymentstatus = paymentstatus
    console.log(paymentstatus,"kdkdkdkdkdjfhfh")
    return false;
  }

  getpaymentstatus() {
    return this.paymentstatus
  }

}
