<div class="site-wrapper overflow-hidden">
  <!-- Header start  -->
  <!-- Header Area -->
  
  <!-- navbar- -->
  <router-outlet></router-outlet>

  <!-- footer area function start -->
  <!-- cta section -->
  <footer
    class="footer bg-white dark-mode-texts fixed-bottom"
  >
    <div class="container text-center font-weight-semibold">
      <!-- Cta section -->
      Developed by <a class="text-primary">Aspirant Labs</a>
    </div>
  </footer>
  <!-- footer area function end -->
</div>
