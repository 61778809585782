import { Component, OnInit } from '@angular/core';

declare var Checkout: any;

@Component({
  selector: 'app-payment-doha-bank',
  templateUrl: './payment-doha-bank.component.html',
  styleUrls: ['./payment-doha-bank.component.css']
})
export class PaymentDohaBankComponent implements OnInit {

  constructor() { }


  ngOnInit(): void {
    this.loadCheckoutScript();
  }


  loadCheckoutScript() {
    const script = document.createElement('script');
    script.src = 'https://dohabank.gateway.mastercard.com/static/checkout/checkout.min.js';

    script.onload = () => this.configureCheckout();
    document.head.appendChild(script);
  }

  configureCheckout() {
    const sessionId = localStorage.getItem('checkout_session_id');
    if (sessionId) {
      Checkout.configure({
        session: {
          id: sessionId
        },
      });
      this.showPaymentPage();
    } else {
      console.error('Session ID not found in local storage');
    }

  }



  showPaymentPage() {
    Checkout.showPaymentPage();
  }


}
