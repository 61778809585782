import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import * as $ from 'jquery';

@Component({
  selector: 'app-qpay-gateway',
  templateUrl: './qpay-gateway.component.html',
  styleUrls: ['./qpay-gateway.component.css']
})
export class QpayGatewayComponent implements OnInit {

  opened = true;
  over = 'side';
  expandHeight = '42px';
  collapseHeight = '42px';
  displayMode = 'flat';
  ComNameStyle:object;
  SpanStyle:object;
  
  amount = localStorage.getItem('amount')
  currencyCode = localStorage.getItem('currencyCode')
  pun = localStorage.getItem('pun')
  merchantModuleSessionID = localStorage.getItem('merchantModuleSessionID')
  paymentDescription = localStorage.getItem('paymentDescription')
  nationalID = localStorage.getItem('nationalID')
  merchantID = localStorage.getItem('merchantID')
  bankID = localStorage.getItem('bankID')
  lang = localStorage.getItem('lang')
  action = localStorage.getItem('action')
  secureHash = localStorage.getItem('secureHash')
  transactionRequestDate = localStorage.getItem('transactionRequestDate')
  extraFields_f14 = localStorage.getItem('extraFields_f14')
  quantity = localStorage.getItem('quantity')

  constructor(private router: Router,public spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    let sessionkey=localStorage.getItem('session_valid_key')
    // console.log(sessionkey,"kdkdhdhdbccggd")
    if(sessionkey=="logout"){
    this.router.navigate(['/home'])
    
    }
    $('#amount').val(localStorage.getItem('amount'));
    $('#currencyCode').val(localStorage.getItem('currencyCode'));
    $('#pun').val(localStorage.getItem('pun'));
    $('#merchantModuleSessionID').val(localStorage.getItem('merchantModuleSessionID'));
    $('#paymentDescription').val(localStorage.getItem('paymentDescription'));
    $('#nationalID').val(localStorage.getItem('nationalID'));
    $('#merchantID').val(localStorage.getItem('merchantID'));
    $('#bankID').val(localStorage.getItem('bankID'));
    $('#lang').val(localStorage.getItem('lang'));
    $('#action').val(localStorage.getItem('action'));
    $('#secureHash').val(localStorage.getItem('secureHash'));
    $('#transactionRequestDate').val(localStorage.getItem('transactionRequestDate'));
    $('#extraFields_f14').val(localStorage.getItem('extraFields_f14'));
    $('#quantity').val(localStorage.getItem('quantity'));
    $('#nonseamless').submit();
  }

}


