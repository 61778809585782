import { AfterViewInit, Component, ViewChild, OnInit } from '@angular/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { AppService } from '../app.service'
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

export interface PeriodicElement {
  giftcard: string;
  position: number;
  action: number;
}


const ELEMENT_DATA: PeriodicElement[] = [
  {position: 1, giftcard: 'Akshaya Priority Scheme', action: 1}
  
];





@Component({
  selector: 'app-giftcardlist',
  templateUrl: './giftcardlist.component.html',
  styleUrls: ['./giftcardlist.component.css']
})
export class GiftcardlistComponent implements OnInit {
	opened = true;
  over = 'side';
  expandHeight = '42px';
  collapseHeight = '42px';
  displayMode = 'flat';
  ComNameStyle:object;
  
  mobile_number: any;
  mnumber=localStorage.getItem('mobile_Number');
  cusname=localStorage.getItem('cusname');

  watcher: Subscription;
  constructor(mediaObserver: MediaObserver,private router: Router,public spinner: NgxSpinnerService,public appService: AppService) {
    this.watcher = mediaObserver.media$.subscribe((change: MediaChange) => {
      if (change.mqAlias === 'sm' || change.mqAlias === 'xs') {
        this.opened = false;
        this.over = 'over';
        this.ComNameStyle ={'display':'block'};
        
      } else {
        this.opened = true;
        this.over = 'side';
        this.ComNameStyle ={'display':'none'};
        
      }
    });
  }
  ngOnInit(): void {
  }
  displayedColumns: string[] = ['position', 'giftcard', 'action'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  

  logout = function(){
   
  var self = this;
  self.spinner.show(); 
  self.router.navigate(['/home'])
  self.spinner.hide(); 
}
rglink = function(){
   
  var self = this;
  self.spinner.show(); 
  self.router.navigate(['/giftcardregistered'])
  self.spinner.hide(); 
}
gclink = function(){
   
  var self = this;
  self.spinner.show(); 
  self.router.navigate(['/giftcard'])
  self.spinner.hide(); 
}


}

