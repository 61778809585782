
<div class="modal modal-lg fade" tabindex="1" role="dialog" aria-labelledby="modelTitle" aria-hidden="true"
                [ngClass]="{
                    'show': WarningClass == true,
                    '': WarningClass == false
                    }" 
                [ngStyle]='WarningStyle'
                style="color:gray;opacity:0.98;display:none;margin:auto;margin-left:435px;"
                id="modal-warning" 
                >
                <!-- style="margin-top: 150px" -->
                    <div class="modal-dialog modal-dialog-centered"  role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title" id="modelTitle">Warning</h4>
                                <button type="button" (click)="hideWarning()" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <div class="modal-body">
                                {{validate_message}}
                            </div>
                            <div class="modal-footer">
                                <button type="button" (click)="hideWarning()" class="btn btn-danger">Close</button>
                            </div>
                        </div>
                    </div>
                </div>


<form #formP="ngForm" (ngSubmit)="formSubmit(formP.value)" class="form-horizontal form-material" [ngClass]="{
                            'show': SignupClass == true,
                            '': SignupClass == false
                            }" 
                        [ngStyle]='SignupStyle' style="display:block;">
                                <button mat-button (click)="onNoClick()" class="close" style="float: right;">X</button>
                                <h3 class="box-title m-t-20 m-b-0" style="margin-left: 275px;">Signup</h3>
                                
                                
                                
                                <br><br>
                                <div class="form-group m-t-20">
                                    <div class="col-xs-12">
                                        <input class="form-control" type="text" 
                                        name="email"  placeholder="Email"  [(ngModel)]="signupForm.email" required="text">
                                    </div>
                                </div>
                                <div class="form-group m-t-20">
                                    <div class="col-xs-12">
                                        <input class="form-control" type="text" 
                                        name="mobile_number" [(ngModel)]="signupForm.mobileNumber"  placeholder="Mobile Number">
                                    </div>
                                </div>
                                <div class="form-group m-t-20">
                                    <div class="col-xs-12">
                                        <input class="form-control" type="password" 
                                        name="password" [(ngModel)]="signupForm.password"  placeholder="Password">
                                    </div>
                                </div>
                                <div class="form-group m-t-30">
                                    <div class="col-xs-12">
                                        <input class="form-control" type="password" 
                                        name="confirm_password" [(ngModel)]="signupForm.confirmPassword"
                                        placeholder="Confirm Password">
                                    </div>
                                </div>
                
                                <div class="form-group text-center m-t-20">
                                    <div class="col-xs-12">
                                        <button class="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light" type="submit">Sign Up</button>
                                    </div>
                                </div>

                                                       
                                                
                            </form>
