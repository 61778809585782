import { MatDialog, MatDialogRef } from  '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { SignupForm } from '../data-model';
import { OdooService } from '../odoo.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { LoginComponent } from '../login/login.component';
import * as $ from 'jquery';



@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent {
signupForm: SignupForm = new SignupForm();
validate_message: string = "";
WarningClass: boolean;
WarningStyle: object;
SignupClass: boolean;
SignupStyle: object;
msg: string = "";

  constructor(
  private  dialog:  MatDialog,
  public dialogRef: MatDialogRef<SignupComponent>,
  public spinner: NgxSpinnerService,
  private router: Router){}

  

  getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = $.trim(cookies[i]);
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
  }


    onNoClick(): void {
    this.dialogRef.close();
  }
  closeaction(){
  this.dialogRef.close();
  }


  hideWarning = function(){
    this.WarningClass = false;
    this.WarningStyle = {'display': 'none'};
  }

  validateEmail(mail) 
  {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
    {
      return true;
    }
    else{
      return false;
    }
  }
  validateMobileNumber(mobile)
  {
  if(/^(?:\+971|0(0971)?)(?:[234679]|5[01256])[0-9]{7}$/.test(mobile))
  {
    return true;
  }
  else{
    return false;
  }
  }


  formSubmit(value) {
  var self = this;
  self.msg="";
  // console.log(value.mobile_number,value.email,value.password,value.confirm_password,"fdkdfknbfgbn")
  let mobile_number=value.mobile_number;
  let email=value.email;
  let password=value.password;
  let confirm_password=value.confirm_password;

  if (!mobile_number || !email || !password || !confirm_password){
  if(!mobile_number){
   self.msg +=" Mobile Number,"
  }
  if(!email){
  self.msg +=" Email,"
  }
  if(!password){
  self.msg +=" Password,"
  }
  if(!confirm_password){
  self.msg +=" Confirm Password,"

  }
   this.validate_message = "Please Enter "+self.msg+" to Signup";
      self.WarningClass = true;
      self.WarningStyle = {'display': 'block'};
      return false;
      }
    if (password != confirm_password){
    this.validate_message = "Password and Confirm Password are not same to Signup";
      self.WarningClass = true;
      self.WarningStyle = {'display': 'block'};
      return false;
      }
    let emailValidation = self.validateEmail(email)
    if (emailValidation == false){
    this.validate_message = "Please enter valid email address to Signup ";
      self.WarningClass = true;
      self.WarningStyle = {'display': 'block'};
      return false;

    } 
    let mobileValidation = self.validateMobileNumber(mobile_number)
    if (mobileValidation == false){
    this.validate_message = "Please enter valid Mobile Number to Signup ";
      self.WarningClass = true;
      self.WarningStyle = {'display': 'block'};
      return false;

    } 
    
    var session_id='';
    if(this.getCookie('kalyan_id')!=null){
      session_id=this.getCookie('kalyan_id');Date
    }  

    if (mobile_number || email || password || confirm_password){
    let args = {'session': session_id,'email':email,'password':password,'mobile_number':mobile_number,'confirm_password':confirm_password };
    var csrftoken = self.getCookie('csrf_token');
    self.spinner.show();  
    $.ajax({
            beforeSend: function(xhr, settings) {
              xhr.setRequestHeader("X-CSRFToken", csrftoken);
            },
            method: "POST",
            url: "/web/payment_portal/signup/",
            dataType: "json",
            data : {data:JSON.stringify(args),csrf_token: csrftoken},
            success: function(response) {
            // console.log("callingghghghgh")
            self.SignupClass = false;
            self.SignupStyle = {'display': 'none'};

            

            
            self.spinner.hide();

            },
            error: function(err) {
              // console.log(err)
              self.validate_message = err['message'];
              self.WarningClass = true;
              self.WarningStyle = {'display': 'block'};
              self.spinner.hide();
            }
            });

  }


  }

}
