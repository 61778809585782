<!-- <div class="modal fade form-modal" id="login" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog max-width-px-840 position-relative">
        <button type="button" class="circle-32 btn-reset bg-white pos-abs-tr mt-md-n6 mr-lg-n6 focus-reset z-index-supper" data-dismiss="modal"><i class="fas fa-times"></i></button>
        <div class="login-modal-main bg-white rounded-8 overflow-hidden">
          <div class="row no-gutters">
            <div class="col-lg-5 col-md-6">
              <div class="pt-10 pb-6 pl-11 pr-12 bg-black-2 h-100 d-flex flex-column dark-mode-texts">
                <div class="pb-9">
                  <h3 class="font-size-8 text-white line-height-reset pb-4 line-height-1p4">
                    Welcome Back
                  </h3>
                  <p class="mb-0 font-size-4 text-white">Log in to continue your account
                    and explore new jobs.</p>
                </div>
                <div class="border-top border-default-color-2 mt-auto">
                  <div class="d-flex mx-n9 pt-6 flex-xs-row flex-column">
                    <div class="pt-5 px-9">
                      <h3 class="font-size-7 text-white">
                        295
                      </h3>
                      <p class="font-size-3 text-white gr-opacity-5 line-height-1p4">New jobs
                        posted today</p>
                    </div>
                    <div class="pt-5 px-9">
                      <h3 class="font-size-7 text-white">
                        14
                      </h3>
                      <p class="font-size-3 text-white gr-opacity-5 line-height-1p4">New companies
                        registered</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-7 col-md-6">
              <div class="bg-white-2 h-100 px-11 pt-11 pb-7">
                <div class="row">
                  <div class="col-4 col-xs-12">
                    <a href="" class="font-size-4 font-weight-semibold position-relative text-white bg-allports h-px-48 flex-all-center w-100 px-6 rounded-5 mb-4"><i class="fab fa-linkedin pos-xs-abs-cl font-size-7 ml-xs-4"></i> <span class="d-none d-xs-block">Log in with LinkedIn</span></a>
                  </div>
                  <div class="col-4 col-xs-12">
                    <a href="" class="font-size-4 font-weight-semibold position-relative text-white bg-poppy h-px-48 flex-all-center w-100 px-6 rounded-5 mb-4"><i class="fab fa-google pos-xs-abs-cl font-size-7 ml-xs-4"></i> <span class="d-none d-xs-block">Log in with Google</span></a>
                  </div>
                  <div class="col-4 col-xs-12">
                    <a href="" class="font-size-4 font-weight-semibold position-relative text-white bg-marino h-px-48 flex-all-center w-100 px-6 rounded-5 mb-4"><i class="fab fa-facebook-square pos-xs-abs-cl font-size-7 ml-xs-4"></i> <span class="d-none d-xs-block">Log in with Facebook</span></a>
                  </div>
                </div>
                <div class="or-devider">
                  <span class="font-size-3 line-height-reset ">Or</span>
                </div>
                <form action="/">
                  <mat-form-field>
						<input  matInput  placeholder="Email"  type="email" name="email"  required>
				  </mat-form-field>
				  <mat-form-field>
					<input  matInput  placeholder="Password"  type="password"  name="password"  required>
				  </mat-form-field>
                  <div class="form-group d-flex flex-wrap justify-content-between">
                    <label for="terms-check" class="gr-check-input d-flex  mr-3">
                      <input class="d-none" type="checkbox" id="terms-check">
                      <span class="checkbox mr-5"></span>
                      <span class="font-size-3 mb-0 line-height-reset mb-1 d-block">Remember password</span>
                    </label>
                    <a href="" class="font-size-3 text-dodger line-height-reset">Forget Password</a>
                  </div>
                  <div class="form-group mb-8">
                    <button class="btn btn-primary btn-medium w-100 rounded-5 text-uppercase">Log in </button>
                  </div>
                  <p class="font-size-4 text-center heading-default-color">Don’t have an account? <a href="" class="text-primary">Create a free account</a></p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
 -->

 <form #formP="ngForm" class="form-horizontal form-material">
<!--                                 <button mat-button (click)="onNoClick()" style="float: right;">X</button> -->
<!--                                 <h3 class="box-title m-t-20 m-b-0" style="margin-left: 210px;">Login</h3> -->
                                
                                
                                
                                <br><br>
                                <div class="form-group m-t-20">
                                    <div class="col-xs-12">
                                        <input class="form-control" type="text" 
                                        name="mobile_number"                                         placeholder="Email (or) Mobile Number">
                                    </div>
                                </div>
                                <div class="form-group m-t-30">
                                    <div class="col-xs-12">
                                        <input class="form-control" type="password" 
                                        name="password" 
                                        placeholder="Password">
                                    </div>
                                </div>
                
                                <div class="form-group text-center m-t-20">
                                    <div class="col-xs-12">
                                        <button class="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light" style="background-color: red;" type="submit">Log In </button>
                                    </div>
                                </div>

                                                       
                                                
                            </form>