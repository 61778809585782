<div class="bg-default-1 pt-5">

<div class="container" style="margin-top: auto;margin-left:auto;background: white">
    <form ngNoForm #nonseamless id="nonseamless" name="nonseamless" method="post" name="redirect"
      action="https://kpay.com.kw/">
      <div id="proceed_payment_div">
        <br />
        <br />
        <br /><br />
        <input type="hidden" id="encRequest" name="encRequest" value="{{encryptedString}}"
          style="visibility:hidden" />
        <!-- <input type="hidden" name="access_code" id="access_code" value="{{accessCode}}" style="visibility:hidden"/> -->

        <button style="visibility:hidden">Submit</button>
        <p>Please wait while your transaction is being processed. You will be automatically redirected to the
          confirmation page.Please do not hit "back", "forward" or "refresh" button.</p>
        <div class="form-group text-center m-t-15">
          <div class="col-xs-12">
            <button type="submit" class="btn btn-sm btn-primary" style="display: none"
              style="color:white;background-color: red;align:center;visibility:hidden"
              id="confirm_payment">Confirm</button>
          </div>
        </div>
      </div>
      <br />
    </form>
    <script type="text/javascript">
      $('#nonseamless').submit()
    </script>
</div>
</div>
