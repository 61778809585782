import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-standing-instruction',
  templateUrl: './standing-instruction.component.html',
  styleUrls: ['./standing-instruction.component.css']
})
export class StandingInstructionComponent implements OnInit {

  opened = true;
  over = 'side';
  expandHeight = '42px';
  collapseHeight = '42px';
  displayMode = 'flat';
  ComNameStyle:object;
  SpanStyle:object;
  amount=localStorage.getItem('payableAmount');
  customerName=localStorage.getItem('customerName');
  
  referencenumber=localStorage.getItem('referencenumber');
  paymentURL = localStorage.getItem('payurl');
  accessCode = localStorage.getItem('accesscode');
  encryptedString = localStorage.getItem('encrpquery');
  giftcardTopupId = localStorage.getItem('giftcardTopupId');
  c_email = localStorage.getItem('odooemailid');
  c_mobile = localStorage.getItem('mobile');
  c_name=localStorage.getItem('country_name');
  constructor(private router: Router,public spinner: NgxSpinnerService) { }

  ngOnInit(): void {
  	let sessionkey=localStorage.getItem('session_valid_key')
    // console.log(sessionkey,"kdkdhdhdbccggd")
    if(sessionkey=="logout"){
    this.router.navigate(['/home'])
    
    }
    var ac = "AVUA03HL63BW84AUWB"
    $('#encRequest').val(localStorage.getItem('encrpquery'));
    $('#access_code').val(ac);
    $('#nonseamless').submit();
  }

}
